import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { CookieConsentProvider } from './contexts/CookieConsentContext';
import { MaintenanceProvider } from './contexts/MaintenanceContext';
import ScrollToTop from './components/ScrollToTop';
import MaintenanceWrapper from './components/MaintenanceWrapper';
import ProtectedRoute from './components/ProtectedRoute';
import MaintenanceEditor from './pages/admin/MaintenanceEditor';
import ContactMessages from './pages/admin/ContactMessages';
import Header from './components/Header';
import Footer from './components/Footer';
import Home2 from './pages/Home/index2';
import About from './pages/About';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import Realisations from './pages/Realisations';
import ProjectDetails from './pages/ProjectDetails';
import Domains from './pages/Domains';
import News from './pages/News';
import ArticleDetails from './pages/ArticleDetails';
import Privacy from './pages/legal/Privacy';
import Terms from './pages/legal/Terms';
import LegalNotice from './pages/legal/LegalNotice';
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import Users from './pages/admin/Users';
import Profile from './pages/admin/Profile';
import HeaderEditor from './pages/admin/HeaderEditor';
import FooterEditor from './pages/admin/FooterEditor';
import HomeEditor from './pages/admin/HomeEditor';
import Home2Editor from './pages/admin/Home2Editor';
import ContactEditor from './pages/admin/ContactEditor';
import CareersEditor from './pages/admin/CareersEditor';
import RealisationsEditor from './pages/admin/RealisationsEditor';
import AboutEditor from './pages/admin/AboutEditor';
import DomainsEditor from './pages/admin/DomainsEditor';
import BlogEditor from './pages/admin/BlogEditor';
import Recruitment from './pages/admin/Recruitment';
import SeoEditor from './pages/admin/SeoEditor';
import LegalEditor from './pages/admin/LegalEditor';
import Error404 from './pages/Error404';

export default function App() {
  return (
    <Router>
      <HelmetProvider>
        <AuthProvider>
          <MaintenanceProvider>
            <CookieConsentProvider>
              <ScrollToTop />
              <Toaster position="top-right" />
              <MaintenanceWrapper>
                <Routes>
                  {/* Admin routes */}
                  <Route path="/admin/login" element={<Login />} />
                  <Route
                    path="/admin/maintenance"
                    element={
                      <ProtectedRoute>
                        <MaintenanceEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/dashboard"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={
                      <ProtectedRoute>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/profile"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/header"
                    element={
                      <ProtectedRoute>
                        <HeaderEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/footer"
                    element={
                      <ProtectedRoute>
                        <FooterEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/home"
                    element={
                      <ProtectedRoute>
                        <HomeEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/home2"
                    element={
                      <ProtectedRoute>
                        <Home2Editor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/about"
                    element={
                      <ProtectedRoute>
                        <AboutEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/domains"
                    element={
                      <ProtectedRoute>
                        <DomainsEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/contact"
                    element={
                      <ProtectedRoute>
                        <ContactEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/messages"
                    element={
                      <ProtectedRoute>
                        <ContactMessages />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/careers"
                    element={
                      <ProtectedRoute>
                        <CareersEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/realisations"
                    element={
                      <ProtectedRoute>
                        <RealisationsEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/blog"
                    element={
                      <ProtectedRoute>
                        <BlogEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/recruitment"
                    element={
                      <ProtectedRoute>
                        <Recruitment />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/seo"
                    element={
                      <ProtectedRoute>
                        <SeoEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/legal"
                    element={
                      <ProtectedRoute>
                        <LegalEditor />
                      </ProtectedRoute>
                    }
                  />

                  {/* Public routes */}
                  <Route
                    path="/"
                    element={
                      <>
                        <Header />
                        <Home2 />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <>
                        <Header />
                        <About />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/domains"
                    element={
                      <>
                        <Header />
                        <Domains />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/contact"
                    element={
                      <>
                        <Header />
                        <Contact />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/careers"
                    element={
                      <>
                        <Header />
                        <Careers />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/realisations"
                    element={
                      <>
                        <Header />
                        <Realisations />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/realisations/:slug"
                    element={
                      <>
                        <Header />
                        <ProjectDetails />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/news"
                    element={
                      <>
                        <Header />
                        <News />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/news/:slug"
                    element={
                      <>
                        <Header />
                        <ArticleDetails />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/privacy"
                    element={
                      <>
                        <Header />
                        <Privacy />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/terms"
                    element={
                      <>
                        <Header />
                        <Terms />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/mentions-legales"
                    element={
                      <>
                        <Header />
                        <LegalNotice />
                        <Footer />
                      </>
                    }
                  />

                  {/* 404 Page */}
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </MaintenanceWrapper>
            </CookieConsentProvider>
          </MaintenanceProvider>
        </AuthProvider>
      </HelmetProvider>
    </Router>
  );
}