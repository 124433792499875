import React from 'react';
import { motion } from 'framer-motion';
import AnimatedStat from '../AnimatedStat';

interface KeyFiguresData {
  title: string;
  revenue: string;
  employees: string;
  locations: string;
  jobsCreated: string;
  labels: {
    revenue: string;
    employees: string;
    locations: string;
    jobsCreated: string;
  };
}

interface KeyFiguresSectionProps {
  data: KeyFiguresData;
}

export default function KeyFiguresSection({ data }: KeyFiguresSectionProps) {
  if (!data) return null;

  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-4xl font-bold text-center mb-16"
        >
          {data.title || "Nos chiffres clés"}
        </motion.h2>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <AnimatedStat 
            end={parseInt(data.revenue) || 0} 
            label={data.labels?.revenue || "Chiffre d'affaires"}
            suffix="M€"
          />
          <AnimatedStat 
            end={parseInt(data.employees) || 0} 
            label={data.labels?.employees || "Collaborateurs"}
          />
          <AnimatedStat 
            end={parseInt(data.locations) || 0} 
            label={data.labels?.locations || "Implantations dans le monde"}
          />
          <AnimatedStat 
            end={parseInt(data.jobsCreated) || 0} 
            label={data.labels?.jobsCreated || "Créations de postes par an"}
          />
        </div>
      </div>
    </section>
  );
}