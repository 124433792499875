import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Check } from 'lucide-react';

interface Domain {
  name: string;
  description: string;
  features: string[];
  image: string;
  stats: {
    sites: string;
    satisfaction: string;
    availability: string;
  };
  details: {
    description: string;
    features: {
      title: string;
      description: string;
    }[];
  };
}

interface DomainSectionProps {
  domains: Domain[];
  selectedDomain: string;
  onDomainChange: (domain: string) => void;
}

export default function DomainSection({ domains, selectedDomain, onDomainChange }: DomainSectionProps) {
  const [showDetails, setShowDetails] = React.useState(false);
  const currentDomain = domains.find(
    domain => domain.name.toLowerCase() === selectedDomain
  );

  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-4xl font-bold text-center mb-12"
        >
          Nos domaines d'intervention
        </motion.h2>

        <div className="flex justify-center flex-wrap gap-4 mb-12">
          {domains.map((domain) => (
            <motion.button
              key={domain.name}
              onClick={() => {
                onDomainChange(domain.name.toLowerCase());
                setShowDetails(false);
              }}
              className={`px-6 py-2 rounded-full transition-colors ${
                selectedDomain === domain.name.toLowerCase()
                  ? 'bg-black text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {domain.name}
            </motion.button>
          ))}
        </div>

        {currentDomain && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
            <div className="space-y-6">
              <div>
                <h2 className="text-3xl font-bold mb-4">{currentDomain.name}</h2>
                <p className="text-gray-600 text-lg">{currentDomain.description}</p>
              </div>

              <ul className="space-y-4">
                {currentDomain.features.map((feature, index) => (
                  <li key={index} className="flex items-center space-x-3">
                    <span className="w-1.5 h-1.5 bg-black rounded-full" />
                    <span className="text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => setShowDetails(!showDetails)}
                className="text-black hover:text-gray-700 font-medium"
              >
                {showDetails ? 'Masquer les détails' : 'Plus de détails'}
              </button>
            </div>

            <AnimatePresence mode="wait">
              {showDetails ? (
                <motion.div
                  key="details"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="space-y-8"
                >
                  <p className="text-gray-600">
                    {currentDomain.details.description}
                  </p>

                  <div className="space-y-4">
                    {currentDomain.details.features.map((feature, index) => (
                      <div key={index} className="border border-gray-200 rounded-lg p-4">
                        <div className="flex items-start space-x-3">
                          <Check className="h-5 w-5 text-black flex-shrink-0 mt-0.5" />
                          <div>
                            <h3 className="font-medium mb-1">{feature.title}</h3>
                            <p className="text-gray-600 text-sm">{feature.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="grid grid-cols-3 gap-8 pt-4">
                    <div className="text-center">
                      <div className="text-3xl font-bold mb-1">{currentDomain.stats.sites}</div>
                      <div className="text-gray-600">Sites traités</div>
                    </div>
                    <div className="text-center">
                      <div className="text-3xl font-bold mb-1">{currentDomain.stats.satisfaction}</div>
                      <div className="text-gray-600">Satisfaction</div>
                    </div>
                    <div className="text-center">
                      <div className="text-3xl font-bold mb-1">{currentDomain.stats.availability}</div>
                      <div className="text-gray-600">Disponibilité</div>
                    </div>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="image"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="relative"
                >
                  <div className="absolute -top-6 -right-6 w-full h-full bg-black rounded-lg z-0" />
                  <div className="relative rounded-lg overflow-hidden">
                    <img
                      src={currentDomain.image}
                      alt={currentDomain.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>
    </section>
  );
}