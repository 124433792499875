import React from 'react';

interface MapSectionProps {
  mapUrl: string;
  onChange: (mapUrl: string) => void;
}

export default function MapSection({ mapUrl, onChange }: MapSectionProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          URL Google Maps (iframe)
        </label>
        <input
          type="text"
          value={mapUrl}
          onChange={(e) => onChange(e.target.value)}
          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          placeholder="Collez l'URL d'intégration Google Maps"
        />
        <p className="mt-2 text-sm text-gray-500">
          Format attendu: https://maps.google.com/maps?width=100%...
        </p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Prévisualisation
        </label>
        <div className="aspect-video w-full rounded-lg overflow-hidden border border-gray-200">
          <div dangerouslySetInnerHTML={{ 
            __html: `<div style="width: 100%"><iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=13%20route%20de%20morangis+(Aps)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>`
          }} className="w-full h-full" />
        </div>
      </div>
    </div>
  );
}