import React from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import MaintenanceForm from '../../components/admin/MaintenanceMode/MaintenanceForm';

export default function MaintenanceEditor() {
  return (
    <AdminLayout>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold">Mode Maintenance</h1>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <MaintenanceForm />
      </div>
    </AdminLayout>
  );
}