import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, collection, getDocs, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save, Plus, Trash2, Edit2, Copy } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';

interface JobOffer {
  id: string;
  title: string;
  location: string;
  type: string;
  department: string;
  description: string;
  requirements: string[];
  responsibilities: string[];
}

const emptyJobOffer: Omit<JobOffer, 'id'> = {
  title: '',
  location: '',
  type: '',
  department: '',
  description: '',
  requirements: [''],
  responsibilities: ['']
};

interface CareerData {
  hero: {
    title: string;
    subtitle: string;
    backgroundImage: string;
  };
  benefits: {
    title: string;
    items: {
      id: string;
      icon: string;
      title: string;
      description: string;
    }[];
  };
  values: {
    title: string;
    items: {
      id: string;
      title: string;
      description: string;
    }[];
  };
}

const defaultCareerData: CareerData = {
  hero: {
    title: "Rejoignez notre équipe",
    subtitle: "Ensemble, construisons un avenir plus propre et plus durable",
    backgroundImage: "https://images.unsplash.com/photo-1664575602276-acd073f104c1?auto=format&fit=crop&q=80"
  },
  benefits: {
    title: "Pourquoi nous rejoindre ?",
    items: [
      {
        id: "1",
        icon: "Star",
        title: "Formation continue",
        description: "Développez vos compétences avec nos programmes de formation personnalisés"
      },
      {
        id: "2",
        icon: "Users",
        title: "Équipe dynamique",
        description: "Rejoignez une équipe passionnée et collaborative"
      },
      {
        id: "3",
        icon: "Briefcase",
        title: "Projets innovants",
        description: "Participez à des projets ambitieux et stimulants"
      }
    ]
  },
  values: {
    title: "Nos valeurs",
    items: [
      {
        id: "1",
        title: "Innovation",
        description: "Nous repoussons constamment les limites pour trouver des solutions durables"
      },
      {
        id: "2",
        title: "Engagement",
        description: "Nous sommes déterminés à faire une différence positive pour l'environnement"
      },
      {
        id: "3",
        title: "Excellence",
        description: "Nous visons l'excellence dans tout ce que nous entreprenons"
      }
    ]
  }
};

export default function CareersEditor() {
  const [careerData, setCareerData] = useState<CareerData>(defaultCareerData);
  const [jobOffers, setJobOffers] = useState<JobOffer[]>([]);
  const [loading, setLoading] = useState(false);
  const [newJobOffer, setNewJobOffer] = useState<Omit<JobOffer, 'id'>>(emptyJobOffer);
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      // Load career page data
      const careerRef = doc(db, 'settings', 'careers');
      const careerDoc = await getDoc(careerRef);
      
      if (careerDoc.exists()) {
        setCareerData({ ...defaultCareerData, ...careerDoc.data() as CareerData });
      } else {
        // If document doesn't exist, create it with default data
        await setDoc(careerRef, defaultCareerData);
      }

      // Load job offers
      const jobsSnapshot = await getDocs(collection(db, 'jobs'));
      const jobsData = jobsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as JobOffer[];
      setJobOffers(jobsData);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Error loading data. Please try again.');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const careerRef = doc(db, 'settings', 'careers');
      await setDoc(careerRef, careerData);
      toast.success('Page carrières mise à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  const handleAddJobOffer = async () => {
    try {
      if (isEditing && editingId) {
        await updateDoc(doc(db, 'jobs', editingId), newJobOffer);
        toast.success('Offre mise à jour avec succès');
      } else {
        await addDoc(collection(db, 'jobs'), newJobOffer);
        toast.success('Offre ajoutée avec succès');
      }
      loadData();
      setNewJobOffer(emptyJobOffer);
      setIsEditing(false);
      setEditingId(null);
    } catch (error) {
      toast.error(isEditing ? 'Erreur lors de la mise à jour' : 'Erreur lors de l\'ajout');
    }
  };

  const handleEditJobOffer = (job: JobOffer) => {
    setNewJobOffer({
      title: job.title,
      location: job.location,
      type: job.type,
      department: job.department,
      description: job.description,
      requirements: job.requirements,
      responsibilities: job.responsibilities
    });
    setIsEditing(true);
    setEditingId(job.id);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDuplicateJobOffer = async (job: JobOffer) => {
    try {
      const duplicatedJob = {
        title: `${job.title} (copie)`,
        location: job.location,
        type: job.type,
        department: job.department,
        description: job.description,
        requirements: [...job.requirements],
        responsibilities: [...job.responsibilities]
      };

      await addDoc(collection(db, 'jobs'), duplicatedJob);
      toast.success('Offre dupliquée avec succès');
      loadData();
    } catch (error) {
      toast.error('Erreur lors de la duplication');
    }
  };

  const handleDeleteJobOffer = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette offre ?')) {
      try {
        await deleteDoc(doc(db, 'jobs', id));
        toast.success('Offre supprimée avec succès');
        loadData();
      } catch (error) {
        toast.error('Erreur lors de la suppression');
      }
    }
  };

  const addRequirement = () => {
    setNewJobOffer({
      ...newJobOffer,
      requirements: [...newJobOffer.requirements, '']
    });
  };

  const removeRequirement = (index: number) => {
    const newRequirements = [...newJobOffer.requirements];
    newRequirements.splice(index, 1);
    setNewJobOffer({
      ...newJobOffer,
      requirements: newRequirements
    });
  };

  const addResponsibility = () => {
    setNewJobOffer({
      ...newJobOffer,
      responsibilities: [...newJobOffer.responsibilities, '']
    });
  };

  const removeResponsibility = (index: number) => {
    const newResponsibilities = [...newJobOffer.responsibilities];
    newResponsibilities.splice(index, 1);
    setNewJobOffer({
      ...newJobOffer,
      responsibilities: newResponsibilities
    });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Modifier la page Carrières</h1>
            <button
              onClick={handleSave}
              disabled={loading}
              className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
            >
              <Save className="h-5 w-5" />
              <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
            </button>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-medium mb-4">Offres d'emploi</h2>
            
            {/* Existing Job Offers */}
            <div className="space-y-4 mb-8">
              {jobOffers.map((job) => (
                <div key={job.id} className="p-4 border rounded-lg">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h3 className="font-medium">{job.title}</h3>
                      <div className="text-sm text-gray-500">
                        {job.location} • {job.type} • {job.department}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => handleDuplicateJobOffer(job)}
                        className="text-blue-600 hover:text-blue-700"
                        title="Dupliquer"
                      >
                        <Copy className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleEditJobOffer(job)}
                        className="text-blue-600 hover:text-blue-700"
                        title="Modifier"
                      >
                        <Edit2 className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteJobOffer(job.id)}
                        className="text-red-600 hover:text-red-700"
                        title="Supprimer"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Add/Edit Job Offer Form */}
            <div className="border-t pt-6">
              <h3 className="text-lg font-medium mb-4">
                {isEditing ? 'Modifier l\'offre' : 'Ajouter une nouvelle offre'}
              </h3>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Titre du poste
                  </label>
                  <input
                    type="text"
                    value={newJobOffer.title}
                    onChange={(e) => setNewJobOffer({ ...newJobOffer, title: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Localisation
                    </label>
                    <input
                      type="text"
                      value={newJobOffer.location}
                      onChange={(e) => setNewJobOffer({ ...newJobOffer, location: e.target.value })}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Type de contrat
                    </label>
                    <input
                      type="text"
                      value={newJobOffer.type}
                      onChange={(e) => setNewJobOffer({ ...newJobOffer, type: e.target.value })}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Département
                  </label>
                  <input
                    type="text"
                    value={newJobOffer.department}
                    onChange={(e) => setNewJobOffer({ ...newJobOffer, department: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Description
                  </label>
                  <textarea
                    value={newJobOffer.description}
                    onChange={(e) => setNewJobOffer({ ...newJobOffer, description: e.target.value })}
                    rows={4}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                {/* Requirements */}
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Prérequis
                    </label>
                    <button
                      type="button"
                      onClick={addRequirement}
                      className="text-sm text-black hover:text-gray-700 flex items-center"
                    >
                      <Plus className="h-4 w-4 mr-1" />
                      Ajouter
                    </button>
                  </div>
                  <div className="space-y-2">
                    {newJobOffer.requirements.map((req, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          type="text"
                          value={req}
                          onChange={(e) => {
                            const newReqs = [...newJobOffer.requirements];
                            newReqs[index] = e.target.value;
                            setNewJobOffer({ ...newJobOffer, requirements: newReqs });
                          }}
                          className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                        />
                        <button
                          type="button"
                          onClick={() => removeRequirement(index)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Responsibilities */}
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Responsabilités
                    </label>
                    <button
                      type="button"
                      onClick={addResponsibility}
                      className="text-sm text-black hover:text-gray-700 flex items-center"
                    >
                      <Plus className="h-4 w-4 mr-1" />
                      Ajouter
                    </button>
                  </div>
                  <div className="space-y-2">
                    {newJobOffer.responsibilities.map((resp, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <input
                          type="text"
                          value={resp}
                          onChange={(e) => {
                            const newResps = [...newJobOffer.responsibilities];
                            newResps[index] = e.target.value;
                            setNewJobOffer({ ...newJobOffer, responsibilities: newResps });
                          }}
                          className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                        />
                        <button
                          type="button"
                          onClick={() => removeResponsibility(index)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex space-x-4">
                  <button
                    onClick={handleAddJobOffer}
                    className="flex-1 bg-black text-white py-3 px-6 rounded-lg hover:bg-gray-900 transition-colors duration-200 flex items-center justify-center"
                  >
                    <Save className="h-5 w-5 mr-2" />
                    <span>{isEditing ? 'Mettre à jour l\'offre' : 'Ajouter l\'offre'}</span>
                  </button>

                  {isEditing && (
                    <button
                      onClick={() => {
                        setNewJobOffer(emptyJobOffer);
                        setIsEditing(false);
                        setEditingId(null);
                      }}
                      className="flex-1 bg-gray-100 text-gray-700 py-3 px-6 rounded-lg hover:bg-gray-200 transition-colors duration-200"
                    >
                      Annuler la modification
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}