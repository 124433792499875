import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Search, Filter } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import PageHero from '../components/PageHero';
import ProjectCard from '../components/ProjectCard';

interface Project {
  id: string;
  title: string;
  description: string;
  location: {
    city: string;
    country: string;
  };
  year: string;
  category: string;
  image: string;
  slug: string;
  surface?: string;
  duration?: string;
  budget?: string;
}

const categories = [
  'Tous',
  'Dépollution',
  'Désamiantage',
  'Démantèlement',
  'Réhabilitation'
];

export default function Realisations() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('Tous');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const projectsSnapshot = await getDocs(collection(db, 'projects'));
        const projectsData = projectsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Project[];
        setProjects(projectsData);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors du chargement des projets:', error);
        setLoading(false);
      }
    };

    loadProjects();
  }, []);

  const filteredProjects = projects.filter(project => 
    (selectedCategory === 'Tous' || project.category === selectedCategory) &&
    (project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
     project.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
     project.location.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
     project.location.country.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <>
      <Helmet>
        <title>Nos réalisations | DEPOL</title>
        <meta name="description" content="Découvrez nos projets de dépollution et de réhabilitation à travers la France. Expertise en désamiantage, dépollution et démantèlement." />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        <PageHero
          title="Nos réalisations"
          subtitle="Découvrez nos projets de dépollution et de réhabilitation à travers la France"
          backgroundImage="https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?auto=format&fit=crop&q=80"
          height="large"
        />

        {/* Filters Section */}
        <section className="sticky top-20 z-20 bg-white border-b border-gray-200 shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              {/* Mobile Filter Button */}
              <button
                className="md:hidden w-full flex items-center justify-center space-x-2 px-4 py-2 bg-black text-white rounded-lg"
                onClick={() => {}}
              >
                <Filter className="h-5 w-5" />
                <span>Filtrer</span>
              </button>

              {/* Category Filters */}
              <div className="flex flex-wrap gap-2">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                      selectedCategory === category
                        ? 'bg-black text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>

              {/* Search Bar */}
              <div className="relative w-full md:w-64">
                <input
                  type="text"
                  placeholder="Rechercher un projet..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </section>

        {/* Projects Grid */}
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredProjects.map((project) => (
                <ProjectCard key={project.id} project={project} />
              ))}
            </div>
          )}

          {!loading && filteredProjects.length === 0 && (
            <div className="text-center py-12">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                Aucun projet trouvé
              </h3>
              <p className="text-gray-600">
                Essayez de modifier vos critères de recherche
              </p>
            </div>
          )}
        </section>
      </div>
    </>
  );
}