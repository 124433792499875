import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHero from '../../components/PageHero';

export default function Terms() {
  return (
    <>
      <Helmet>
        <title>Conditions d'utilisation | DEPOL</title>
        <meta name="description" content="Nos conditions générales d'utilisation définissent les règles d'utilisation de notre site web." />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        <PageHero
          title="Conditions d'utilisation"
          subtitle="Règles d'utilisation de notre site web"
          backgroundImage="https://images.unsplash.com/photo-1507925921958-8a62f3d1a50d?auto=format&fit=crop&q=80"
          height="medium"
        />

        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
            <section>
              <h2 className="text-2xl font-bold mb-4">1. Acceptation des conditions</h2>
              <p className="text-gray-600">
                En accédant et en utilisant ce site web, vous acceptez d'être lié par ces conditions d'utilisation, toutes les lois et réglementations applicables. Si vous n'acceptez pas ces conditions, vous ne devez pas utiliser ce site.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">2. Utilisation du site</h2>
              <p className="text-gray-600 mb-4">
                L'utilisation de ce site est soumise aux règles suivantes :
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>Utilisation à des fins légales uniquement</li>
                <li>Ne pas interférer avec la sécurité du site</li>
                <li>Ne pas diffuser de contenu illégal ou offensant</li>
                <li>Ne pas tenter d'accéder aux zones restreintes</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">3. Propriété intellectuelle</h2>
              <p className="text-gray-600 mb-4">
                Tout le contenu présent sur ce site (textes, images, logos, etc.) est protégé par les droits de propriété intellectuelle. Vous ne pouvez pas :
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>Copier ou reproduire le contenu sans autorisation</li>
                <li>Utiliser le contenu à des fins commerciales</li>
                <li>Modifier ou altérer le contenu</li>
                <li>Supprimer les mentions de droits d'auteur</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">4. Responsabilité</h2>
              <p className="text-gray-600">
                Nous nous efforçons de maintenir les informations de ce site à jour et exactes. Cependant, nous ne pouvons garantir l'exactitude, l'exhaustivité ou la pertinence des informations fournies. L'utilisation du site se fait à vos propres risques.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">5. Liens externes</h2>
              <p className="text-gray-600">
                Ce site peut contenir des liens vers des sites externes. Nous ne sommes pas responsables du contenu ou des pratiques de confidentialité de ces sites. L'accès à ces liens se fait à vos propres risques.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">6. Modification des conditions</h2>
              <p className="text-gray-600">
                Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les modifications entrent en vigueur dès leur publication sur le site. Il est de votre responsabilité de consulter régulièrement ces conditions.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">7. Contact</h2>
              <p className="text-gray-600">
                Pour toute question concernant ces conditions d'utilisation, vous pouvez nous contacter à l'adresse suivante : legal@depol.fr
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}