import { enableIndexedDbPersistence, disableNetwork, enableNetwork, clearIndexedDbPersistence } from 'firebase/firestore';
import { db } from '../lib/firebase';

let cacheEnabled = false;
let cacheDuration = 60; // Default 60 minutes
let cacheTimeout: NodeJS.Timeout | null = null;

export const enableFirebaseCache = async (duration: number) => {
  try {
    if (!cacheEnabled) {
      await enableIndexedDbPersistence(db);
      cacheEnabled = true;
      cacheDuration = duration;
      
      // Set up cache expiration
      if (cacheTimeout) {
        clearTimeout(cacheTimeout);
      }
      
      cacheTimeout = setTimeout(() => {
        clearFirebaseCache();
      }, duration * 60 * 1000); // Convert minutes to milliseconds
      
      console.log('Firebase cache enabled for', duration, 'minutes');
    }
  } catch (error) {
    console.error('Error enabling Firebase cache:', error);
    throw error;
  }
};

export const disableFirebaseCache = async () => {
  try {
    if (cacheEnabled) {
      await disableNetwork(db);
      await enableNetwork(db); // Re-enable network to ensure fresh data
      cacheEnabled = false;
      
      if (cacheTimeout) {
        clearTimeout(cacheTimeout);
        cacheTimeout = null;
      }
      
      console.log('Firebase cache disabled');
    }
  } catch (error) {
    console.error('Error disabling Firebase cache:', error);
    throw error;
  }
};

export const clearFirebaseCache = async () => {
  try {
    await clearIndexedDbPersistence(db);
    console.log('Firebase cache cleared');
  } catch (error) {
    console.error('Error clearing Firebase cache:', error);
    throw error;
  }
};

export const isCacheEnabled = () => cacheEnabled;
export const getCacheDuration = () => cacheDuration;