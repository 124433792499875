import React, { useRef } from 'react';
import { Upload, X, Loader2 } from 'lucide-react';
import { uploadImage } from '../utils/uploadImage';

interface ImageUploadProps {
  onImageUploaded: (url: string) => void;
  onError: (error: string) => void;
}

export default function ImageUpload({ onImageUploaded, onError }: ImageUploadProps) {
  const [uploading, setUploading] = React.useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Vérifier le type de fichier
    if (!file.type.startsWith('image/')) {
      onError('Le fichier doit être une image');
      return;
    }

    // Vérifier la taille du fichier (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      onError('L\'image ne doit pas dépasser 5MB');
      return;
    }

    setUploading(true);
    try {
      const url = await uploadImage(file);
      onImageUploaded(url);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      onError('Erreur lors du téléchargement de l\'image');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="relative">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer disabled:cursor-not-allowed"
        disabled={uploading}
      />
      <div className="px-4 py-2 border rounded-lg flex items-center justify-center space-x-2 hover:bg-gray-50">
        {uploading ? (
          <>
            <Loader2 className="h-5 w-5 text-gray-400 animate-spin" />
            <span className="text-gray-600">Téléchargement...</span>
          </>
        ) : (
          <>
            <Upload className="h-5 w-5 text-gray-400" />
            <span className="text-gray-600">Sélectionner une image</span>
          </>
        )}
      </div>
    </div>
  );
}