import React, { useState } from 'react';
import { MapPin, Clock } from 'lucide-react';
import JobModal from './JobModal';

interface JobCardProps {
  job: {
    id: string;
    title: string;
    location: string;
    type: string;
    department: string;
    description: string;
    requirements: string[];
    responsibilities: string[];
  };
}

export default function JobCard({ job }: JobCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setIsModalOpen(true)}
        className="bg-white p-6 rounded-2xl border border-gray-200 hover:border-gray-300 hover:shadow-lg transition-all duration-200 cursor-pointer"
      >
        <div className="space-y-4">
          <div className="flex items-start justify-between">
            <span className="inline-block px-3 py-1 bg-gray-100 text-gray-700 text-sm rounded-full">
              {job.department}
            </span>
          </div>
          
          <h3 className="text-xl font-semibold text-gray-900">
            {job.title}
          </h3>
          
          <p className="text-gray-600 line-clamp-2">
            {job.description}
          </p>

          <div className="pt-4 space-y-2 text-gray-600">
            <div className="flex items-center space-x-2">
              <MapPin className="h-4 w-4" />
              <span className="text-sm">{job.location}</span>
            </div>
            <div className="flex items-center space-x-2">
              <Clock className="h-4 w-4" />
              <span className="text-sm">{job.type}</span>
            </div>
          </div>
        </div>
      </div>

      <JobModal
        job={job}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}