import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../lib/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Users, Eye, FileText, MessageSquare, TrendingUp } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';

export default function Dashboard() {
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    visitors: { count: 2847, change: '+12.5%' },
    pageViews: { count: 14589, change: '+8.2%' },
    projects: { count: 48, change: '+2' },
    messages: { count: 23, change: '+5' }
  });

  const [recentActivity] = useState([
    {
      type: 'project',
      title: 'Nouveau projet ajouté',
      description: 'Réhabilitation d\'une friche industrielle à Lyon',
      time: 'Il y a 2 heures'
    },
    {
      type: 'message',
      title: 'Nouveau message',
      description: 'Demande de devis pour désamiantage',
      time: 'Il y a 4 heures'
    },
    {
      type: 'traffic',
      title: 'Pic de trafic',
      description: '+250 visiteurs dans la dernière heure',
      time: 'Il y a 6 heures'
    }
  ]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/admin/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      
      {/* Main content */}
      <div className="ml-64">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="flex justify-between items-center px-8 py-4">
            <h1 className="text-2xl font-bold">Tableau de bord</h1>
            <button className="px-4 py-2 bg-black text-white rounded-lg">
              Aujourd'hui
            </button>
          </div>
        </header>

        {/* Content */}
        <main className="p-8">
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-3xl font-bold">{stats.visitors.count}</p>
                  <p className="text-sm text-gray-600">Visiteurs uniques</p>
                </div>
                <span className="text-green-500">{stats.visitors.change}</span>
              </div>
              <Users className="h-6 w-6 text-gray-400 mt-4" />
            </div>

            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-3xl font-bold">{stats.pageViews.count}</p>
                  <p className="text-sm text-gray-600">Pages vues</p>
                </div>
                <span className="text-green-500">{stats.pageViews.change}</span>
              </div>
              <Eye className="h-6 w-6 text-gray-400 mt-4" />
            </div>

            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-3xl font-bold">{stats.projects.count}</p>
                  <p className="text-sm text-gray-600">Projets publiés</p>
                </div>
                <span className="text-green-500">{stats.projects.change}</span>
              </div>
              <FileText className="h-6 w-6 text-gray-400 mt-4" />
            </div>

            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-3xl font-bold">{stats.messages.count}</p>
                  <p className="text-sm text-gray-600">Messages reçus</p>
                </div>
                <span className="text-green-500">{stats.messages.change}</span>
              </div>
              <MessageSquare className="h-6 w-6 text-gray-400 mt-4" />
            </div>
          </div>

          {/* Recent Activity */}
          <div className="bg-white rounded-lg shadow">
            <div className="p-6 border-b">
              <h2 className="text-lg font-semibold">Activité récente</h2>
            </div>
            <div className="divide-y">
              {recentActivity.map((activity, index) => (
                <div key={index} className="p-6 flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    {activity.type === 'project' && <FileText className="h-5 w-5 text-gray-400" />}
                    {activity.type === 'message' && <MessageSquare className="h-5 w-5 text-gray-400" />}
                    {activity.type === 'traffic' && <TrendingUp className="h-5 w-5 text-gray-400" />}
                    <div>
                      <p className="font-medium">{activity.title}</p>
                      <p className="text-sm text-gray-600">{activity.description}</p>
                    </div>
                  </div>
                  <span className="text-sm text-gray-500">{activity.time}</span>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}