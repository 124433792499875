import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin, MapPin, Phone, Mail } from 'lucide-react';
import { motion } from 'framer-motion';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface FooterData {
  logo: {
    white: string;
  };
  companyInfo: {
    description: string;
    address: string;
    phone: string;
    email: string;
  };
  quickLinks: {
    id: string;
    label: string;
    path: string;
  }[];
  socialLinks: {
    facebook: {
      url: string;
      enabled: boolean;
    };
    twitter: {
      url: string;
      enabled: boolean;
    };
    instagram: {
      url: string;
      enabled: boolean;
    };
    linkedin: {
      url: string;
      enabled: boolean;
    };
  };
  legalLinks: {
    id: string;
    label: string;
    path: string;
  }[];
}

const defaultFooterData: FooterData = {
  logo: {
    white: '/logo-white.png'
  },
  companyInfo: {
    description: 'Votre partenaire de confiance pour des solutions innovantes et personnalisées en dépollution et services environnementaux.',
    address: '123 Rue Example, 75000 Paris, France',
    phone: '+33 1 23 45 67 89',
    email: 'contact@votresite.fr'
  },
  quickLinks: [
    { id: '1', label: 'Accueil', path: '/' },
    { id: '2', label: 'À propos', path: '/about' },
    { id: '3', label: "Domaines d'intervention", path: '/domains' },
    { id: '4', label: 'Réalisations', path: '/realisations' },
    { id: '5', label: 'Actualités', path: '/news' },
    { id: '6', label: 'Carrières', path: '/careers' }
  ],
  socialLinks: {
    facebook: { url: '#', enabled: true },
    twitter: { url: '#', enabled: true },
    instagram: { url: '#', enabled: true },
    linkedin: { url: '#', enabled: true }
  },
  legalLinks: [
    { id: '1', label: 'Politique de confidentialité', path: '/privacy' },
    { id: '2', label: 'Conditions d\'utilisation', path: '/terms' },
    { id: '3', label: 'Mentions légales', path: '/mentions-legales' }
  ]
};

export default function Footer() {
  const [footerData, setFooterData] = useState<FooterData>(defaultFooterData);

  useEffect(() => {
    const loadFooterData = async () => {
      try {
        const footerRef = doc(db, 'settings', 'footer');
        const footerDoc = await getDoc(footerRef);
        
        if (footerDoc.exists()) {
          setFooterData({ ...defaultFooterData, ...footerDoc.data() as FooterData });
        }
      } catch (error) {
        console.error('Erreur lors du chargement du footer:', error);
      }
    };

    loadFooterData();
  }, []);

  const contactItemVariants = {
    initial: { x: 0 },
    hover: { x: 10, transition: { duration: 0.2 } }
  };

  return (
    <footer className="bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* Logo and Company Info */}
          <div className="space-y-6">
            <Link to="/" className="block">
              <img
                src={footerData.logo.white}
                alt="Logo"
                className="h-12 w-auto"
              />
            </Link>
            <p className="text-gray-400">
              {footerData.companyInfo.description}
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-bold mb-6">Liens Rapides</h3>
            <ul className="space-y-4">
              {footerData.quickLinks.map((link) => (
                <li key={link.id}>
                  <Link 
                    to={link.path} 
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-bold mb-6">Contact</h3>
            <ul className="space-y-4">
              <motion.li 
                className="flex items-start space-x-3 cursor-pointer group"
                initial="initial"
                whileHover="hover"
              >
                <MapPin className="h-5 w-5 text-gray-400 mt-0.5 group-hover:text-white transition-colors" />
                <motion.span 
                  className="text-gray-400 group-hover:text-white transition-colors"
                  variants={contactItemVariants}
                >
                  {footerData.companyInfo.address}
                </motion.span>
              </motion.li>
              <motion.li 
                className="flex items-center space-x-3 cursor-pointer group"
                initial="initial"
                whileHover="hover"
              >
                <Phone className="h-5 w-5 text-gray-400 group-hover:text-white transition-colors" />
                <motion.span 
                  className="text-gray-400 group-hover:text-white transition-colors"
                  variants={contactItemVariants}
                >
                  {footerData.companyInfo.phone}
                </motion.span>
              </motion.li>
              <motion.li 
                className="flex items-center space-x-3 cursor-pointer group"
                initial="initial"
                whileHover="hover"
              >
                <Mail className="h-5 w-5 text-gray-400 group-hover:text-white transition-colors" />
                <motion.span 
                  className="text-gray-400 group-hover:text-white transition-colors"
                  variants={contactItemVariants}
                >
                  {footerData.companyInfo.email}
                </motion.span>
              </motion.li>
            </ul>
          </div>

          {/* Social Media and Newsletter */}
          <div>
            <h3 className="text-lg font-bold mb-6">Suivez-nous</h3>
            <div className="flex space-x-4 mb-8">
              {footerData.socialLinks.facebook.enabled && (
                <a 
                  href={footerData.socialLinks.facebook.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                  aria-label="Facebook"
                >
                  <Facebook className="h-5 w-5 text-gray-400 hover:text-white" />
                </a>
              )}
              {footerData.socialLinks.twitter.enabled && (
                <a 
                  href={footerData.socialLinks.twitter.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                  aria-label="Twitter"
                >
                  <Twitter className="h-5 w-5 text-gray-400 hover:text-white" />
                </a>
              )}
              {footerData.socialLinks.instagram.enabled && (
                <a 
                  href={footerData.socialLinks.instagram.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                  aria-label="Instagram"
                >
                  <Instagram className="h-5 w-5 text-gray-400 hover:text-white" />
                </a>
              )}
              {footerData.socialLinks.linkedin.enabled && (
                <a 
                  href={footerData.socialLinks.linkedin.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                  aria-label="LinkedIn"
                >
                  <Linkedin className="h-5 w-5 text-gray-400 hover:text-white" />
                </a>
              )}
            </div>

            <div>
              <h4 className="text-lg font-bold mb-4">Newsletter</h4>
              <form className="flex space-x-2">
                <input
                  type="email"
                  placeholder="Votre email"
                  className="flex-1 px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:border-gray-600 text-white placeholder-gray-400"
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-white text-black rounded-lg hover:bg-gray-200 transition-colors"
                >
                  S'inscrire
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">
              &copy; {new Date().getFullYear()} Votre Entreprise. Tous droits réservés.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              {footerData.legalLinks.map((link) => (
                <Link 
                  key={link.id}
                  to={link.path} 
                  className="text-gray-400 hover:text-white text-sm"
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}