import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { MapPin, Phone, Mail, Clock, Send } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import ContactInfoCard from '../components/ContactInfoCard';
import PageHero from '../components/PageHero';

interface ContactInfo {
  icon: string;
  title: string;
  content: string;
}

interface ContactData {
  title: string;
  subtitle: string;
  contactInfo: {
    address: ContactInfo;
    phone: ContactInfo;
    email: ContactInfo;
    hours: ContactInfo;
  };
  mapUrl: string;
}

const defaultContactData: ContactData = {
  title: "Discutons de votre projet",
  subtitle: "Notre équipe d'experts est à votre écoute pour vous accompagner dans la réalisation de vos projets et répondre à toutes vos questions.",
  contactInfo: {
    address: {
      icon: "MapPin",
      title: "Notre adresse",
      content: "123 Rue de la Dépollution, 75001 Paris, France"
    },
    phone: {
      icon: "Phone",
      title: "Téléphone",
      content: "+33 1 23 45 67 89"
    },
    email: {
      icon: "Mail",
      title: "Email",
      content: "contact@entreprise.fr"
    },
    hours: {
      icon: "Clock",
      title: "Horaires d'ouverture",
      content: "Lun - Ven: 9h00 - 18h00"
    }
  },
  mapUrl: "https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=13%20route%20de%20morangis+(Aps)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
};

export default function Contact() {
  const [contactData, setContactData] = useState<ContactData>(defaultContactData);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const loadContactData = async () => {
      try {
        const contactRef = doc(db, 'settings', 'contact');
        const contactDoc = await getDoc(contactRef);
        
        if (contactDoc.exists()) {
          setContactData({ ...defaultContactData, ...contactDoc.data() as ContactData });
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
      }
    };

    loadContactData();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      // Add message to Firestore
      await addDoc(collection(db, 'contactMessages'), {
        ...formData,
        createdAt: new Date().toISOString()
      });

      toast.success('Message envoyé avec succès');
      setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error submitting message:', error);
      toast.error('Erreur lors de l\'envoi du message');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact | APS</title>
        <meta name="description" content="Contactez-nous pour discuter de vos projets de dépollution et réhabilitation environnementale." />
      </Helmet>

      <div className="relative">
        <PageHero
          title={contactData.title}
          subtitle={contactData.subtitle}
          backgroundImage="https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&q=80"
          height="large"
        />

        {/* Contact Information Cards */}
        <div className="relative z-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-20">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {Object.entries(contactData.contactInfo).map(([key, info]) => {
              const IconComponent = {
                MapPin,
                Phone,
                Mail,
                Clock
              }[info.icon as keyof typeof contactData.contactInfo];
              return (
                <ContactInfoCard
                  key={key}
                  icon={IconComponent}
                  title={info.title}
                  content={info.content}
                />
              );
            })}
          </div>
        </div>

        {/* Contact Form and Map Section */}
        <div id="contact-form" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Contact Form */}
            <div>
              <h2 className="text-2xl font-bold mb-8">Envoyez-nous un message</h2>
              <p className="text-gray-600 mb-8">Nous vous répondrons dans les plus brefs délais</p>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Nom complet
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                      required
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Téléphone
                    </label>
                    <input
                      type="tel"
                      value={formData.phone}
                      onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Sujet
                    </label>
                    <select
                      value={formData.subject}
                      onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                      required
                    >
                      <option value="">Sélectionnez un sujet</option>
                      <option value="devis">Demande de devis</option>
                      <option value="information">Demande d'information</option>
                      <option value="autre">Autre</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Message
                  </label>
                  <textarea
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    rows={6}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                    required
                  />
                </div>
                <button
                  type="submit"
                  disabled={submitting}
                  className="w-full bg-black text-white py-3 px-6 rounded-lg hover:bg-gray-900 transition-colors duration-200 flex items-center justify-center disabled:opacity-50"
                >
                  {submitting ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                      <span>Envoi en cours...</span>
                    </>
                  ) : (
                    <>
                      <span>Envoyer le message</span>
                      <Send className="ml-2 h-5 w-5" />
                    </>
                  )}
                </button>
              </form>
            </div>

            {/* Map */}
            <div className="h-[600px] rounded-lg overflow-hidden shadow-lg">
              <iframe
                src={contactData.mapUrl}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}