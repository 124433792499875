import React from 'react';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

interface TypewriterProps {
  data: {
    prefix: string;
    words: string[];
    buttonText: string;
    buttonLink: string;
  };
}

export default function TypewriterSection({ data }: TypewriterProps) {
  return (
    <section className="py-20 bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="text-2xl md:text-4xl font-bold mb-8 flex flex-col md:flex-row items-center gap-2 md:gap-4">
            <span className="mb-4 md:mb-0">{data.prefix}</span>
            <span className="text-white bg-gray-800 px-4 py-2 rounded min-h-[40px] md:min-h-0">
              <Typewriter
                options={{
                  strings: data.words,
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 50,
                  delay: 100
                }}
              />
            </span>
          </h2>
          <Link
            to={data.buttonLink}
            className="mt-8 inline-flex items-center px-8 py-3 border border-white text-lg font-medium rounded-md text-white hover:bg-white hover:text-black transition-colors duration-200"
          >
            {data.buttonText}
          </Link>
        </div>
      </div>
    </section>
  );
}