import React from 'react';
import { Check, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

interface ExperienceProps {
  data: {
    title: string;
    subtitle: string;
    description: string;
    image: string;
    features: string[];
    steps?: {
      title: string;
      description: string;
    }[];
  };
}

export default function ExperienceSection({ data }: ExperienceProps) {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="relative"
          >
            {/* Main image container */}
            <div className="relative rounded-2xl overflow-hidden shadow-xl">
              <img
                src={data.image}
                alt="Experience"
                className="w-full h-[500px] object-cover"
              />
              
              {/* Black overlay gradient */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
              
              {/* Text overlay */}
              <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
                <h3 className="text-2xl font-bold mb-3">{data.subtitle}</h3>
                <p className="text-gray-200">{data.description}</p>
              </div>
            </div>

            {/* Steps overlay */}
            {data.steps && (
              <div className="absolute -right-8 top-8 bg-black text-white p-6 rounded-lg shadow-xl max-w-xs">
                <h4 className="text-lg font-semibold mb-4">Étapes de réalisation</h4>
                <div className="space-y-4">
                  {data.steps.map((step, index) => (
                    <div key={index} className="flex items-start space-x-3">
                      <span className="flex-shrink-0 w-6 h-6 rounded-full bg-white text-black flex items-center justify-center text-sm font-bold">
                        {index + 1}
                      </span>
                      <div>
                        <h5 className="font-medium text-sm mb-1">{step.title}</h5>
                        <p className="text-xs text-gray-300">{step.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-8">{data.title}</h2>
            <div className="space-y-6">
              {data.features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="flex items-center space-x-3"
                >
                  <div className="flex-shrink-0 w-10 h-10 bg-black rounded-lg flex items-center justify-center">
                    <Check className="h-6 w-6 text-white" />
                  </div>
                  <span className="text-lg">{feature}</span>
                </motion.div>
              ))}
            </div>
            <Link
              to="/about"
              className="mt-8 inline-flex items-center px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-900 transition-colors group"
            >
              Découvrir l'entreprise
              <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
}