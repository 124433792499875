import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import HeroSection from '../../components/admin/sections/HeroSection';
import AboutSection from '../../components/admin/sections/AboutSection';
import TypewriterSection from '../../components/admin/sections/TypewriterSection';
import KeyFiguresSection from '../../components/admin/sections/KeyFiguresSection';
import ExperienceSection from '../../components/admin/sections/ExperienceSection';
import ClientsSection from '../../components/admin/sections/ClientsSection';
import ContactSection from '../../components/admin/sections/ContactSection';

interface HomeData {
  hero: {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonLink: string;
    backgroundImage?: string;
  };
  about: {
    yearSince: string;
    title: string;
    subtitle: string;
    description: string;
  };
  typewriter: {
    prefix: string;
    words: string[];
    buttonText: string;
    buttonLink: string;
  };
  businessLines: {
    title: string;
    categories: {
      id: string;
      name: string;
      content: {
        title: string;
        description: string;
        features: string[];
        image: string;
      };
    }[];
  };
  keyFigures: {
    revenue: string;
    employees: string;
    locations: string;
    jobsCreated: string;
  };
  experience: {
    title: string;
    subtitle: string;
    description: string;
    image: string;
    features: string[];
  };
  clients: {
    title: string;
    subtitle: string;
    items: Array<{
      id: string;
      name: string;
      logo: string;
    }>;
  };
  contact: {
    title: string;
    subtitle: string;
    address: string;
    phone: string;
    email: string;
  };
}

const defaultHomeData: HomeData = {
  hero: {
    title: "Dépolluer pour l'Homme & l'Environnement",
    subtitle: "Solutions professionnelles de dépollution et services environnementaux",
    buttonText: "En savoir plus",
    buttonLink: "/about",
    backgroundImage: "https://images.unsplash.com/photo-1590674899484-d5640e854abe?auto=format&fit=crop&q=80"
  },
  about: {
    yearSince: "DEPUIS 1995",
    title: "DI Environnement réalise des travaux de dépollution & de désamiantage",
    subtitle: "Notre groupe est spécialisé dans le retrait et le traitement de tous types de polluants",
    description: "Forts de notre expérience, nous proposons une offre globale qui s'étend de la dépollution des bâtiments et des sols au traitement de surfaces métalliques."
  },
  typewriter: {
    prefix: "Nos experts à vos côtés pour",
    words: ["recycler", "dépolluer", "désamianter", "innover"],
    buttonText: "Nos expertises",
    buttonLink: "/expertises"
  },
  businessLines: {
    title: "Une offre globale en 5 business lines",
    categories: []
  },
  keyFigures: {
    revenue: "104",
    employees: "650",
    locations: "26",
    jobsCreated: "40"
  },
  experience: {
    title: "Près de 30 ans d'expérience",
    subtitle: "Un savoir-faire maîtrisé",
    description: "Notre expertise technique et notre expérience nous permettent de répondre aux défis les plus complexes.",
    image: "https://images.unsplash.com/photo-1590674899484-d5640e854abe?auto=format&fit=crop&q=80",
    features: [
      "Près de 650 collaborateurs",
      "Une entreprise à impact",
      "Des équipements innovants et sur-mesure"
    ]
  },
  clients: {
    title: "Ils nous font confiance",
    subtitle: "Nos références",
    items: []
  },
  contact: {
    title: "Contactez-nous",
    subtitle: "Nous sommes à votre écoute pour répondre à vos questions et vous accompagner dans vos projets.",
    address: "123 Rue de la Dépollution, 75001 Paris",
    phone: "+33 1 23 45 67 89",
    email: "contact@entreprise.fr"
  }
};

export default function HomeEditor() {
  const [homeData, setHomeData] = useState<HomeData>(defaultHomeData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadHomeData();
  }, []);

  const loadHomeData = async () => {
    try {
      const homeRef = doc(db, 'settings', 'homepage');
      const homeDoc = await getDoc(homeRef);
      
      if (homeDoc.exists()) {
        setHomeData({ ...defaultHomeData, ...homeDoc.data() as HomeData });
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      toast.error('Erreur lors du chargement des données');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const homeRef = doc(db, 'settings', 'homepage');
      await setDoc(homeRef, homeData);
      toast.success('Page d\'accueil mise à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  const handleSectionChange = (section: keyof HomeData, newData: any) => {
    setHomeData(prev => ({
      ...prev,
      [section]: newData
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Modifier la page d'accueil</h1>
            <button
              onClick={handleSave}
              disabled={loading}
              className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
            >
              <Save className="h-5 w-5" />
              <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
            </button>
          </div>

          <div className="space-y-8">
            <HeroSection
              data={homeData.hero}
              onChange={(data) => handleSectionChange('hero', data)}
            />

            <AboutSection
              data={homeData.about}
              onChange={(data) => handleSectionChange('about', data)}
            />

            <TypewriterSection
              data={homeData.typewriter}
              onChange={(data) => handleSectionChange('typewriter', data)}
            />

            <BusinessLinesSection
              data={homeData.businessLines}
              onChange={(data) => handleSectionChange('businessLines', data)}
            />

            <KeyFiguresSection
              data={homeData.keyFigures}
              onChange={(data) => handleSectionChange('keyFigures', data)}
            />

            <ExperienceSection
              data={homeData.experience}
              onChange={(data) => handleSectionChange('experience', data)}
            />

            <ClientsSection
              data={homeData.clients}
              onChange={(data) => handleSectionChange('clients', data)}
              onAddClient={() => {
                const newId = Math.max(...homeData.clients.items.map(c => parseInt(c.id)), 0) + 1;
                setHomeData(prev => ({
                  ...prev,
                  clients: {
                    ...prev.clients,
                    items: [
                      ...prev.clients.items,
                      {
                        id: newId.toString(),
                        name: '',
                        logo: ''
                      }
                    ]
                  }
                }));
              }}
            />

            <ContactSection
              data={homeData.contact}
              onChange={(data) => handleSectionChange('contact', data)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}