import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import CollapsibleSection from '../../components/admin/CollapsibleSection';
import MapSection from '../../components/admin/sections/MapSection';

interface ContactData {
  title: string;
  subtitle: string;
  address: string;
  phone: string;
  email: string;
  mapUrl: string;
}

const defaultContactData: ContactData = {
  title: "Discutons de votre projet",
  subtitle: "Notre équipe d'experts est à votre écoute pour vous accompagner dans la réalisation de vos projets et répondre à toutes vos questions.",
  address: "13 route de morangis",
  phone: "+33 1 23 45 67 89",
  email: "contact@entreprise.fr",
  mapUrl: "https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=13%20route%20de%20morangis+(Aps)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
};

export default function ContactEditor() {
  const [contactData, setContactData] = useState<ContactData>(defaultContactData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadContactData();
  }, []);

  const loadContactData = async () => {
    try {
      const contactRef = doc(db, 'settings', 'contact');
      const contactDoc = await getDoc(contactRef);
      
      if (contactDoc.exists()) {
        setContactData({ ...defaultContactData, ...contactDoc.data() as ContactData });
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      toast.error('Erreur lors du chargement des données');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const contactRef = doc(db, 'settings', 'contact');
      await setDoc(contactRef, contactData);
      toast.success('Page de contact mise à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Modifier la page Contact</h1>
            <button
              onClick={handleSave}
              disabled={loading}
              className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
            >
              <Save className="h-5 w-5" />
              <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
            </button>
          </div>

          <div className="space-y-6">
            {/* Header Section */}
            <CollapsibleSection title="En-tête" defaultOpen>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Titre
                  </label>
                  <input
                    type="text"
                    value={contactData.title}
                    onChange={(e) => setContactData({ ...contactData, title: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Sous-titre
                  </label>
                  <textarea
                    value={contactData.subtitle}
                    onChange={(e) => setContactData({ ...contactData, subtitle: e.target.value })}
                    rows={3}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
              </div>
            </CollapsibleSection>

            {/* Contact Information */}
            <CollapsibleSection title="Informations de contact">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Adresse
                  </label>
                  <input
                    type="text"
                    value={contactData.address}
                    onChange={(e) => setContactData({ ...contactData, address: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Téléphone
                  </label>
                  <input
                    type="text"
                    value={contactData.phone}
                    onChange={(e) => setContactData({ ...contactData, phone: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    value={contactData.email}
                    onChange={(e) => setContactData({ ...contactData, email: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
              </div>
            </CollapsibleSection>

            {/* Map Section */}
            <CollapsibleSection title="Carte Google Maps">
              <MapSection
                mapUrl={contactData.mapUrl}
                onChange={(mapUrl) => setContactData({ ...contactData, mapUrl })}
              />
            </CollapsibleSection>
          </div>
        </div>
      </div>
    </div>
  );
}