import React, { useState } from 'react';
import { Power, Save, Plus, Trash2 } from 'lucide-react';
import { useMaintenance } from '../../../contexts/MaintenanceContext';

export default function MaintenanceForm() {
  const { maintenance, setMaintenance } = useMaintenance();
  const [newIP, setNewIP] = useState('');
  const [formData, setFormData] = useState({
    enabled: maintenance.enabled,
    message: maintenance.message,
    startTime: maintenance.startTime || '',
    endTime: maintenance.endTime || '',
    allowedIPs: maintenance.allowedIPs || []
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await setMaintenance(formData);
  };

  const addIP = () => {
    if (newIP && !formData.allowedIPs.includes(newIP)) {
      setFormData({
        ...formData,
        allowedIPs: [...formData.allowedIPs, newIP]
      });
      setNewIP('');
    }
  };

  const removeIP = (ip: string) => {
    setFormData({
      ...formData,
      allowedIPs: formData.allowedIPs.filter(allowedIP => allowedIP !== ip)
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <button
            type="button"
            onClick={() => setFormData({ ...formData, enabled: !formData.enabled })}
            className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
              formData.enabled
                ? 'bg-red-500 text-white hover:bg-red-600'
                : 'bg-green-500 text-white hover:bg-green-600'
            }`}
          >
            <Power className="h-5 w-5" />
            <span>{formData.enabled ? 'Désactiver' : 'Activer'}</span>
          </button>
          <span className={`text-sm font-medium ${formData.enabled ? 'text-red-500' : 'text-green-500'}`}>
            {formData.enabled ? 'Mode maintenance actif' : 'Mode maintenance inactif'}
          </span>
        </div>
        <button
          type="submit"
          className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900"
        >
          <Save className="h-5 w-5" />
          <span>Enregistrer</span>
        </button>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Message de maintenance
        </label>
        <textarea
          value={formData.message}
          onChange={(e) => setFormData({ ...formData, message: e.target.value })}
          rows={3}
          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          placeholder="Message affiché pendant la maintenance..."
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Date et heure de début
          </label>
          <input
            type="datetime-local"
            value={formData.startTime}
            onChange={(e) => setFormData({ ...formData, startTime: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Date et heure de fin
          </label>
          <input
            type="datetime-local"
            value={formData.endTime}
            onChange={(e) => setFormData({ ...formData, endTime: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          IPs autorisées
        </label>
        <div className="flex space-x-2 mb-4">
          <input
            type="text"
            value={newIP}
            onChange={(e) => setNewIP(e.target.value)}
            placeholder="Ajouter une IP..."
            className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
          <button
            type="button"
            onClick={addIP}
            className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900"
          >
            <Plus className="h-5 w-5" />
            <span>Ajouter</span>
          </button>
        </div>
        <div className="space-y-2">
          {formData.allowedIPs.map((ip) => (
            <div key={ip} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
              <span>{ip}</span>
              <button
                type="button"
                onClick={() => removeIP(ip)}
                className="text-red-600 hover:text-red-700"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </form>
  );
}