import React from 'react';
import ImageUpload from '../../ImageUpload';

interface HeroSectionProps {
  data: {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonLink: string;
    backgroundImage?: string;
  };
  onChange: (data: any) => void;
}

export default function HeroSection({ data, onChange }: HeroSectionProps) {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium mb-4">Section Hero</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Titre
          </label>
          <input
            type="text"
            value={data.title}
            onChange={(e) => onChange({
              ...data,
              title: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Sous-titre
          </label>
          <input
            type="text"
            value={data.subtitle}
            onChange={(e) => onChange({
              ...data,
              subtitle: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Image de fond
          </label>
          {data.backgroundImage && (
            <div className="mb-4">
              <img 
                src={data.backgroundImage} 
                alt="Hero background" 
                className="h-40 w-full object-cover rounded-lg"
              />
            </div>
          )}
          <ImageUpload
            onImageUploaded={(url) => onChange({
              ...data,
              backgroundImage: url
            })}
            onError={(error) => console.error(error)}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Texte du bouton
          </label>
          <input
            type="text"
            value={data.buttonText}
            onChange={(e) => onChange({
              ...data,
              buttonText: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Lien du bouton
          </label>
          <input
            type="text"
            value={data.buttonLink}
            onChange={(e) => onChange({
              ...data,
              buttonLink: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>
      </div>
    </div>
  );
}