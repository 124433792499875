import React from 'react';
import { Trash2 } from 'lucide-react';
import { Service } from './types';
import ImageUpload from '../../../ImageUpload';

interface ServiceCardProps {
  service: Service;
  categoryId: string;
  onUpdate: (categoryId: string, service: Service) => void;
  onDelete: (categoryId: string) => void;
}

export default function ServiceCard({ service, categoryId, onUpdate, onDelete }: ServiceCardProps) {
  const addPoint = () => {
    onUpdate(categoryId, {
      ...service,
      points: [...service.points, '']
    });
  };

  const removePoint = (index: number) => {
    const newPoints = [...service.points];
    newPoints.splice(index, 1);
    onUpdate(categoryId, {
      ...service,
      points: newPoints
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">Détails du service</h3>
        <button
          onClick={() => onDelete(categoryId)}
          className="text-red-600 hover:text-red-700"
        >
          <Trash2 className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Titre
          </label>
          <input
            type="text"
            value={service.title}
            onChange={(e) => onUpdate(categoryId, { ...service, title: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
            placeholder="Ex: Désamiantage professionnel"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Description
          </label>
          <textarea
            value={service.description}
            onChange={(e) => onUpdate(categoryId, { ...service, description: e.target.value })}
            rows={3}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
            placeholder="Ex: Solutions complètes de désamiantage pour tous types de bâtiments"
          />
        </div>

        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Points clés
            </label>
            <button
              type="button"
              onClick={addPoint}
              className="text-sm text-black hover:text-gray-700"
            >
              + Ajouter un point
            </button>
          </div>
          <div className="space-y-2">
            {service.points.map((point, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="text"
                  value={point}
                  onChange={(e) => {
                    const newPoints = [...service.points];
                    newPoints[index] = e.target.value;
                    onUpdate(categoryId, { ...service, points: newPoints });
                  }}
                  className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  placeholder="Ex: Retrait de toitures amiantées"
                />
                <button
                  onClick={() => removePoint(index)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Image
          </label>
          {service.image && (
            <div className="mb-4">
              <img
                src={service.image}
                alt={service.title}
                className="h-40 w-full object-cover rounded-lg"
              />
            </div>
          )}
          <ImageUpload
            onImageUploaded={(url) => onUpdate(categoryId, { ...service, image: url })}
            onError={(error) => console.error(error)}
          />
        </div>
      </div>
    </div>
  );
}