import React from 'react';
import { Plus, Trash2, Upload } from 'lucide-react';
import { uploadImage } from '../../../utils/uploadImage';
import toast from 'react-hot-toast';

interface ClientsSectionProps {
  data: {
    title: string;
    subtitle: string;
    items: {
      id: string;
      name: string;
      logo: string;
    }[];
  };
  onChange: (data: any) => void;
  onAddClient: () => void;
}

export default function ClientsSection({ data, onChange, onAddClient }: ClientsSectionProps) {
  const handleImageUpload = async (file: File, clientId: string) => {
    try {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        toast.error('Le fichier doit être une image');
        return;
      }

      // Validate file size (5MB max)
      if (file.size > 5 * 1024 * 1024) {
        toast.error('L\'image ne doit pas dépasser 5MB');
        return;
      }

      // Upload image and get URL
      const url = await uploadImage(file);

      // Update client logo
      const newItems = data.items.map(client =>
        client.id === clientId ? { ...client, logo: url } : client
      );
      onChange({ ...data, items: newItems });
      
      toast.success('Image téléchargée avec succès');
    } catch (error) {
      toast.error('Erreur lors du téléchargement de l\'image');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium">Section Clients</h2>
        <button
          onClick={onAddClient}
          className="flex items-center space-x-2 text-black hover:text-gray-700"
        >
          <Plus className="h-5 w-5" />
          <span>Ajouter un client</span>
        </button>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Titre
          </label>
          <input
            type="text"
            value={data.title}
            onChange={(e) => onChange({
              ...data,
              title: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Sous-titre
          </label>
          <input
            type="text"
            value={data.subtitle}
            onChange={(e) => onChange({
              ...data,
              subtitle: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div className="space-y-4">
          {data.items.map((client, index) => (
            <div key={client.id} className="p-4 border rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">Client {index + 1}</h3>
                <button
                  onClick={() => {
                    const newItems = data.items.filter(item => item.id !== client.id);
                    onChange({
                      ...data,
                      items: newItems
                    });
                  }}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom
                  </label>
                  <input
                    type="text"
                    value={client.name}
                    onChange={(e) => {
                      const newItems = [...data.items];
                      newItems[index] = { ...client, name: e.target.value };
                      onChange({
                        ...data,
                        items: newItems
                      });
                    }}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Logo
                  </label>
                  {client.logo && (
                    <div className="mb-4">
                      <img 
                        src={client.logo} 
                        alt={client.name} 
                        className="h-20 object-contain"
                      />
                    </div>
                  )}
                  <div className="relative">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          handleImageUpload(file, client.id);
                        }
                      }}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />
                    <div className="px-4 py-2 border rounded-lg flex items-center justify-center space-x-2 hover:bg-gray-50">
                      <Upload className="h-5 w-5 text-gray-400" />
                      <span className="text-gray-600">Sélectionner une image</span>
                    </div>
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Format recommandé : PNG ou SVG transparent
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}