import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { HomeData } from '../types';
import HeroSection from '../../../../components/admin/sections/HeroSection';
import AboutSection from '../../../../components/admin/sections/AboutSection';
import TypewriterSection from '../../../../components/admin/sections/TypewriterSection';
import ServicesSection from '../../../../components/admin/sections/ServicesSectionEditor';
import KeyFiguresSection from '../../../../components/admin/sections/KeyFiguresSection';
import ExperienceSection from '../../../../components/admin/sections/ExperienceSection';
import ClientsSection from '../../../../components/admin/sections/ClientsSection02';
import LatestProjectsSection from '../../../../components/admin/sections/LatestProjectsSection';
import ContactSection from '../../../../components/admin/sections/ContactSection';

interface EditorContentProps {
  homeData: HomeData;
  onSectionChange: (section: keyof HomeData, newData: any) => void;
  onAddClient: () => void;
}

interface Section {
  id: keyof HomeData;
  title: string;
  component: React.ComponentType<any>;
}

export default function EditorContent({ homeData, onSectionChange, onAddClient }: EditorContentProps) {
  const [openSections, setOpenSections] = useState<Set<string>>(new Set(['hero']));

  const sections: Section[] = [
    { id: 'hero', title: 'Section Hero', component: HeroSection },
    { id: 'about', title: 'Section À propos', component: AboutSection },
    { id: 'typewriter', title: 'Section Typewriter', component: TypewriterSection },
    { id: 'services', title: 'Section Services', component: ServicesSection },
    { id: 'keyFigures', title: 'Section Chiffres clés', component: KeyFiguresSection },
    { id: 'experience', title: 'Section Expérience', component: ExperienceSection },
    { id: 'clients', title: 'Section Clients', component: ClientsSection },
    { id: 'latestProjects', title: 'Section Derniers projets', component: LatestProjectsSection },
    { id: 'contact', title: 'Section Contact', component: ContactSection }
  ];

  const toggleSection = (sectionId: string) => {
    const newOpenSections = new Set(openSections);
    if (newOpenSections.has(sectionId)) {
      newOpenSections.delete(sectionId);
    } else {
      newOpenSections.add(sectionId);
    }
    setOpenSections(newOpenSections);
  };

  return (
    <div className="space-y-6">
      {sections.map(({ id, title, component: Component }) => (
        <div key={id} className="bg-white rounded-lg shadow">
          <button
            onClick={() => toggleSection(id)}
            className="w-full px-6 py-4 flex items-center justify-between text-left hover:bg-gray-50"
          >
            <h2 className="text-lg font-medium">{title}</h2>
            {openSections.has(id) ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </button>
          
          {openSections.has(id) && (
            <div className="p-6 border-t">
              <Component
                data={homeData[id]}
                onChange={(data: any) => onSectionChange(id, data)}
                onAddClient={id === 'clients' ? onAddClient : undefined}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}