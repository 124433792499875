import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMaintenance } from '../contexts/MaintenanceContext';
import MaintenancePage from './admin/MaintenanceMode/MaintenancePage';

interface MaintenanceWrapperProps {
  children: React.ReactNode;
}

export default function MaintenanceWrapper({ children }: MaintenanceWrapperProps) {
  const { maintenance } = useMaintenance();
  const location = useLocation();

  // Allow access to admin routes even during maintenance
  const isAdminRoute = location.pathname.startsWith('/admin');
  
  if (maintenance.enabled && !isAdminRoute) {
    return <MaintenancePage message={maintenance.message} />;
  }

  return <>{children}</>;
}