import React from 'react';

interface AboutSectionProps {
  data: {
    yearSince: string;
    title: string;
    subtitle: string;
    description: string;
  };
  onChange: (data: any) => void;
}

export default function AboutSection({ data, onChange }: AboutSectionProps) {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium mb-4">Section À propos</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Année de création
          </label>
          <input
            type="text"
            value={data.yearSince}
            onChange={(e) => onChange({
              ...data,
              yearSince: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Titre
          </label>
          <input
            type="text"
            value={data.title}
            onChange={(e) => onChange({
              ...data,
              title: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Sous-titre
          </label>
          <input
            type="text"
            value={data.subtitle}
            onChange={(e) => onChange({
              ...data,
              subtitle: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Description
          </label>
          <textarea
            value={data.description}
            onChange={(e) => onChange({
              ...data,
              description: e.target.value
            })}
            rows={4}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>
      </div>
    </div>
  );
}