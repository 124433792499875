import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion, AnimatePresence } from 'framer-motion';
import { Check } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import PageHero from '../components/PageHero';

interface Domain {
  name: string;
  description: string;
  features: string[];
  image: string;
  stats: {
    sites: string;
    satisfaction: string;
    availability: string;
  };
  details: {
    description: string;
    features: {
      title: string;
      description: string;
    }[];
  };
}

interface DomainsData {
  hero: {
    title: string;
    subtitle: string;
    backgroundImage: string;
  };
  domains: Domain[];
}

const defaultDomainsData: DomainsData = {
  hero: {
    title: "Nos Domaines d'Intervention",
    subtitle: "Expertise complète en dépollution et réhabilitation environnementale pour tous types de sites",
    backgroundImage: "https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?auto=format&fit=crop&q=80"
  },
  domains: []
};

export default function Domains() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [domainsData, setDomainsData] = useState<DomainsData>(defaultDomainsData);

  useEffect(() => {
    const loadDomainsData = async () => {
      try {
        const domainsRef = doc(db, 'settings', 'domains');
        const domainsDoc = await getDoc(domainsRef);
        
        if (domainsDoc.exists()) {
          const data = { ...defaultDomainsData, ...domainsDoc.data() as DomainsData };
          setDomainsData(data);
          // Set the first domain as selected by default
          if (data.domains.length > 0) {
            setSelectedCategory(data.domains[0].name.toLowerCase());
          }
        }
      } catch (error) {
        console.error('Erreur lors du chargement:', error);
      }
    };

    loadDomainsData();
  }, []);

  const currentDomain = domainsData.domains.find(
    domain => domain.name.toLowerCase() === selectedCategory
  );

  return (
    <>
      <Helmet>
        <title>Domaines d'intervention | DEPOL</title>
        <meta name="description" content="Découvrez nos domaines d'expertise en dépollution et réhabilitation environnementale." />
      </Helmet>

      <div className="min-h-screen bg-white">
        {/* Hero Section */}
        <PageHero
          title={domainsData.hero.title}
          subtitle={domainsData.hero.subtitle}
          backgroundImage={domainsData.hero.backgroundImage}
          height="large"
        />

        {/* Categories Section */}
        <section className="py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-center flex-wrap gap-4 mb-12">
              {domainsData.domains.map((domain) => (
                <button
                  key={domain.name}
                  onClick={() => {
                    setSelectedCategory(domain.name.toLowerCase());
                    setShowDetails(false);
                  }}
                  className={`px-6 py-2 rounded-full transition-colors ${
                    selectedCategory === domain.name.toLowerCase()
                      ? 'bg-black text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {domain.name}
                </button>
              ))}
            </div>

            {/* Content Section */}
            {currentDomain && (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
                {/* Left Column - Always visible */}
                <div className="space-y-6">
                  <div>
                    <h2 className="text-3xl font-bold mb-4">{currentDomain.name}</h2>
                    <p className="text-gray-600 text-lg">{currentDomain.description}</p>
                  </div>

                  <ul className="space-y-4">
                    {currentDomain.features.map((feature, index) => (
                      <li key={index} className="flex items-center space-x-3">
                        <span className="w-1.5 h-1.5 bg-black rounded-full" />
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <button
                    onClick={() => setShowDetails(!showDetails)}
                    className="text-black hover:text-gray-700 font-medium"
                  >
                    {showDetails ? 'Masquer les détails' : 'Plus de détails'}
                  </button>
                </div>

                {/* Right Column - Toggles between image and details */}
                <AnimatePresence mode="wait">
                  {showDetails ? (
                    <motion.div
                      key="details"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="space-y-8"
                    >
                      <p className="text-gray-600">
                        {currentDomain.details.description}
                      </p>

                      <div className="space-y-4">
                        {currentDomain.details.features.map((feature, index) => (
                          <div key={index} className="border border-gray-200 rounded-lg p-4">
                            <div className="flex items-start space-x-3">
                              <Check className="h-5 w-5 text-black flex-shrink-0 mt-0.5" />
                              <div>
                                <h3 className="font-medium mb-1">{feature.title}</h3>
                                <p className="text-gray-600 text-sm">{feature.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="grid grid-cols-3 gap-8 pt-4">
                        <div className="text-center">
                          <div className="text-3xl font-bold mb-1">{currentDomain.stats.sites}</div>
                          <div className="text-gray-600">Sites traités</div>
                        </div>
                        <div className="text-center">
                          <div className="text-3xl font-bold mb-1">{currentDomain.stats.satisfaction}</div>
                          <div className="text-gray-600">Satisfaction</div>
                        </div>
                        <div className="text-center">
                          <div className="text-3xl font-bold mb-1">{currentDomain.stats.availability}</div>
                          <div className="text-gray-600">Disponibilité</div>
                        </div>
                      </div>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="image"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="relative"
                    >
                      {/* Black square background */}
                      <div className="absolute -top-6 -right-6 w-full h-full bg-black rounded-lg z-0" />
                      
                      {/* Main image */}
                      <div className="relative rounded-lg overflow-hidden">
                        <img
                          src={currentDomain.image}
                          alt={currentDomain.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}