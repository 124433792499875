import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Home, ArrowLeft, Search } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

export default function Error404() {
  return (
    <>
      <Helmet>
        <title>Page non trouvée | DEPOL</title>
        <meta name="description" content="La page que vous recherchez n'existe pas ou a été déplacée." />
      </Helmet>

      <div className="min-h-screen bg-gray-50 flex flex-col">
        {/* Animated Background Pattern */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-gray-100" />
          <motion.div
            className="absolute inset-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2 }}
          >
            {[...Array(20)].map((_, i) => (
              <div
                key={i}
                className="absolute bg-gray-200 rounded-full"
                style={{
                  width: Math.random() * 300 + 50,
                  height: Math.random() * 300 + 50,
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  opacity: 0.1,
                }}
              />
            ))}
          </motion.div>
        </div>

        {/* Content */}
        <div className="relative flex-1 flex items-center justify-center">
          <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              {/* 404 Text */}
              <div className="relative">
                <motion.h1
                  className="text-9xl font-bold text-black opacity-5"
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 0.05 }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                >
                  404
                </motion.h1>
                <motion.div
                  className="absolute inset-0 flex items-center justify-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h2 className="text-3xl font-bold text-gray-900">
                    Page non trouvée
                  </h2>
                </motion.div>
              </div>

              {/* Message */}
              <motion.p
                className="mt-6 text-lg text-gray-600 max-w-lg mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.6 }}
              >
                Désolé, la page que vous recherchez semble avoir été déplacée ou n'existe plus.
              </motion.p>

              {/* Search Bar */}
              <motion.div
                className="mt-8 max-w-md mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.8 }}
              >
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Rechercher sur le site..."
                    className="w-full px-4 py-3 pl-12 border border-gray-300 rounded-lg focus:ring-2 focus:ring-black focus:border-transparent"
                  />
                  <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                </div>
              </motion.div>

              {/* Action Buttons */}
              <motion.div
                className="mt-8 flex flex-col sm:flex-row items-center justify-center gap-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 1 }}
              >
                <Link
                  to="/"
                  className="inline-flex items-center px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-900 transition-colors"
                >
                  <Home className="h-5 w-5 mr-2" />
                  Page d'accueil
                </Link>
                <button
                  onClick={() => window.history.back()}
                  className="inline-flex items-center px-6 py-3 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <ArrowLeft className="h-5 w-5 mr-2" />
                  Page précédente
                </button>
              </motion.div>
            </motion.div>
          </div>
        </div>

        {/* Quick Links */}
        <motion.div
          className="relative py-12 bg-white/50 backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h3 className="text-lg font-medium text-gray-900 mb-6">
                Pages populaires
              </h3>
              <div className="flex flex-wrap justify-center gap-4">
                <Link
                  to="/about"
                  className="px-4 py-2 text-sm text-gray-700 hover:text-black transition-colors"
                >
                  À propos
                </Link>
                <Link
                  to="/contact"
                  className="px-4 py-2 text-sm text-gray-700 hover:text-black transition-colors"
                >
                  Contact
                </Link>
                <Link
                  to="/domains"
                  className="px-4 py-2 text-sm text-gray-700 hover:text-black transition-colors"
                >
                  Domaines d'intervention
                </Link>
                <Link
                  to="/realisations"
                  className="px-4 py-2 text-sm text-gray-700 hover:text-black transition-colors"
                >
                  Réalisations
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}