import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Download, Trash2, Archive } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';

interface Application {
  id: string;
  jobTitle: string;
  department: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  linkedin?: string;
  portfolio?: string;
  availability: string;
  salary: string;
  resumeUrl: string;
  coverLetterUrl?: string;
  status: 'pending' | 'reviewed' | 'accepted' | 'rejected';
  appliedAt: string;
  archived?: boolean;
}

export default function Recruitment() {
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    loadApplications();
  }, [showArchived]);

  const loadApplications = async () => {
    try {
      const applicationsSnapshot = await getDocs(collection(db, 'applications'));
      const applicationsData = applicationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Application[];
      
      // Filter based on archive status
      const filteredApplications = applicationsData.filter(app => 
        showArchived ? app.archived : !app.archived
      );
      
      // Sort by date, most recent first
      filteredApplications.sort((a, b) => 
        new Date(b.appliedAt).getTime() - new Date(a.appliedAt).getTime()
      );
      
      setApplications(filteredApplications);
    } catch (error) {
      console.error('Error loading applications:', error);
      toast.error('Erreur lors du chargement des candidatures');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (url: string, fileName: string) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Téléchargement démarré');
    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);
      toast.error('Erreur lors du téléchargement du fichier');
    }
  };

  const handleStatusChange = async (applicationId: string, newStatus: Application['status']) => {
    try {
      const applicationRef = doc(db, 'applications', applicationId);
      await updateDoc(applicationRef, { status: newStatus });
      toast.success('Statut mis à jour avec succès');
      loadApplications();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Erreur lors de la mise à jour du statut');
    }
  };

  const handleArchive = async (applicationId: string) => {
    try {
      const applicationRef = doc(db, 'applications', applicationId);
      await updateDoc(applicationRef, { archived: true });
      toast.success('Candidature archivée avec succès');
      loadApplications();
    } catch (error) {
      console.error('Error archiving application:', error);
      toast.error('Erreur lors de l\'archivage');
    }
  };

  const handleDelete = async (applicationId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette candidature ?')) {
      try {
        await deleteDoc(doc(db, 'applications', applicationId));
        toast.success('Candidature supprimée avec succès');
        loadApplications();
      } catch (error) {
        console.error('Error deleting application:', error);
        toast.error('Erreur lors de la suppression');
      }
    }
  };

  const getStatusColor = (status: Application['status']) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'reviewed':
        return 'bg-blue-100 text-blue-800';
      case 'accepted':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">
              {showArchived ? 'Candidatures archivées' : 'Gestion des candidatures'}
            </h1>
            <button
              onClick={() => setShowArchived(!showArchived)}
              className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700"
            >
              <Archive className="h-5 w-5 mr-2" />
              {showArchived ? 'Voir les candidatures actives' : 'Voir les archives'}
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Candidat
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Poste
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Statut
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Documents
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {applications.map((application) => (
                      <tr key={application.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-col">
                            <div className="text-sm font-medium text-gray-900">
                              {application.firstName} {application.lastName}
                            </div>
                            <div className="text-sm text-gray-500">
                              {application.email}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{application.jobTitle}</div>
                          <div className="text-sm text-gray-500">{application.department}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(application.appliedAt).toLocaleDateString('fr-FR')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <select
                            value={application.status}
                            onChange={(e) => handleStatusChange(application.id, e.target.value as Application['status'])}
                            className={`text-xs font-semibold rounded-full px-2 py-1 ${getStatusColor(application.status)}`}
                          >
                            <option value="pending">En attente</option>
                            <option value="reviewed">Examinée</option>
                            <option value="accepted">Acceptée</option>
                            <option value="rejected">Refusée</option>
                          </select>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-2">
                          <button
                            onClick={() => handleDownload(application.resumeUrl, `CV_${application.firstName}_${application.lastName}.pdf`)}
                            className="text-indigo-600 hover:text-indigo-900 flex items-center"
                          >
                            <Download className="h-4 w-4 mr-1" />
                            CV
                          </button>
                          {application.coverLetterUrl && (
                            <button
                              onClick={() => handleDownload(application.coverLetterUrl!, `LM_${application.firstName}_${application.lastName}.pdf`)}
                              className="text-indigo-600 hover:text-indigo-900 flex items-center"
                            >
                              <Download className="h-4 w-4 mr-1" />
                              LM
                            </button>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <div className="flex items-center space-x-2">
                            {!showArchived && (
                              <button
                                onClick={() => handleArchive(application.id)}
                                className="text-gray-600 hover:text-gray-900"
                                title="Archiver"
                              >
                                <Archive className="h-5 w-5" />
                              </button>
                            )}
                            <button
                              onClick={() => handleDelete(application.id)}
                              className="text-red-600 hover:text-red-900"
                              title="Supprimer"
                            >
                              <Trash2 className="h-5 w-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}