import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { SEOData, PageSEO } from '../types/seo';
import { getSEODataFromCache, setSEODataToCache } from '../utils/seoCache';

export const useSEO = (page: keyof SEOData['pages']) => {
  const [seo, setSeo] = useState<PageSEO | null>(null);

  useEffect(() => {
    const loadSEO = async () => {
      // Try to get data from cache first
      const cachedData = getSEODataFromCache();
      if (cachedData) {
        console.log('Using cached SEO data for', page);
        setSeo(cachedData.pages[page]);
        return;
      }

      // If no cache or expired, load from Firestore
      try {
        console.log('Loading SEO data from Firestore for', page);
        const seoRef = doc(db, 'settings', 'seo');
        const seoDoc = await getDoc(seoRef);
        
        if (seoDoc.exists()) {
          const seoData = seoDoc.data() as SEOData;
          setSeo(seoData.pages[page]);
          
          // Update cache if enabled
          if (seoData.cache.enabled) {
            console.log('Updating SEO cache');
            setSEODataToCache(seoData);
          }
        }
      } catch (error) {
        console.error('Error loading SEO data:', error);
      }
    };

    loadSEO();
  }, [page]);

  return seo;
};