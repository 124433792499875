import React from 'react';
import { MapPin, Phone, Mail, Send } from 'lucide-react';
import ContactInfoCard from '../ContactInfoCard';

interface ContactProps {
  data: {
    title: string;
    subtitle: string;
    address: string;
    phone: string;
    email: string;
  };
}

export default function ContactSection({ data }: ContactProps) {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <div>
            <h2 className="text-4xl font-bold mb-6">{data.title}</h2>
            <p className="text-gray-600 mb-8">{data.subtitle}</p>
            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <MapPin className="h-6 w-6 text-gray-400" />
                <div>
                  <h3 className="font-medium">Notre adresse</h3>
                  <p className="text-gray-600">{data.address}</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <Phone className="h-6 w-6 text-gray-400" />
                <div>
                  <h3 className="font-medium">Téléphone</h3>
                  <p className="text-gray-600">{data.phone}</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <Mail className="h-6 w-6 text-gray-400" />
                <div>
                  <h3 className="font-medium">Email</h3>
                  <p className="text-gray-600">{data.email}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <form className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <input
                  type="text"
                  placeholder="Nom complet"
                  className="px-4 py-3 border rounded-lg"
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="px-4 py-3 border rounded-lg"
                />
              </div>
              <input
                type="text"
                placeholder="Sujet"
                className="w-full px-4 py-3 border rounded-lg"
              />
              <textarea
                placeholder="Message"
                rows={6}
                className="w-full px-4 py-3 border rounded-lg"
              ></textarea>
              <button
                type="submit"
                className="w-full bg-black text-white py-4 rounded-lg hover:bg-gray-900 flex items-center justify-center"
              >
                <span>Envoyer le message</span>
                <Send className="ml-2 h-5 w-5" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}