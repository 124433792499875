import React from 'react';
import { Save } from 'lucide-react';

interface EditorHeaderProps {
  loading: boolean;
  onSave: () => void;
}

export default function EditorHeader({ loading, onSave }: EditorHeaderProps) {
  return (
    <div className="flex justify-between items-center mb-8">
      <h1 className="text-2xl font-bold">Modifier la page d'accueil (Version 2)</h1>
      <button
        onClick={onSave}
        disabled={loading}
        className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
      >
        <Save className="h-5 w-5" />
        <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
      </button>
    </div>
  );
}