import React from 'react';
import { Link } from 'react-router-dom';
import { Clock, Tag } from 'lucide-react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface NewsCardProps {
  article: {
    id: string;
    slug: string;
    title: string;
    excerpt: string;
    image: string;
    category: string;
    publishedAt: string;
    readTime: number;
    author: {
      name: string;
      avatar: string;
    };
  };
}

export default function NewsCard({ article }: NewsCardProps) {
  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <Link to={`/news/${article.slug}`} className="block">
        <div className="relative h-48 overflow-hidden">
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
          />
          <div className="absolute top-4 left-4">
            <span className="px-3 py-1 bg-black text-white text-sm font-medium rounded-full">
              {article.category}
            </span>
          </div>
        </div>
        
        <div className="p-6">
          <div className="flex items-center space-x-4 mb-4">
            <img
              src={article.author.avatar}
              alt={article.author.name}
              className="w-10 h-10 rounded-full"
            />
            <div>
              <p className="text-sm font-medium text-gray-900">{article.author.name}</p>
              <div className="flex items-center text-sm text-gray-500">
                <Clock className="w-4 h-4 mr-1" />
                <span>{format(new Date(article.publishedAt), 'dd MMM yyyy', { locale: fr })}</span>
                <span className="mx-2">•</span>
                <span>{article.readTime} min de lecture</span>
              </div>
            </div>
          </div>

          <h3 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2 hover:text-gray-700 transition-colors">
            {article.title}
          </h3>
          
          <p className="text-gray-600 mb-4 line-clamp-2">
            {article.excerpt}
          </p>

          <div className="flex items-center text-sm text-gray-500">
            <Tag className="w-4 h-4 mr-2" />
            <span className="hover:text-black transition-colors">Lire l'article</span>
          </div>
        </div>
      </Link>
    </motion.article>
  );
}