import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import MarqueeTrack from './MarqueeTrack';

interface Client {
  id: string;
  name: string;
  logo: string;
}

interface ClientsSection02Props {
  data: {
    title: string;
    subtitle: string;
    items: Client[];
  };
}

export default function ClientsSection02({ data }: ClientsSection02Props) {
  if (!data.items.length) {
    return null;
  }

  return (
    <section className="py-20 bg-white overflow-hidden">
      <div className="max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-12">
          <div>
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-4xl font-bold mb-2"
            >
              {data.title}
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-gray-600"
            >
              {data.subtitle}
            </motion.p>
          </div>
          <Link 
            to="/realisations" 
            className="group flex items-center text-gray-900 hover:text-gray-700 transition-colors"
          >
            <span className="mr-2">Voir toutes nos références</span>
            <ArrowRight className="h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
          </Link>
        </div>

        <div className="relative">
          <MarqueeTrack items={data.items} />
          
          {/* Gradient overlays */}
          <div className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-white to-transparent z-10" />
          <div className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-white to-transparent z-10" />
        </div>
      </div>
    </section>
  );
}