import React from 'react';
import { motion } from 'framer-motion';

interface ClientLogoProps {
  name: string;
  logo: string;
}

export default function ClientLogo({ name, logo }: ClientLogoProps) {
  return (
    <motion.div
      className="flex items-center justify-center w-[200px] h-[100px] flex-shrink-0"
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.2 }}
    >
      <div className="w-[150px] h-[60px] flex items-center justify-center">
        <img
          src={logo}
          alt={name}
          className="max-w-full max-h-full w-auto h-auto object-contain transition-all duration-300 filter grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
        />
      </div>
    </motion.div>
  );
}