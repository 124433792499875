import React, { useState } from 'react';
import { MapPin, Calendar } from 'lucide-react';
import ProjectModal from './ProjectModal';

interface ProjectCardProps {
  project: {
    id: string;
    title: string;
    description: string;
    location: {
      city: string;
      country: string;
    };
    year: string;
    category: string;
    image: string;
    slug: string;
    surface?: string;
    duration?: string;
    budget?: string;
  };
}

export default function ProjectCard({ project }: ProjectCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div 
        onClick={() => setIsModalOpen(true)}
        className="group bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-200 cursor-pointer"
      >
        <div className="relative h-64">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
          />
          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-opacity duration-200"></div>
          <div className="absolute top-4 left-4">
            <span className="px-3 py-1 bg-white text-gray-900 text-sm font-medium rounded-full">
              {project.category}
            </span>
          </div>
        </div>
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-3 group-hover:text-gray-700 transition-colors">
            {project.title}
          </h2>
          <div className="flex items-center justify-between text-gray-500">
            <div className="flex items-center">
              <MapPin className="h-4 w-4 mr-1" />
              <span className="text-sm">
                {project.location.city}
              </span>
            </div>
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-1" />
              <span className="text-sm">{project.year}</span>
            </div>
          </div>
        </div>
      </div>

      <ProjectModal
        project={project}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}