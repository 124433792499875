import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { Service, ServicesData } from './types';
import ServiceCard from './ServiceCard';

interface ServicesSectionEditorProps {
  data: ServicesData;
  onChange: (data: ServicesData) => void;
}

export default function ServicesSectionEditor({ data, onChange }: ServicesSectionEditorProps) {
  const addCategory = () => {
    const newId = `category-${Date.now()}`;
    onChange({
      ...data,
      categories: [
        ...data.categories,
        { id: newId, label: '' }
      ],
      services: {
        ...data.services,
        [newId]: {
          id: newId,
          title: '',
          description: '',
          points: [''],
          image: ''
        }
      }
    });
  };

  const removeCategory = (categoryId: string) => {
    const { [categoryId]: removedService, ...remainingServices } = data.services;
    onChange({
      ...data,
      categories: data.categories.filter(cat => cat.id !== categoryId),
      services: remainingServices
    });
  };

  const updateCategory = (categoryId: string, label: string) => {
    onChange({
      ...data,
      categories: data.categories.map(cat =>
        cat.id === categoryId ? { ...cat, label } : cat
      )
    });
  };

  const updateService = (categoryId: string, service: Service) => {
    onChange({
      ...data,
      services: {
        ...data.services,
        [categoryId]: service
      }
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-medium">Section Services</h2>
        <button
          onClick={addCategory}
          className="flex items-center space-x-2 text-black hover:text-gray-700"
        >
          <Plus className="h-5 w-5" />
          <span>Ajouter une catégorie</span>
        </button>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Titre de la section
        </label>
        <input
          type="text"
          value={data.title}
          onChange={(e) => onChange({ ...data, title: e.target.value })}
          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400 mb-8"
          placeholder="Nos domaines d'expertise"
        />
      </div>

      <div className="space-y-8">
        {data.categories.map((category) => (
          <div key={category.id} className="border-t pt-8">
            <div className="flex items-center justify-between mb-4">
              <div className="flex-1 mr-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Nom de la catégorie
                </label>
                <input
                  type="text"
                  value={category.label}
                  onChange={(e) => updateCategory(category.id, e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  placeholder="Ex: Désamiantage"
                />
              </div>
              <button
                onClick={() => removeCategory(category.id)}
                className="text-red-600 hover:text-red-700 mt-8"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>

            <ServiceCard
              service={data.services[category.id]}
              categoryId={category.id}
              onUpdate={updateService}
              onDelete={removeCategory}
            />
          </div>
        ))}
      </div>
    </div>
  );
}