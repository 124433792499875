import React from 'react';

interface KeyFiguresData {
  title?: string;
  revenue: string;
  employees: string;
  locations: string;
  jobsCreated: string;
  labels: {
    revenue: string;
    employees: string;
    locations: string;
    jobsCreated: string;
  };
}

interface KeyFiguresSectionProps {
  data: KeyFiguresData;
  onChange: (data: KeyFiguresData) => void;
}

export default function KeyFiguresSection({ data, onChange }: KeyFiguresSectionProps) {
  // Initialize labels if they don't exist
  const currentData = {
    ...data,
    labels: data.labels || {
      revenue: "Chiffre d'affaires",
      employees: "Collaborateurs",
      locations: "Implantations dans le monde",
      jobsCreated: "Créations de postes par an"
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Titre de la section
        </label>
        <input
          type="text"
          value={currentData.title || ''}
          onChange={(e) => onChange({
            ...currentData,
            title: e.target.value
          })}
          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          placeholder="Nos chiffres clés"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Revenue Section */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Libellé chiffre d'affaires
            </label>
            <input
              type="text"
              value={currentData.labels.revenue}
              onChange={(e) => onChange({
                ...currentData,
                labels: {
                  ...currentData.labels,
                  revenue: e.target.value
                }
              })}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              placeholder="Ex: Chiffre d'affaires"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Valeur chiffre d'affaires
            </label>
            <div className="relative">
              <input
                type="text"
                value={currentData.revenue}
                onChange={(e) => onChange({
                  ...currentData,
                  revenue: e.target.value
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                placeholder="Ex: 104"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">M€</span>
            </div>
          </div>
        </div>

        {/* Employees Section */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Libellé collaborateurs
            </label>
            <input
              type="text"
              value={currentData.labels.employees}
              onChange={(e) => onChange({
                ...currentData,
                labels: {
                  ...currentData.labels,
                  employees: e.target.value
                }
              })}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              placeholder="Ex: Collaborateurs"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Nombre de collaborateurs
            </label>
            <div className="relative">
              <input
                type="text"
                value={currentData.employees}
                onChange={(e) => onChange({
                  ...currentData,
                  employees: e.target.value
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                placeholder="Ex: 650"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">+</span>
            </div>
          </div>
        </div>

        {/* Locations Section */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Libellé implantations
            </label>
            <input
              type="text"
              value={currentData.labels.locations}
              onChange={(e) => onChange({
                ...currentData,
                labels: {
                  ...currentData.labels,
                  locations: e.target.value
                }
              })}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              placeholder="Ex: Implantations dans le monde"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Nombre d'implantations
            </label>
            <div className="relative">
              <input
                type="text"
                value={currentData.locations}
                onChange={(e) => onChange({
                  ...currentData,
                  locations: e.target.value
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                placeholder="Ex: 26"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">+</span>
            </div>
          </div>
        </div>

        {/* Jobs Created Section */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Libellé créations de postes
            </label>
            <input
              type="text"
              value={currentData.labels.jobsCreated}
              onChange={(e) => onChange({
                ...currentData,
                labels: {
                  ...currentData.labels,
                  jobsCreated: e.target.value
                }
              })}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              placeholder="Ex: Créations de postes par an"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Nombre de créations de postes
            </label>
            <div className="relative">
              <input
                type="text"
                value={currentData.jobsCreated}
                onChange={(e) => onChange({
                  ...currentData,
                  jobsCreated: e.target.value
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                placeholder="Ex: 40"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">+</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}