import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Phone, Mail, Save, Plus, Trash2 } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import ImageUpload from '../../components/ImageUpload';

interface MenuItem {
  id: number;
  label: string;
  path: string;
}

interface HeaderData {
  phone: string;
  email: string;
  logo: {
    default: string;
    scrolled: string;
  };
  menuItems: MenuItem[];
}

const availableLinks = [
  { label: 'Accueil', path: '/' },
  { label: 'À propos', path: '/a-propos' },
  { label: "Domaines d'intervention", path: '/domaines' },
  { label: 'Réalisations', path: '/realisations' },
  { label: 'News', path: '/news' },
  { label: 'Contact', path: '/contact' },
  { label: 'Carrières', path: '/careers' }
];

export default function HeaderEditor() {
  const [headerData, setHeaderData] = useState<HeaderData>({
    phone: '',
    email: '',
    logo: {
      default: '',
      scrolled: ''
    },
    menuItems: []
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadHeaderData();
  }, []);

  const loadHeaderData = async () => {
    try {
      const headerRef = doc(db, 'settings', 'header');
      const headerDoc = await getDoc(headerRef);
      
      if (headerDoc.exists()) {
        setHeaderData(headerDoc.data() as HeaderData);
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      toast.error('Erreur lors du chargement des données');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const headerRef = doc(db, 'settings', 'header');
      await setDoc(headerRef, headerData);
      toast.success('Header mis à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  const addMenuItem = (link: { label: string; path: string }) => {
    const newId = headerData.menuItems.length > 0 
      ? Math.max(...headerData.menuItems.map(item => item.id)) + 1 
      : 1;
    
    setHeaderData(prev => ({
      ...prev,
      menuItems: [...prev.menuItems, { id: newId, ...link }]
    }));
  };

  const removeMenuItem = (id: number) => {
    setHeaderData(prev => ({
      ...prev,
      menuItems: prev.menuItems.filter(item => item.id !== id)
    }));
  };

  const moveMenuItem = (id: number, direction: 'up' | 'down') => {
    const index = headerData.menuItems.findIndex(item => item.id === id);
    if (
      (direction === 'up' && index === 0) || 
      (direction === 'down' && index === headerData.menuItems.length - 1)
    ) return;

    const newItems = [...headerData.menuItems];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    [newItems[index], newItems[newIndex]] = [newItems[newIndex], newItems[index]];
    
    setHeaderData(prev => ({
      ...prev,
      menuItems: newItems
    }));
  };

  const isLinkAvailable = (path: string) => {
    return !headerData.menuItems.some(item => item.path === path);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Modifier le Header</h1>
            <button
              onClick={handleSave}
              disabled={loading}
              className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
            >
              <Save className="h-5 w-5" />
              <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
            </button>
          </div>

          <div className="bg-white rounded-lg shadow p-6 space-y-8">
            {/* Logos Section */}
            <div>
              <h2 className="text-lg font-medium mb-6">Logos</h2>
              
              {/* Default Logo */}
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Logo principal
                </label>
                {headerData.logo.default && (
                  <div className="mb-4">
                    <img 
                      src={headerData.logo.default} 
                      alt="Logo principal" 
                      className="h-12 object-contain"
                    />
                  </div>
                )}
                <ImageUpload
                  onImageUploaded={(url) => setHeaderData({
                    ...headerData,
                    logo: { ...headerData.logo, default: url }
                  })}
                  onError={(error) => toast.error(error)}
                />
              </div>

              {/* Scrolled Logo */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Logo au défilement (version sombre)
                </label>
                {headerData.logo.scrolled && (
                  <div className="mb-4">
                    <img 
                      src={headerData.logo.scrolled} 
                      alt="Logo au défilement" 
                      className="h-12 object-contain"
                    />
                  </div>
                )}
                <ImageUpload
                  onImageUploaded={(url) => setHeaderData({
                    ...headerData,
                    logo: { ...headerData.logo, scrolled: url }
                  })}
                  onError={(error) => toast.error(error)}
                />
              </div>
            </div>

            {/* Contact Information */}
            <div>
              <h2 className="text-lg font-medium mb-4">Informations de contact</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="flex items-center space-x-2 text-sm font-medium text-gray-700 mb-2">
                    <Phone className="h-4 w-4" />
                    <span>Téléphone</span>
                  </label>
                  <input
                    type="text"
                    value={headerData.phone}
                    onChange={(e) => setHeaderData({ ...headerData, phone: e.target.value })}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                    placeholder="+33 1 23 45 67 89"
                  />
                </div>
                <div>
                  <label className="flex items-center space-x-2 text-sm font-medium text-gray-700 mb-2">
                    <Mail className="h-4 w-4" />
                    <span>Email</span>
                  </label>
                  <input
                    type="email"
                    value={headerData.email}
                    onChange={(e) => setHeaderData({ ...headerData, email: e.target.value })}
                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                    placeholder="contact@example.com"
                  />
                </div>
              </div>
            </div>

            {/* Menu Items */}
            <div>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium">Menu de navigation</h2>
                <div className="relative">
                  <select
                    onChange={(e) => {
                      const link = availableLinks.find(l => l.path === e.target.value);
                      if (link) addMenuItem(link);
                    }}
                    className="appearance-none px-4 py-2 pr-8 border rounded-lg focus:ring-2 focus:ring-gray-400 bg-white"
                    value=""
                  >
                    <option value="" disabled>Ajouter un lien</option>
                    {availableLinks.map((link) => (
                      isLinkAvailable(link.path) && (
                        <option key={link.path} value={link.path}>
                          {link.label}
                        </option>
                      )
                    ))}
                  </select>
                  <Plus className="absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 pointer-events-none" />
                </div>
              </div>

              <div className="space-y-4">
                {headerData.menuItems.map((item, index) => (
                  <div key={item.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center space-x-4">
                      <span className="font-medium">{item.label}</span>
                      <span className="text-gray-500 text-sm">{item.path}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => moveMenuItem(item.id, 'up')}
                        disabled={index === 0}
                        className="p-1 text-gray-500 hover:text-gray-700 disabled:opacity-50"
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => moveMenuItem(item.id, 'down')}
                        disabled={index === headerData.menuItems.length - 1}
                        className="p-1 text-gray-500 hover:text-gray-700 disabled:opacity-50"
                      >
                        ↓
                      </button>
                      <button
                        onClick={() => removeMenuItem(item.id)}
                        className="p-1 text-red-600 hover:text-red-700"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ))}

                {headerData.menuItems.length === 0 && (
                  <div className="text-center py-8 text-gray-500">
                    Aucun lien dans le menu. Utilisez le sélecteur ci-dessus pour en ajouter.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}