import React, { useState, useRef, useEffect } from 'react';
import { 
  Bold, Italic, Underline, List, ListOrdered, 
  Link, Image, AlignLeft, AlignCenter, AlignRight,
  Type, Quote, Code
} from 'lucide-react';

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export default function RichTextEditor({ value, onChange, className = '' }: RichTextEditorProps) {
  const editorRef = useRef<HTMLDivElement>(null);
  const [currentFormat, setCurrentFormat] = useState<string[]>([]);

  const execCommand = (command: string, value: string | undefined = undefined) => {
    document.execCommand(command, false, value);
    updateCurrentFormat();
    handleChange();
  };

  const handleChange = () => {
    if (editorRef.current) {
      onChange(editorRef.current.innerHTML);
    }
  };

  const updateCurrentFormat = () => {
    const formats: string[] = [];
    if (document.queryCommandState('bold')) formats.push('bold');
    if (document.queryCommandState('italic')) formats.push('italic');
    if (document.queryCommandState('underline')) formats.push('underline');
    setCurrentFormat(formats);
  };

  const handleHeading = (level: number) => {
    execCommand('formatBlock', `h${level}`);
  };

  const handleLink = () => {
    const url = prompt('Enter URL:');
    if (url) {
      execCommand('createLink', url);
    }
  };

  const handleImage = () => {
    const url = prompt('Enter image URL:');
    if (url) {
      execCommand('insertImage', url);
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = value;
    }
  }, []);

  const ToolbarButton = ({ 
    icon: Icon, 
    command, 
    value, 
    isActive = false,
    title 
  }: { 
    icon: any;
    command: string;
    value?: string;
    isActive?: boolean;
    title: string;
  }) => (
    <button
      type="button"
      onClick={() => execCommand(command, value)}
      className={`p-2 rounded hover:bg-gray-100 ${
        isActive ? 'bg-gray-100 text-black' : 'text-gray-700'
      }`}
      title={title}
    >
      <Icon className="h-5 w-5" />
    </button>
  );

  return (
    <div className={`bg-white border rounded-lg ${className}`}>
      {/* Toolbar */}
      <div className="border-b p-2 sticky top-0 bg-white z-10">
        <div className="flex flex-wrap gap-1">
          {/* Text Formatting */}
          <div className="flex items-center space-x-1 border-r pr-2">
            <div className="relative group">
              <button
                type="button"
                className="p-2 rounded hover:bg-gray-100 text-gray-700"
                title="Heading Styles"
              >
                <Type className="h-5 w-5" />
              </button>
              <div className="absolute hidden group-hover:block top-full left-0 bg-white border rounded-lg shadow-lg p-2 w-48">
                {[1, 2, 3, 4, 5, 6].map((level) => (
                  <button
                    key={level}
                    onClick={() => handleHeading(level)}
                    className="w-full text-left px-3 py-2 hover:bg-gray-100 rounded"
                  >
                    Heading {level}
                  </button>
                ))}
              </div>
            </div>
            <ToolbarButton 
              icon={Bold} 
              command="bold" 
              isActive={currentFormat.includes('bold')}
              title="Bold"
            />
            <ToolbarButton 
              icon={Italic} 
              command="italic" 
              isActive={currentFormat.includes('italic')}
              title="Italic"
            />
            <ToolbarButton 
              icon={Underline} 
              command="underline" 
              isActive={currentFormat.includes('underline')}
              title="Underline"
            />
          </div>

          {/* Alignment */}
          <div className="flex items-center space-x-1 border-r pr-2">
            <ToolbarButton 
              icon={AlignLeft} 
              command="justifyLeft" 
              title="Align Left"
            />
            <ToolbarButton 
              icon={AlignCenter} 
              command="justifyCenter" 
              title="Align Center"
            />
            <ToolbarButton 
              icon={AlignRight} 
              command="justifyRight" 
              title="Align Right"
            />
          </div>

          {/* Lists */}
          <div className="flex items-center space-x-1 border-r pr-2">
            <ToolbarButton 
              icon={List} 
              command="insertUnorderedList" 
              title="Bullet List"
            />
            <ToolbarButton 
              icon={ListOrdered} 
              command="insertOrderedList" 
              title="Numbered List"
            />
          </div>

          {/* Insert */}
          <div className="flex items-center space-x-1">
            <button
              type="button"
              onClick={handleLink}
              className="p-2 rounded hover:bg-gray-100 text-gray-700"
              title="Insert Link"
            >
              <Link className="h-5 w-5" />
            </button>
            <button
              type="button"
              onClick={handleImage}
              className="p-2 rounded hover:bg-gray-100 text-gray-700"
              title="Insert Image"
            >
              <Image className="h-5 w-5" />
            </button>
          </div>

          {/* Additional Formatting */}
          <div className="flex items-center space-x-1 border-l pl-2">
            <ToolbarButton 
              icon={Quote} 
              command="formatBlock" 
              value="blockquote"
              title="Block Quote"
            />
            <ToolbarButton 
              icon={Code} 
              command="formatBlock" 
              value="pre"
              title="Code Block"
            />
          </div>
        </div>
      </div>

      {/* Editor */}
      <div
        ref={editorRef}
        contentEditable
        onInput={handleChange}
        onKeyUp={updateCurrentFormat}
        onMouseUp={updateCurrentFormat}
        className="p-4 min-h-[300px] focus:outline-none prose prose-sm sm:prose lg:prose-lg xl:prose-xl max-w-none"
      />
    </div>
  );
}