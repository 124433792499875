import React from 'react';
import { motion } from 'framer-motion';

interface AboutProps {
  data: {
    yearSince: string;
    title: string;
    subtitle: string;
    description: string;
  };
}

export default function AboutSection({ data }: AboutProps) {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <span className="text-sm font-medium text-gray-500 tracking-wider">
              {data.yearSince}
            </span>
            <h2 className="mt-4 text-4xl font-bold text-gray-900 leading-tight">
              {data.title}
            </h2>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <p className="text-xl font-medium text-gray-900">
              {data.subtitle}
            </p>
            <p className="text-gray-600 leading-relaxed">
              {data.description}
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
}