// Previous interfaces remain the same...

export interface Domain {
  name: string;
  description: string;
  features: string[];
  image: string;
  stats: {
    sites: string;
    satisfaction: string;
    availability: string;
  };
  details: {
    description: string;
    features: {
      title: string;
      description: string;
    }[];
  };
}

export interface HomeData {
  hero: {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonLink: string;
    backgroundImage?: string;
  };
  about: {
    yearSince: string;
    title: string;
    subtitle: string;
    description: string;
  };
  typewriter: {
    prefix: string;
    words: string[];
    buttonText: string;
    buttonLink: string;
  };
  domains: Domain[];
  keyFigures: {
    title: string;
    revenue: string;
    employees: string;
    locations: string;
    jobsCreated: string;
    labels: {
      revenue: string;
      employees: string;
      locations: string;
      jobsCreated: string;
    };
  };
  experience: {
    title: string;
    subtitle: string;
    description: string;
    image: string;
    features: string[];
    steps?: {
      title: string;
      description: string;
    }[];
  };
  clients: {
    title: string;
    subtitle: string;
    items: Array<{
      id: string;
      name: string;
      logo: string;
    }>;
  };
  latestProjects: {
    title: string;
    subtitle: string;
    projects: Array<{
      id: string;
      title: string;
      description: string;
      image: string;
      category: string;
      location: {
        city: string;
        country: string;
      };
      year: string;
    }>;
  };
  contact: {
    title: string;
    subtitle: string;
    address: string;
    phone: string;
    email: string;
  };
}

export const defaultHomeData: HomeData = {
  hero: {
    title: "Dépolluer pour l'Homme & l'Environnement",
    subtitle: "Solutions professionnelles de dépollution et services environnementaux",
    buttonText: "En savoir plus",
    buttonLink: "/about",
    backgroundImage: "https://images.unsplash.com/photo-1590674899484-d5640e854abe?auto=format&fit=crop&q=80"
  },
  about: {
    yearSince: "DEPUIS 1995",
    title: "DI Environnement réalise des travaux de dépollution & de désamiantage",
    subtitle: "Notre groupe est spécialisé dans le retrait et le traitement de tous types de polluants",
    description: "Forts de notre expérience, nous proposons une offre globale qui s'étend de la dépollution des bâtiments et des sols au traitement de surfaces métalliques."
  },
  typewriter: {
    prefix: "Nos experts à vos côtés pour",
    words: ["recycler", "dépolluer", "désamianter", "innover"],
    buttonText: "Nos expertises",
    buttonLink: "/expertises"
  },
  domains: [],
  keyFigures: {
    title: "Nos chiffres clés",
    revenue: "104",
    employees: "650",
    locations: "26",
    jobsCreated: "40",
    labels: {
      revenue: "Chiffre d'affaires",
      employees: "Collaborateurs",
      locations: "Implantations dans le monde",
      jobsCreated: "Créations de postes par an"
    }
  },
  experience: {
    title: "Près de 30 ans d'expérience",
    subtitle: "Un savoir-faire maîtrisé",
    description: "Notre expertise technique et notre expérience nous permettent de répondre aux défis les plus complexes.",
    image: "https://images.unsplash.com/photo-1590674899484-d5640e854abe?auto=format&fit=crop&q=80",
    features: [
      "Près de 650 collaborateurs",
      "Une entreprise à impact",
      "Des équipements innovants et sur-mesure"
    ]
  },
  clients: {
    title: "Ils nous font confiance",
    subtitle: "Nos références",
    items: []
  },
  latestProjects: {
    title: "Nos dernières réalisations",
    subtitle: "Découvrez nos projets les plus récents",
    projects: []
  },
  contact: {
    title: "Contactez-nous",
    subtitle: "Nous sommes à votre écoute pour répondre à vos questions et vous accompagner dans vos projets.",
    address: "123 Rue de la Dépollution, 75001 Paris",
    phone: "+33 1 23 45 67 89",
    email: "contact@entreprise.fr"
  }
};