import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  LogOut, Users, Eye, Settings, Layout, Phone, 
  Briefcase, FileText, BookOpen, Info, Layers, 
  UserPlus, Search, Scale, Power, Mail
} from 'lucide-react';
import { auth } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { useMaintenance } from '../../contexts/MaintenanceContext';

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { maintenance } = useMaintenance();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      toast.error('Erreur lors de la déconnexion');
    }
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg">
      <div className="flex flex-col h-full">
        <div className="p-4 border-b">
          <h1 className="text-xl font-bold text-red-600">APS</h1>
        </div>
        
        <nav className="flex-1 p-4">
          <div className="space-y-1">
            {/* Maintenance Mode Status */}
            <div className="px-4 py-2 mb-4">
              <Link
                to="/admin/maintenance"
                className={`flex items-center justify-between rounded-lg p-2 ${
                  maintenance.enabled
                    ? 'bg-red-100 text-red-700'
                    : 'bg-green-100 text-green-700'
                }`}
              >
                <div className="flex items-center">
                  <Power className="h-5 w-5 mr-2" />
                  <span>Mode Maintenance</span>
                </div>
                <span className="text-sm font-medium">
                  {maintenance.enabled ? 'Actif' : 'Inactif'}
                </span>
              </Link>
            </div>

            {/* Mise en page */}
            <div>
              <p className="px-4 text-xs font-semibold text-gray-400 uppercase">MISE EN PAGE</p>
              <Link
                to="/admin/header"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/header')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Layout className="h-5 w-5 mr-2" />
                Header
              </Link>
              <Link
                to="/admin/footer"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/footer')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Layout className="h-5 w-5 mr-2" />
                Footer
              </Link>
            </div>

            {/* Administration */}
            <div>
              <p className="px-4 text-xs font-semibold text-gray-400 uppercase">ADMINISTRATION</p>
              <Link
                to="/admin/users"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/users')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Users className="h-5 w-5 mr-2" />
                Utilisateurs
              </Link>
              <Link
                to="/admin/profile"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/profile')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Settings className="h-5 w-5 mr-2" />
                Profil
              </Link>
              <Link
                to="/admin/recruitment"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/recruitment')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <UserPlus className="h-5 w-5 mr-2" />
                Recrutement
              </Link>
              <Link
                to="/admin/messages"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/messages')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Mail className="h-5 w-5 mr-2" />
                Messages
              </Link>
              <Link
                to="/admin/seo"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/seo')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Search className="h-5 w-5 mr-2" />
                SEO
              </Link>
              <Link
                to="/admin/legal"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/legal')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Scale className="h-5 w-5 mr-2" />
                Textes légaux
              </Link>
            </div>

            {/* Contenu */}
            <div>
              <p className="px-4 text-xs font-semibold text-gray-400 uppercase">CONTENU</p>
              <Link
                to="/admin/home2"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/home2')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Info className="h-5 w-5 mr-2" />
                Page d'accueil
              </Link>
              <Link
                to="/admin/about"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/about')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Info className="h-5 w-5 mr-2" />
                À propos
              </Link>
              <Link
                to="/admin/domains"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/domains')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Layers className="h-5 w-5 mr-2" />
                Domaines d'intervention
              </Link>
              <Link
                to="/admin/contact"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/contact')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Phone className="h-5 w-5 mr-2" />
                Contact
              </Link>
              <Link
                to="/admin/careers"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/careers')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Briefcase className="h-5 w-5 mr-2" />
                Carrières
              </Link>
              <Link
                to="/admin/realisations"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/realisations')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <FileText className="h-5 w-5 mr-2" />
                Réalisations
              </Link>
              <Link
                to="/admin/blog"
                className={`flex items-center px-4 py-2 rounded-lg ${
                  isActive('/admin/blog')
                    ? 'text-gray-900 bg-gray-100'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <BookOpen className="h-5 w-5 mr-2" />
                Blog
              </Link>
            </div>
          </div>
        </nav>

        <div className="p-4 border-t">
          <Link
            to="/"
            className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
          >
            <Eye className="h-5 w-5 mr-2" />
            Voir le site
          </Link>
          <button
            onClick={handleLogout}
            className="flex items-center px-4 py-2 mt-2 text-gray-600 hover:bg-gray-50 rounded-lg w-full"
          >
            <LogOut className="h-5 w-5 mr-2" />
            <span>Déconnexion</span>
          </button>
        </div>
      </div>
    </div>
  );
}