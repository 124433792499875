import React from 'react';
import { useHomeEditor } from './hooks/useHomeEditor';
import AdminLayout from '../../../components/admin/AdminLayout';
import EditorHeader from './components/EditorHeader';
import EditorContent from './components/EditorContent';

export default function Home2Editor() {
  const {
    homeData,
    loading,
    handleSectionChange,
    handleAddClient,
    handleSave
  } = useHomeEditor();

  return (
    <AdminLayout loading={loading}>
      <EditorHeader loading={loading} onSave={handleSave} />
      <EditorContent
        homeData={homeData}
        onSectionChange={handleSectionChange}
        onAddClient={handleAddClient}
      />
    </AdminLayout>
  );
}