import React, { useState } from 'react';
import { MapPin, Briefcase, X } from 'lucide-react';
import JobApplicationForm from './JobApplicationForm';

interface JobModalProps {
  job: {
    title: string;
    location: string;
    type: string;
    department: string;
    description: string;
    requirements: string[];
    responsibilities: string[];
  };
  isOpen: boolean;
  onClose: () => void;
}

export default function JobModal({ job, isOpen, onClose }: JobModalProps) {
  const [showApplicationForm, setShowApplicationForm] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
        </div>

        <div className="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
          <div className="relative p-8">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
            >
              <X className="h-6 w-6" />
            </button>

            {!showApplicationForm ? (
              <>
                <div className="mb-6">
                  <span className="inline-block px-3 py-1 text-sm font-medium text-gray-600 bg-gray-100 rounded-full mb-4">
                    {job.department}
                  </span>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">{job.title}</h2>
                  <div className="flex items-center space-x-6 text-gray-500">
                    <div className="flex items-center">
                      <MapPin className="h-5 w-5 mr-2" />
                      <span>{job.location}</span>
                    </div>
                    <div className="flex items-center">
                      <Briefcase className="h-5 w-5 mr-2" />
                      <span>{job.type}</span>
                    </div>
                  </div>
                </div>

                <div className="prose max-w-none mb-8">
                  <p className="text-gray-600">{job.description}</p>
                </div>

                <div className="mb-8">
                  <h3 className="text-lg font-semibold mb-4">Prérequis</h3>
                  <ul className="space-y-2">
                    {job.requirements.map((requirement, index) => (
                      <li key={index} className="flex items-start">
                        <span className="inline-block w-2 h-2 bg-black rounded-full mt-2 mr-3"></span>
                        <span className="text-gray-600">{requirement}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mb-8">
                  <h3 className="text-lg font-semibold mb-4">Responsabilités</h3>
                  <ul className="space-y-2">
                    {job.responsibilities.map((responsibility, index) => (
                      <li key={index} className="flex items-start">
                        <span className="inline-block w-2 h-2 bg-black rounded-full mt-2 mr-3"></span>
                        <span className="text-gray-600">{responsibility}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <button
                  className="w-full bg-black text-white py-3 px-6 rounded-lg hover:bg-gray-900 transition-colors duration-200"
                  onClick={() => setShowApplicationForm(true)}
                >
                  Postuler maintenant
                </button>
              </>
            ) : (
              <JobApplicationForm
                job={job}
                onClose={() => {
                  setShowApplicationForm(false);
                  onClose();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}