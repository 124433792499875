import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Save } from 'lucide-react';
import toast from 'react-hot-toast';
import AdminLayout from '../../components/admin/AdminLayout';
import CollapsibleSection from '../../components/admin/CollapsibleSection';
import RichTextEditor from '../../components/admin/RichTextEditor';

interface LegalData {
  copyright: {
    text: string;
    company: string;
  };
  privacy: {
    title: string;
    content: string;
  };
  terms: {
    title: string;
    content: string;
  };
  legalNotice: {
    title: string;
    content: string;
  };
}

const defaultLegalData: LegalData = {
  copyright: {
    text: "Tous droits réservés.",
    company: "Votre Entreprise"
  },
  privacy: {
    title: "Politique de confidentialité",
    content: "Contenu de la politique de confidentialité..."
  },
  terms: {
    title: "Conditions d'utilisation",
    content: "Contenu des conditions d'utilisation..."
  },
  legalNotice: {
    title: "Mentions légales",
    content: "Contenu des mentions légales..."
  }
};

export default function LegalEditor() {
  const [legalData, setLegalData] = useState<LegalData>(defaultLegalData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadLegalData();
  }, []);

  const loadLegalData = async () => {
    try {
      const legalRef = doc(db, 'settings', 'legal');
      const legalDoc = await getDoc(legalRef);
      
      if (legalDoc.exists()) {
        setLegalData({ ...defaultLegalData, ...legalDoc.data() as LegalData });
      }
    } catch (error) {
      console.error('Error loading legal data:', error);
      toast.error('Erreur lors du chargement des données');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const legalRef = doc(db, 'settings', 'legal');
      await setDoc(legalRef, legalData);
      toast.success('Textes légaux mis à jour avec succès');
    } catch (error) {
      console.error('Error saving legal data:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold">Textes légaux</h1>
        <button
          onClick={handleSave}
          disabled={loading}
          className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
        >
          <Save className="h-5 w-5" />
          <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
        </button>
      </div>

      <div className="space-y-6">
        {/* Copyright Section */}
        <CollapsibleSection title="Copyright" defaultOpen>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Nom de l'entreprise
              </label>
              <input
                type="text"
                value={legalData.copyright.company}
                onChange={(e) => setLegalData({
                  ...legalData,
                  copyright: { ...legalData.copyright, company: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Texte du copyright
              </label>
              <input
                type="text"
                value={legalData.copyright.text}
                onChange={(e) => setLegalData({
                  ...legalData,
                  copyright: { ...legalData.copyright, text: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              />
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-600">
                Aperçu: © {new Date().getFullYear()} {legalData.copyright.company}. {legalData.copyright.text}
              </p>
            </div>
          </div>
        </CollapsibleSection>

        {/* Privacy Policy Section */}
        <CollapsibleSection title="Politique de confidentialité">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Titre
              </label>
              <input
                type="text"
                value={legalData.privacy.title}
                onChange={(e) => setLegalData({
                  ...legalData,
                  privacy: { ...legalData.privacy, title: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Contenu
              </label>
              <RichTextEditor
                value={legalData.privacy.content}
                onChange={(value) => setLegalData({
                  ...legalData,
                  privacy: { ...legalData.privacy, content: value }
                })}
              />
            </div>
          </div>
        </CollapsibleSection>

        {/* Terms of Service Section */}
        <CollapsibleSection title="Conditions d'utilisation">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Titre
              </label>
              <input
                type="text"
                value={legalData.terms.title}
                onChange={(e) => setLegalData({
                  ...legalData,
                  terms: { ...legalData.terms, title: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Contenu
              </label>
              <RichTextEditor
                value={legalData.terms.content}
                onChange={(value) => setLegalData({
                  ...legalData,
                  terms: { ...legalData.terms, content: value }
                })}
              />
            </div>
          </div>
        </CollapsibleSection>

        {/* Legal Notice Section */}
        <CollapsibleSection title="Mentions légales">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Titre
              </label>
              <input
                type="text"
                value={legalData.legalNotice.title}
                onChange={(e) => setLegalData({
                  ...legalData,
                  legalNotice: { ...legalData.legalNotice, title: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Contenu
              </label>
              <RichTextEditor
                value={legalData.legalNotice.content}
                onChange={(value) => setLegalData({
                  ...legalData,
                  legalNotice: { ...legalData.legalNotice, content: value }
                })}
              />
            </div>
          </div>
        </CollapsibleSection>
      </div>
    </AdminLayout>
  );
}