import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

interface MaintenanceState {
  enabled: boolean;
  message: string;
  startTime?: string;
  endTime?: string;
  allowedIPs?: string[];
}

interface MaintenanceContextType {
  maintenance: MaintenanceState;
  setMaintenance: (state: MaintenanceState) => Promise<void>;
  loading: boolean;
}

const MaintenanceContext = createContext<MaintenanceContextType | undefined>(undefined);

const defaultMaintenanceState: MaintenanceState = {
  enabled: false,
  message: "Site en maintenance. Nous serons bientôt de retour.",
  allowedIPs: []
};

export function MaintenanceProvider({ children }: { children: React.ReactNode }) {
  const [maintenance, setMaintenanceState] = useState<MaintenanceState>(defaultMaintenanceState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadMaintenanceState();
  }, []);

  const loadMaintenanceState = async () => {
    try {
      const maintenanceRef = doc(db, 'settings', 'maintenance');
      const maintenanceDoc = await getDoc(maintenanceRef);
      
      if (maintenanceDoc.exists()) {
        setMaintenanceState(maintenanceDoc.data() as MaintenanceState);
      } else {
        await setDoc(maintenanceRef, defaultMaintenanceState);
      }
    } catch (error) {
      console.error('Error loading maintenance state:', error);
      toast.error('Erreur lors du chargement du mode maintenance');
    } finally {
      setLoading(false);
    }
  };

  const setMaintenance = async (state: MaintenanceState) => {
    try {
      const maintenanceRef = doc(db, 'settings', 'maintenance');
      await setDoc(maintenanceRef, state);
      setMaintenanceState(state);
      toast.success(state.enabled ? 'Mode maintenance activé' : 'Mode maintenance désactivé');
    } catch (error) {
      console.error('Error updating maintenance state:', error);
      toast.error('Erreur lors de la mise à jour du mode maintenance');
      throw error;
    }
  };

  return (
    <MaintenanceContext.Provider value={{ maintenance, setMaintenance, loading }}>
      {children}
    </MaintenanceContext.Provider>
  );
}

export function useMaintenance() {
  const context = useContext(MaintenanceContext);
  if (context === undefined) {
    throw new Error('useMaintenance must be used within a MaintenanceProvider');
  }
  return context;
}