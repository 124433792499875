import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface HeaderData {
  logo: {
    default: string;
    scrolled: string;
  };
  menuItems: {
    label: string;
    path: string;
  }[];
}

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [headerData, setHeaderData] = useState<HeaderData>({
    logo: {
      default: '',
      scrolled: ''
    },
    menuItems: []
  });

  useEffect(() => {
    const loadHeaderData = async () => {
      try {
        const headerRef = doc(db, 'settings', 'header');
        const headerDoc = await getDoc(headerRef);
        
        if (headerDoc.exists()) {
          setHeaderData(headerDoc.data() as HeaderData);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données du header:', error);
      }
    };

    loadHeaderData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed w-full z-50 transition-all duration-200 ${
      scrolled ? 'bg-white shadow-md' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0 flex items-center">
            {headerData.logo && (
              <img
                src={scrolled ? headerData.logo.scrolled : headerData.logo.default}
                alt="Logo"
                className="h-12 w-auto max-w-[200px] object-contain transition-opacity duration-200"
              />
            )}
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-8">
            <Link to="/" className={`text-lg font-medium whitespace-nowrap hover:text-gray-600 transition-colors duration-200 ${
              scrolled ? 'text-gray-900' : 'text-white'
            }`}>
              Accueil
            </Link>
            <Link to="/about" className={`text-lg font-medium whitespace-nowrap hover:text-gray-600 transition-colors duration-200 ${
              scrolled ? 'text-gray-900' : 'text-white'
            }`}>
              À propos
            </Link>
            <Link to="/domains" className={`text-lg font-medium whitespace-nowrap hover:text-gray-600 transition-colors duration-200 ${
              scrolled ? 'text-gray-900' : 'text-white'
            }`}>
              Domaines d'intervention
            </Link>
            <Link to="/realisations" className={`text-lg font-medium whitespace-nowrap hover:text-gray-600 transition-colors duration-200 ${
              scrolled ? 'text-gray-900' : 'text-white'
            }`}>
              Réalisations
            </Link>
            <Link to="/news" className={`text-lg font-medium whitespace-nowrap hover:text-gray-600 transition-colors duration-200 ${
              scrolled ? 'text-gray-900' : 'text-white'
            }`}>
              News
            </Link>
            <Link to="/contact" className={`text-lg font-medium whitespace-nowrap px-6 py-2 border-2 rounded-full transition-all duration-200 ${
              scrolled 
                ? 'border-black text-black hover:bg-black hover:text-white' 
                : 'border-white text-white hover:bg-white hover:text-black'
            }`}>
              Contact
            </Link>
            <Link to="/careers" className="text-lg font-medium whitespace-nowrap px-6 py-2 bg-black text-white rounded-full hover:bg-gray-900 transition-colors duration-200">
              Carrières
            </Link>
          </nav>

          {/* Mobile/Tablet menu button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`p-2 ${scrolled ? 'text-gray-900' : 'text-white'}`}
              aria-label="Menu"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile/Tablet menu */}
      {isOpen && (
        <div className="lg:hidden bg-white shadow-lg">
          <div className="px-4 pt-2 pb-3 space-y-2">
            <Link
              to="/"
              className="block py-3 text-lg font-medium text-gray-900 hover:text-gray-600 border-b border-gray-200"
              onClick={() => setIsOpen(false)}
            >
              Accueil
            </Link>
            <Link
              to="/about"
              className="block py-3 text-lg font-medium text-gray-900 hover:text-gray-600 border-b border-gray-200"
              onClick={() => setIsOpen(false)}
            >
              À propos
            </Link>
            <Link
              to="/domains"
              className="block py-3 text-lg font-medium text-gray-900 hover:text-gray-600 border-b border-gray-200"
              onClick={() => setIsOpen(false)}
            >
              Domaines d'intervention
            </Link>
            <Link
              to="/realisations"
              className="block py-3 text-lg font-medium text-gray-900 hover:text-gray-600 border-b border-gray-200"
              onClick={() => setIsOpen(false)}
            >
              Réalisations
            </Link>
            <Link
              to="/news"
              className="block py-3 text-lg font-medium text-gray-900 hover:text-gray-600 border-b border-gray-200"
              onClick={() => setIsOpen(false)}
            >
              News
            </Link>
            <Link
              to="/contact"
              className="block py-3 text-lg font-medium text-center border-2 border-black text-black rounded-full hover:bg-black hover:text-white transition-colors duration-200 mx-4 my-2"
              onClick={() => setIsOpen(false)}
            >
              Contact
            </Link>
            <Link
              to="/careers"
              className="block py-3 text-lg font-medium text-center bg-black text-white rounded-full hover:bg-gray-900 transition-colors duration-200 mx-4"
              onClick={() => setIsOpen(false)}
            >
              Carrières
            </Link>
          </div>
        </div>
      )}
    </header>
  );
}