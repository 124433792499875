import { useState, useCallback } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../../lib/firebase';
import { HomeData } from '../types';
import toast from 'react-hot-toast';
import { useFirebaseData } from '../../../../hooks/useFirebaseData';

export function useHomeEditor() {
  const [saving, setSaving] = useState(false);
  const { data: homeData, loading, error } = useFirebaseData<HomeData>('settings', 'homepage2');

  const handleSectionChange = useCallback((section: keyof HomeData, newData: any) => {
    if (!homeData) return;
    
    const updatedData = {
      ...homeData,
      [section]: newData
    };
    setDoc(doc(db, 'settings', 'homepage2'), updatedData)
      .catch((error) => {
        console.error('Error updating section:', error);
        toast.error('Erreur lors de la mise à jour de la section');
      });
  }, [homeData]);

  const handleAddClient = useCallback(() => {
    if (!homeData) return;

    const newId = Math.max(...(homeData.clients.items?.map(c => parseInt(c.id)) || [0]), 0) + 1;
    const updatedData = {
      ...homeData,
      clients: {
        ...homeData.clients,
        items: [
          ...(homeData.clients.items || []),
          {
            id: newId.toString(),
            name: '',
            logo: ''
          }
        ]
      }
    };

    setDoc(doc(db, 'settings', 'homepage2'), updatedData)
      .catch((error) => {
        console.error('Error adding client:', error);
        toast.error('Erreur lors de l\'ajout du client');
      });
  }, [homeData]);

  const handleSave = async () => {
    if (!homeData) return;

    setSaving(true);
    try {
      await setDoc(doc(db, 'settings', 'homepage2'), homeData);
      toast.success('Page d\'accueil mise à jour avec succès');
    } catch (error) {
      console.error('Error saving homepage:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setSaving(false);
    }
  };

  return {
    homeData: homeData || {},
    loading: loading || saving,
    error,
    handleSectionChange,
    handleAddClient,
    handleSave
  };
}