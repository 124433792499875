import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Star, Users, Briefcase } from 'lucide-react';
import PageHero from '../components/PageHero';
import JobCard from '../components/JobCard';

interface JobOffer {
  id: string;
  title: string;
  location: string;
  type: string;
  department: string;
  description: string;
  requirements: string[];
  responsibilities: string[];
}

interface CareerData {
  hero: {
    title: string;
    subtitle: string;
    backgroundImage: string;
  };
  benefits: {
    title: string;
    items: {
      id: string;
      icon: string;
      title: string;
      description: string;
    }[];
  };
  values: {
    title: string;
    items: {
      id: string;
      title: string;
      description: string;
    }[];
  };
}

const defaultCareerData: CareerData = {
  hero: {
    title: "Rejoignez notre équipe",
    subtitle: "Ensemble, construisons un avenir plus propre et plus durable",
    backgroundImage: "https://images.unsplash.com/photo-1664575602276-acd073f104c1?auto=format&fit=crop&q=80"
  },
  benefits: {
    title: "Pourquoi nous rejoindre ?",
    items: [
      {
        id: "1",
        icon: "Star",
        title: "Formation continue",
        description: "Développez vos compétences avec nos programmes de formation personnalisés"
      },
      {
        id: "2",
        icon: "Users",
        title: "Équipe dynamique",
        description: "Rejoignez une équipe passionnée et collaborative"
      },
      {
        id: "3",
        icon: "Briefcase",
        title: "Projets innovants",
        description: "Participez à des projets ambitieux et stimulants"
      }
    ]
  },
  values: {
    title: "Nos valeurs",
    items: [
      {
        id: "1",
        title: "Innovation",
        description: "Nous repoussons constamment les limites pour trouver des solutions durables"
      },
      {
        id: "2",
        title: "Engagement",
        description: "Nous sommes déterminés à faire une différence positive pour l'environnement"
      },
      {
        id: "3",
        title: "Excellence",
        description: "Nous visons l'excellence dans tout ce que nous entreprenons"
      }
    ]
  }
};

const iconComponents = {
  Star,
  Users,
  Briefcase
};

export default function Careers() {
  const [careerData, setCareerData] = useState<CareerData>(defaultCareerData);
  const [jobOffers, setJobOffers] = useState<JobOffer[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');

  useEffect(() => {
    const loadData = async () => {
      try {
        // Load career page data
        const careerDoc = await getDocs(collection(db, 'settings'));
        const careerSettings = careerDoc.docs.find(doc => doc.id === 'careers');
        if (careerSettings) {
          setCareerData({ ...defaultCareerData, ...careerSettings.data() as CareerData });
        }

        // Load job offers
        const jobsSnapshot = await getDocs(collection(db, 'jobs'));
        const jobsData = jobsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as JobOffer[];
        setJobOffers(jobsData);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  const departments = ['all', ...new Set(jobOffers.map(job => job.department))];
  const filteredJobs = selectedDepartment === 'all' 
    ? jobOffers 
    : jobOffers.filter(job => job.department === selectedDepartment);

  return (
    <>
      <Helmet>
        <title>Carrières | DEPOL</title>
        <meta name="description" content="Rejoignez notre équipe et participez à des projets innovants dans le domaine de la dépollution." />
      </Helmet>

      <div className="min-h-screen">
        <PageHero
          title={careerData.hero.title}
          subtitle={careerData.hero.subtitle}
          backgroundImage={careerData.hero.backgroundImage}
          height="large"
        />

        {/* Benefits Section */}
        <section className="py-20 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">{careerData.benefits.title}</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {careerData.benefits.items.map((benefit) => {
                const IconComponent = iconComponents[benefit.icon as keyof typeof iconComponents];
                return (
                  <motion.div
                    key={benefit.id}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="bg-white p-8 rounded-2xl shadow-lg"
                  >
                    <IconComponent className="h-8 w-8 text-gray-900 mb-4" />
                    <h3 className="text-xl font-semibold mb-4">{benefit.title}</h3>
                    <p className="text-gray-600">{benefit.description}</p>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Values Section */}
        <section className="py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">{careerData.values.title}</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {careerData.values.items.map((value) => (
                <motion.div
                  key={value.id}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  className="bg-gray-50 p-8 rounded-2xl"
                >
                  <h3 className="text-xl font-semibold mb-4">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Job Offers Section */}
        <section className="py-20 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Nos offres d'emploi</h2>
              <p className="text-xl text-gray-600">Découvrez nos opportunités de carrière</p>
            </div>

            {/* Department Filter */}
            <div className="flex justify-center mb-12">
              <div className="inline-flex rounded-lg border border-gray-200 bg-white p-1">
                {departments.map((dept) => (
                  <button
                    key={dept}
                    onClick={() => setSelectedDepartment(dept)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                      selectedDepartment === dept
                        ? 'bg-black text-white'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    {dept === 'all' ? 'Tous les postes' : dept}
                  </button>
                ))}
              </div>
            </div>

            {/* Job Listings */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredJobs.map((job) => (
                <JobCard key={job.id} job={job} />
              ))}
            </div>

            {/* Empty State */}
            {filteredJobs.length === 0 && (
              <div className="text-center py-12">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Aucune offre disponible
                </h3>
                <p className="text-gray-600">
                  Revenez plus tard pour découvrir nos nouvelles opportunités
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}