import React from 'react';
import { Plus, Trash2 } from 'lucide-react';
import ImageUpload from '../../ImageUpload';
import toast from 'react-hot-toast';

interface ClientsSectionProps {
  data: {
    title: string;
    subtitle: string;
    items: Array<{
      id: string;
      name: string;
      logo: string;
    }>;
  };
  onChange: (data: any) => void;
  onAddClient?: () => void;
}

export default function ClientsSection02({ data, onChange, onAddClient }: ClientsSectionProps) {
  const handleAddClient = () => {
    const newId = Math.max(...(data.items?.map(c => parseInt(c.id)) || [0]), 0) + 1;
    onChange({
      ...data,
      items: [
        ...(data.items || []),
        {
          id: newId.toString(),
          name: '',
          logo: ''
        }
      ]
    });
  };

  const handleRemoveClient = (clientId: string) => {
    onChange({
      ...data,
      items: data.items.filter(client => client.id !== clientId)
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-medium">Section Clients</h2>
        <button
          onClick={onAddClient || handleAddClient}
          className="flex items-center space-x-2 text-black hover:text-gray-700"
        >
          <Plus className="h-5 w-5" />
          <span>Ajouter un client</span>
        </button>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Titre
          </label>
          <input
            type="text"
            value={data.title}
            onChange={(e) => onChange({ ...data, title: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Sous-titre
          </label>
          <input
            type="text"
            value={data.subtitle}
            onChange={(e) => onChange({ ...data, subtitle: e.target.value })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div className="space-y-4">
          {data.items?.map((client) => (
            <div key={client.id} className="p-4 border rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">Client {client.id}</h3>
                <button
                  onClick={() => handleRemoveClient(client.id)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom
                  </label>
                  <input
                    type="text"
                    value={client.name}
                    onChange={(e) => {
                      const newItems = data.items.map(item =>
                        item.id === client.id ? { ...item, name: e.target.value } : item
                      );
                      onChange({ ...data, items: newItems });
                    }}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Logo
                  </label>
                  {client.logo && (
                    <div className="mb-4">
                      <img
                        src={client.logo}
                        alt={client.name}
                        className="h-20 object-contain"
                      />
                    </div>
                  )}
                  <ImageUpload
                    onImageUploaded={(url) => {
                      const newItems = data.items.map(item =>
                        item.id === client.id ? { ...item, logo: url } : item
                      );
                      onChange({ ...data, items: newItems });
                    }}
                    onError={(error) => toast.error(error)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}