import React from 'react';
import { Plus, Trash2 } from 'lucide-react';

interface ExperienceSectionProps {
  data: {
    title: string;
    subtitle: string;
    description: string;
    image: string;
    features: string[];
  };
  onChange: (data: any) => void;
}

export default function ExperienceSection({ data, onChange }: ExperienceSectionProps) {
  const addFeature = () => {
    onChange({
      ...data,
      features: [...data.features, '']
    });
  };

  const removeFeature = (index: number) => {
    const newFeatures = data.features.filter((_, i) => i !== index);
    onChange({
      ...data,
      features: newFeatures
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium mb-4">Section Expérience</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Titre
          </label>
          <input
            type="text"
            value={data.title}
            onChange={(e) => onChange({
              ...data,
              title: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Sous-titre
          </label>
          <input
            type="text"
            value={data.subtitle}
            onChange={(e) => onChange({
              ...data,
              subtitle: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Description
          </label>
          <textarea
            value={data.description}
            onChange={(e) => onChange({
              ...data,
              description: e.target.value
            })}
            rows={4}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Image URL
          </label>
          <input
            type="text"
            value={data.image}
            onChange={(e) => onChange({
              ...data,
              image: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Caractéristiques
            </label>
            <button
              onClick={addFeature}
              className="text-sm text-black hover:text-gray-700 flex items-center"
            >
              <Plus className="h-4 w-4 mr-1" />
              Ajouter
            </button>
          </div>
          <div className="space-y-2">
            {data.features.map((feature, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="text"
                  value={feature}
                  onChange={(e) => {
                    const newFeatures = [...data.features];
                    newFeatures[index] = e.target.value;
                    onChange({
                      ...data,
                      features: newFeatures
                    });
                  }}
                  className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                />
                <button
                  onClick={() => removeFeature(index)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}