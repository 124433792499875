import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { ArrowLeft } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

interface Project {
  title: string;
  description: string;
  location: {
    city: string;
    country: string;
  };
  year: string;
  category: string;
  image: string;
  gallery?: string[];
  keyPoints?: string[];
  technicalDetails?: string;
  environmentalImpact?: string;
  urbanConstraints?: string;
  results?: {
    pollutionReduction: string;
    wasteRecycled: string;
    jobsCreated: string;
  };
}

export default function ProjectDetails() {
  const { slug } = useParams<{ slug: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadProject = async () => {
      if (!slug) {
        setError(true);
        setLoading(false);
        return;
      }

      try {
        const projectsRef = collection(db, 'projects');
        const q = query(projectsRef, where('slug', '==', slug));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const projectData = querySnapshot.docs[0].data() as Project;
          setProject(projectData);
        } else {
          setError(true);
        }
      } catch (error) {
        console.error('Error loading project:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    loadProject();
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
      </div>
    );
  }

  if (error || !project) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">Projet non trouvé</h1>
          <p className="text-gray-600 mb-8">Le projet que vous recherchez n'existe pas ou a été supprimé.</p>
          <Link 
            to="/realisations" 
            className="inline-flex items-center px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-900 transition-colors"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Retour aux réalisations
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{project.title} | DEPOL</title>
        <meta name="description" content={project.description} />
      </Helmet>

      <div className="min-h-screen bg-white">
        {/* Hero Banner */}
        <div className="relative h-[400px]">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="absolute inset-0 flex items-center">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
              <h1 className="text-4xl font-bold text-white mb-4">
                {project.title}
              </h1>
              <p className="text-xl text-gray-200">
                {project.description}
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
            {/* Main Content */}
            <div className="lg:col-span-2">
              {/* Description */}
              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Description du projet</h2>
                <p className="text-gray-600 leading-relaxed">
                  {project.description}
                </p>
              </section>

              {/* Gallery */}
              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Galerie photos</h2>
                <div className="grid grid-cols-2 gap-4">
                  {project.gallery?.map((image, index) => (
                    <div key={index} className="rounded-lg overflow-hidden">
                      <img
                        src={image}
                        alt={`${project.title} - Image ${index + 1}`}
                        className="w-full h-48 object-cover"
                      />
                    </div>
                  ))}
                </div>
              </section>

              {/* Key Points */}
              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Points clés du projet</h2>
                <ul className="space-y-3">
                  {project.keyPoints?.map((point, index) => (
                    <li key={index} className="flex items-start">
                      <span className="w-2 h-2 bg-black rounded-full mt-2 mr-3"></span>
                      <span className="text-gray-600">{point}</span>
                    </li>
                  ))}
                </ul>
              </section>

              {/* Technical Details */}
              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Détails techniques</h2>
                <p className="text-gray-600 leading-relaxed">
                  {project.technicalDetails}
                </p>
              </section>

              {/* Environmental Impact */}
              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Impact environnemental</h2>
                <p className="text-gray-600 leading-relaxed">
                  {project.environmentalImpact}
                </p>
              </section>

              {/* Urban Constraints */}
              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Contraintes urbaines</h2>
                <p className="text-gray-600 leading-relaxed">
                  {project.urbanConstraints}
                </p>
              </section>

              {/* Results */}
              <section>
                <h2 className="text-2xl font-bold mb-6">Résultats obtenus</h2>
                <div className="grid grid-cols-3 gap-8">
                  <div className="text-center">
                    <div className="text-3xl font-bold text-black mb-2">-60%</div>
                    <div className="text-sm text-gray-600">Réduction de la pollution des sols</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-black mb-2">100T</div>
                    <div className="text-sm text-gray-600">Déchets recyclés</div>
                  </div>
                  <div className="text-center">
                    <div className="text-3xl font-bold text-black mb-2">350</div>
                    <div className="text-sm text-gray-600">Emplois créés</div>
                  </div>
                </div>
              </section>
            </div>

            {/* Sidebar */}
            <div className="lg:col-span-1">
              <div className="bg-gray-50 p-6 rounded-lg sticky top-24">
                <h3 className="text-lg font-bold mb-4">Informations projet</h3>
                <div className="space-y-4">
                  <div>
                    <div className="text-sm text-gray-500">Ville</div>
                    <div className="font-medium">{project.location.city}</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">Pays</div>
                    <div className="font-medium">{project.location.country}</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">Année</div>
                    <div className="font-medium">{project.year}</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">Catégorie</div>
                    <div className="font-medium">{project.category}</div>
                  </div>
                </div>

                <div className="mt-8">
                  <h3 className="text-lg font-bold mb-4">Besoin d'un service similaire ?</h3>
                  <Link
                    to="/contact"
                    className="block w-full bg-black text-white text-center py-3 rounded-lg hover:bg-gray-900 transition-colors"
                  >
                    Nous contacter
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}