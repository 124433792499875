import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHero from '../../components/PageHero';

export default function Privacy() {
  return (
    <>
      <Helmet>
        <title>Politique de confidentialité | DEPOL</title>
        <meta name="description" content="Notre politique de confidentialité détaille comment nous protégeons vos données personnelles." />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        <PageHero
          title="Politique de confidentialité"
          subtitle="Protection de vos données personnelles"
          backgroundImage="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80"
          height="medium"
        />

        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
            <section>
              <h2 className="text-2xl font-bold mb-4">1. Collecte des informations</h2>
              <p className="text-gray-600 mb-4">
                Nous collectons les informations que vous nous fournissez directement lorsque vous :
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>Remplissez un formulaire de contact</li>
                <li>Postulez à une offre d'emploi</li>
                <li>Vous inscrivez à notre newsletter</li>
                <li>Demandez un devis</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">2. Utilisation des informations</h2>
              <p className="text-gray-600 mb-4">
                Les informations que nous collectons sont utilisées pour :
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>Répondre à vos demandes et questions</li>
                <li>Traiter vos candidatures</li>
                <li>Vous envoyer des informations sur nos services</li>
                <li>Améliorer notre site web et nos services</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">3. Protection des données</h2>
              <p className="text-gray-600 mb-4">
                Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données contre :
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>L'accès non autorisé</li>
                <li>La modification</li>
                <li>La divulgation</li>
                <li>La destruction</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">4. Vos droits</h2>
              <p className="text-gray-600 mb-4">
                Conformément au RGPD, vous disposez des droits suivants :
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>Droit d'accès à vos données personnelles</li>
                <li>Droit de rectification de vos données</li>
                <li>Droit à l'effacement de vos données</li>
                <li>Droit à la limitation du traitement</li>
                <li>Droit à la portabilité de vos données</li>
                <li>Droit d'opposition au traitement</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">5. Cookies</h2>
              <p className="text-gray-600 mb-4">
                Notre site utilise des cookies pour améliorer votre expérience de navigation. Vous pouvez configurer votre navigateur pour refuser les cookies, mais certaines fonctionnalités du site pourraient ne plus fonctionner correctement.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">6. Contact</h2>
              <p className="text-gray-600">
                Pour toute question concernant notre politique de confidentialité ou pour exercer vos droits, vous pouvez nous contacter à l'adresse suivante : privacy@depol.fr
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}