import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Helmet } from 'react-helmet-async';
import PageHero from '../../components/PageHero';

interface LegalNoticeData {
  title: string;
  content: string;
}

const defaultLegalNotice: LegalNoticeData = {
  title: "Mentions légales",
  content: "Contenu des mentions légales..."
};

export default function LegalNotice() {
  const [legalNoticeData, setLegalNoticeData] = useState<LegalNoticeData>(defaultLegalNotice);

  useEffect(() => {
    const loadLegalNotice = async () => {
      try {
        const legalRef = doc(db, 'settings', 'legal');
        const legalDoc = await getDoc(legalRef);
        
        if (legalDoc.exists()) {
          const data = legalDoc.data();
          setLegalNoticeData({
            title: data.legalNotice?.title || defaultLegalNotice.title,
            content: data.legalNotice?.content || defaultLegalNotice.content
          });
        }
      } catch (error) {
        console.error('Error loading legal notice:', error);
      }
    };

    loadLegalNotice();
  }, []);

  return (
    <>
      <Helmet>
        <title>{legalNoticeData.title} | APS</title>
        <meta name="description" content="Informations légales concernant notre société et l'utilisation de notre site web." />
      </Helmet>

      <div className="min-h-screen bg-gray-50">
        <PageHero
          title={legalNoticeData.title}
          subtitle="Informations légales et réglementaires"
          backgroundImage="https://images.unsplash.com/photo-1589829545856-d10d557cf95f?auto=format&fit=crop&q=80"
          height="medium"
        />

        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="bg-white rounded-xl shadow-sm p-8">
            <div 
              className="prose prose-lg max-w-none"
              dangerouslySetInnerHTML={{ __html: legalNoticeData.content }}
            />
          </div>
        </div>
      </div>
    </>
  );
}