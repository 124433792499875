import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save, Plus, Trash2 } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import ImageUpload from '../../components/ImageUpload';
import CollapsibleSection from '../../components/admin/CollapsibleSection';

interface AboutData {
  hero: {
    title: string;
    subtitle: string;
    backgroundImage: string;
  };
  stats: {
    years: string;
    projects: string;
    clients: string;
    employees: string;
  };
  mission: {
    title: string;
    description: string;
    values: {
      id: string;
      icon: string;
      title: string;
      description: string;
    }[];
  };
  team: {
    title: string;
    subtitle: string;
    members: {
      id: string;
      name: string;
      role: string;
      image: string;
      linkedin?: string;
    }[];
  };
  certifications: {
    title: string;
    items: {
      id: string;
      name: string;
      image: string;
      description: string;
    }[];
  };
}

const defaultAboutData: AboutData = {
  hero: {
    title: "Notre Histoire et Notre Vision",
    subtitle: "Engagés pour un environnement plus propre depuis plus de 25 ans",
    backgroundImage: "https://images.unsplash.com/photo-1482731215275-a1f151646268?auto=format&fit=crop&q=80"
  },
  stats: {
    years: "25+",
    projects: "1500+",
    clients: "800+",
    employees: "150+"
  },
  mission: {
    title: "Notre Mission",
    description: "Nous nous engageons à créer un impact positif sur l'environnement en proposant des solutions innovantes et durables de dépollution.",
    values: []
  },
  team: {
    title: "Notre Équipe de Direction",
    subtitle: "Des experts passionnés qui dirigent notre entreprise vers l'excellence",
    members: []
  },
  certifications: {
    title: "Nos Certifications",
    items: []
  }
};

export default function AboutEditor() {
  const [aboutData, setAboutData] = useState<AboutData>(defaultAboutData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAboutData();
  }, []);

  const loadAboutData = async () => {
    try {
      const aboutRef = doc(db, 'settings', 'about');
      const aboutDoc = await getDoc(aboutRef);
      
      if (aboutDoc.exists()) {
        setAboutData({ ...defaultAboutData, ...aboutDoc.data() as AboutData });
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      toast.error('Erreur lors du chargement des données');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const aboutRef = doc(db, 'settings', 'about');
      await setDoc(aboutRef, aboutData);
      toast.success('Page À propos mise à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  const addTeamMember = () => {
    const newId = Math.max(...aboutData.team.members.map(m => parseInt(m.id)), 0) + 1;
    setAboutData({
      ...aboutData,
      team: {
        ...aboutData.team,
        members: [
          ...aboutData.team.members,
          {
            id: newId.toString(),
            name: '',
            role: '',
            image: '',
            linkedin: ''
          }
        ]
      }
    });
  };

  const removeTeamMember = (id: string) => {
    setAboutData({
      ...aboutData,
      team: {
        ...aboutData.team,
        members: aboutData.team.members.filter(m => m.id !== id)
      }
    });
  };

  const addValue = () => {
    const newId = Math.max(...aboutData.mission.values.map(v => parseInt(v.id)), 0) + 1;
    setAboutData({
      ...aboutData,
      mission: {
        ...aboutData.mission,
        values: [
          ...aboutData.mission.values,
          {
            id: newId.toString(),
            icon: 'Star',
            title: '',
            description: ''
          }
        ]
      }
    });
  };

  const removeValue = (id: string) => {
    setAboutData({
      ...aboutData,
      mission: {
        ...aboutData.mission,
        values: aboutData.mission.values.filter(v => v.id !== id)
      }
    });
  };

  const addCertification = () => {
    const newId = Math.max(...aboutData.certifications.items.map(c => parseInt(c.id)), 0) + 1;
    setAboutData({
      ...aboutData,
      certifications: {
        ...aboutData.certifications,
        items: [
          ...aboutData.certifications.items,
          {
            id: newId.toString(),
            name: '',
            image: '',
            description: ''
          }
        ]
      }
    });
  };

  const removeCertification = (id: string) => {
    setAboutData({
      ...aboutData,
      certifications: {
        ...aboutData.certifications,
        items: aboutData.certifications.items.filter(c => c.id !== id)
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Modifier la page À propos</h1>
            <button
              onClick={handleSave}
              disabled={loading}
              className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
            >
              <Save className="h-5 w-5" />
              <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
            </button>
          </div>

          <div className="space-y-6">
            {/* Hero Section */}
            <CollapsibleSection title="Section Hero" defaultOpen>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Titre
                  </label>
                  <input
                    type="text"
                    value={aboutData.hero.title}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      hero: { ...aboutData.hero, title: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Sous-titre
                  </label>
                  <input
                    type="text"
                    value={aboutData.hero.subtitle}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      hero: { ...aboutData.hero, subtitle: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Image de fond
                  </label>
                  {aboutData.hero.backgroundImage && (
                    <div className="mb-4">
                      <img 
                        src={aboutData.hero.backgroundImage} 
                        alt="Hero background" 
                        className="h-40 w-full object-cover rounded-lg"
                      />
                    </div>
                  )}
                  <ImageUpload
                    onImageUploaded={(url) => setAboutData({
                      ...aboutData,
                      hero: { ...aboutData.hero, backgroundImage: url }
                    })}
                    onError={(error) => toast.error(error)}
                  />
                </div>
              </div>
            </CollapsibleSection>

            {/* Stats Section */}
            <CollapsibleSection title="Statistiques">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Années d'expérience
                  </label>
                  <input
                    type="text"
                    value={aboutData.stats.years}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      stats: { ...aboutData.stats, years: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Projets réalisés
                  </label>
                  <input
                    type="text"
                    value={aboutData.stats.projects}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      stats: { ...aboutData.stats, projects: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Clients satisfaits
                  </label>
                  <input
                    type="text"
                    value={aboutData.stats.clients}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      stats: { ...aboutData.stats, clients: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Collaborateurs
                  </label>
                  <input
                    type="text"
                    value={aboutData.stats.employees}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      stats: { ...aboutData.stats, employees: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
              </div>
            </CollapsibleSection>

            {/* Mission Section */}
            <CollapsibleSection title="Mission et Valeurs">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Titre
                  </label>
                  <input
                    type="text"
                    value={aboutData.mission.title}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      mission: { ...aboutData.mission, title: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Description
                  </label>
                  <textarea
                    value={aboutData.mission.description}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      mission: { ...aboutData.mission, description: e.target.value }
                    })}
                    rows={4}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-sm font-medium text-gray-700">Valeurs</h3>
                    <button
                      onClick={addValue}
                      className="text-sm text-black hover:text-gray-700 flex items-center"
                    >
                      <Plus className="h-4 w-4 mr-1" />
                      Ajouter une valeur
                    </button>
                  </div>
                  <div className="space-y-4">
                    {aboutData.mission.values.map((value) => (
                      <div key={value.id} className="p-4 border rounded-lg">
                        <div className="flex justify-between items-center mb-4">
                          <h4 className="font-medium">Valeur</h4>
                          <button
                            onClick={() => removeValue(value.id)}
                            className="text-red-600 hover:text-red-700"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </div>
                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Titre
                            </label>
                            <input
                              type="text"
                              value={value.title}
                              onChange={(e) => {
                                const newValues = aboutData.mission.values.map(v =>
                                  v.id === value.id ? { ...v, title: e.target.value } : v
                                );
                                setAboutData({
                                  ...aboutData,
                                  mission: { ...aboutData.mission, values: newValues }
                                });
                              }}
                              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Description
                            </label>
                            <textarea
                              value={value.description}
                              onChange={(e) => {
                                const newValues = aboutData.mission.values.map(v =>
                                  v.id === value.id ? { ...v, description: e.target.value } : v
                                );
                                setAboutData({
                                  ...aboutData,
                                  mission: { ...aboutData.mission, values: newValues }
                                });
                              }}
                              rows={3}
                              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CollapsibleSection>

            {/* Team Section */}
            <CollapsibleSection title="Équipe">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Titre de la section
                  </label>
                  <input
                    type="text"
                    value={aboutData.team.title}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      team: { ...aboutData.team, title: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Sous-titre
                  </label>
                  <input
                    type="text"
                    value={aboutData.team.subtitle}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      team: { ...aboutData.team, subtitle: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-sm font-medium text-gray-700">Membres de l'équipe</h3>
                    <button
                      onClick={addTeamMember}
                      className="text-sm text-black hover:text-gray-700 flex items-center"
                    >
                      <Plus className="h-4 w-4 mr-1" />
                      Ajouter un membre
                    </button>
                  </div>

                  <div className="space-y-6">
                    {aboutData.team.members.map((member) => (
                      <div key={member.id} className="p-4 border rounded-lg">
                        <div className="flex justify-between items-center mb-4">
                          <h4 className="font-medium">{member.name || 'Nouveau membre'}</h4>
                          <button
                            onClick={() => removeTeamMember(member.id)}
                            className="text-red-600 hover:text-red-700"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </div>

                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Nom
                            </label>
                            <input
                              type="text"
                              value={member.name}
                              onChange={(e) => {
                                const newMembers = aboutData.team.members.map(m =>
                                  m.id === member.id ? { ...m, name: e.target.value } : m
                                );
                                setAboutData({
                                  ...aboutData,
                                  team: { ...aboutData.team, members: newMembers }
                                });
                              }}
                              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Rôle
                            </label>
                            <input
                              type="text"
                              value={member.role}
                              onChange={(e) => {
                                const newMembers = aboutData.team.members.map(m =>
                                  m.id === member.id ? { ...m, role: e.target.value } : m
                                );
                                setAboutData({
                                  ...aboutData,
                                  team: { ...aboutData.team, members: newMembers }
                                });
                              }}
                              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Photo
                            </label>
                            {member.image && (
                              <div className="mb-4">
                                <img 
                                  src={member.image} 
                                  alt={member.name} 
                                  className="h-20 w-20 object-cover rounded-full"
                                />
                              </div>
                            )}
                            <ImageUpload
                              onImageUploaded={(url) => {
                                const newMembers = aboutData.team.members.map(m =>
                                  m.id === member.id ? { ...m, image: url } : m
                                );
                                setAboutData({
                                  ...aboutData,
                                  team: { ...aboutData.team, members: newMembers }
                                });
                              }}
                              onError={(error) => toast.error(error)}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              LinkedIn URL
                            </label>
                            <input
                              type="url"
                              value={member.linkedin}
                              onChange={(e) => {
                                const newMembers = aboutData.team.members.map(m =>
                                  m.id === member.id ? { ...m, linkedin: e.target.value } : m
                                );
                                setAboutData({
                                  ...aboutData,
                                  team: { ...aboutData.team, members: newMembers }
                                });
                              }}
                              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                              placeholder="https://linkedin.com/in/..."
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CollapsibleSection>

            {/* Certifications Section */}
            <CollapsibleSection title="Certifications">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Titre de la section
                  </label>
                  <input
                    type="text"
                    value={aboutData.certifications.title}
                    onChange={(e) => setAboutData({
                      ...aboutData,
                      certifications: { ...aboutData.certifications, title: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-sm font-medium text-gray-700">Liste des certifications</h3>
                    <button
                      onClick={addCertification}
                      className="text-sm text-black hover:text-gray-700 flex items-center"
                    >
                      <Plus className="h-4 w-4 mr-1" />
                      Ajouter une certification
                    </button>
                  </div>

                  <div className="space-y-6">
                    {aboutData.certifications.items.map((certification) => (
                      <div key={certification.id} className="p-4 border rounded-lg">
                        <div className="flex justify-between items-center mb-4">
                          <h4 className="font-medium">{certification.name || 'Nouvelle certification'}</h4>
                          <button
                            onClick={() => removeCertification(certification.id)}
                            className="text-red-600 hover:text-red-700"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </div>

                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Nom
                            </label>
                            <input
                              type="text"
                              value={certification.name}
                              onChange={(e) => {
                                const newItems = aboutData.certifications.items.map(c =>
                                  c.id === certification.id ? { ...c, name: e.target.value } : c
                                );
                                setAboutData({
                                  ...aboutData,
                                  certifications: { ...aboutData.certifications, items: newItems }
                                });
                              }}
                              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Logo
                            </label>
                            {certification.image && (
                              <div className="mb-4">
                                <img 
                                  src={certification.image} 
                                  alt={certification.name} 
                                  className="h-20 object-contain"
                                />
                              </div>
                            )}
                            <ImageUpload
                              onImageUploaded={(url) => {
                                const newItems = aboutData.certifications.items.map(c =>
                                  c.id === certification.id ? { ...c, image: url } : c
                                );
                                setAboutData({
                                  ...aboutData,
                                  certifications: { ...aboutData.certifications, items: newItems }
                                });
                              }}
                              onError={(error) => toast.error(error)}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Description
                            </label>
                            <textarea
                              value={certification.description}
                              onChange={(e) => {
                                const newItems = aboutData.certifications.items.map(c =>
                                  c.id === certification.id ? { ...c, description: e.target.value } : c
                                );
                                setAboutData({
                                  ...aboutData,
                                  certifications: { ...aboutData.certifications, items: newItems }
                                });
                              }}
                              rows={3}
                              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CollapsibleSection>
          </div>
        </div>
      </div>
    </div>
  );
}