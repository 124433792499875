import { useState, useEffect } from 'react';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const cache = new Map<string, { data: any; timestamp: number }>();

export function useFirebaseData<T>(collectionName: string, documentId: string) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const cacheKey = `${collectionName}/${documentId}`;
    const cachedData = cache.get(cacheKey);

    const loadInitialData = async () => {
      try {
        const docRef = doc(db, collectionName, documentId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const initialData = docSnap.data() as T;
          setData(initialData);
          cache.set(cacheKey, {
            data: initialData,
            timestamp: Date.now()
          });
        }
      } catch (err) {
        console.error(`Error loading initial ${collectionName} data:`, err);
        toast.error('Erreur lors du chargement des données');
        setError(err instanceof Error ? err : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    };

    // Check if we have valid cached data
    if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
      setData(cachedData.data as T);
      setLoading(false);
    } else {
      // Load initial data
      loadInitialData();
    }

    // Set up real-time listener for updates
    const unsubscribe = onSnapshot(
      doc(db, collectionName, documentId),
      (doc) => {
        if (doc.exists()) {
          const newData = doc.data() as T;
          setData(newData);
          cache.set(cacheKey, {
            data: newData,
            timestamp: Date.now()
          });
        }
      },
      (err) => {
        console.error(`Error in ${collectionName} listener:`, err);
        setError(err);
      }
    );

    return () => unsubscribe();
  }, [collectionName, documentId]);

  return { data, loading, error };
}