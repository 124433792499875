import React, { createContext, useContext, useState, useEffect } from 'react';

interface CookieConsent {
  analytics: boolean;
  marketing: boolean;
  necessary: boolean;
}

interface CookieConsentContextType {
  consent: CookieConsent;
  updateConsent: (newConsent: Partial<CookieConsent>) => void;
  hasInteracted: boolean;
  acceptAll: () => void;
  rejectAll: () => void;
  openPreferences: () => void;
}

const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);

const CONSENT_STORAGE_KEY = 'cookie-consent';

export function CookieConsentProvider({ children }: { children: React.ReactNode }) {
  const [consent, setConsent] = useState<CookieConsent>({
    analytics: false,
    marketing: false,
    necessary: true, // Always true as these are essential
  });
  const [hasInteracted, setHasInteracted] = useState(true); // Start true to prevent flash
  const [showPreferences, setShowPreferences] = useState(false);

  useEffect(() => {
    // Check for existing consent
    const storedConsent = localStorage.getItem(CONSENT_STORAGE_KEY);
    if (storedConsent) {
      setConsent(JSON.parse(storedConsent));
      setHasInteracted(true);
    } else {
      setHasInteracted(false);
    }
  }, []);

  const saveConsent = (newConsent: CookieConsent) => {
    localStorage.setItem(CONSENT_STORAGE_KEY, JSON.stringify(newConsent));
    setConsent(newConsent);
    setHasInteracted(true);
    setShowPreferences(false);
  };

  const updateConsent = (newConsent: Partial<CookieConsent>) => {
    const updatedConsent = { ...consent, ...newConsent };
    saveConsent(updatedConsent);
  };

  const acceptAll = () => {
    saveConsent({
      analytics: true,
      marketing: true,
      necessary: true,
    });
  };

  const rejectAll = () => {
    saveConsent({
      analytics: false,
      marketing: false,
      necessary: true,
    });
  };

  const openPreferences = () => {
    setShowPreferences(true);
  };

  return (
    <CookieConsentContext.Provider 
      value={{
        consent,
        updateConsent,
        hasInteracted,
        acceptAll,
        rejectAll,
        openPreferences,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
}

export function useCookieConsent() {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }
  return context;
}