import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save, Plus, Trash2, Toggle } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import ImageUpload from '../../components/ImageUpload';

interface FooterData {
  logo: {
    white: string;
  };
  companyInfo: {
    description: string;
    address: string;
    phone: string;
    email: string;
  };
  quickLinks: {
    id: string;
    label: string;
    path: string;
  }[];
  socialLinks: {
    facebook: {
      url: string;
      enabled: boolean;
    };
    twitter: {
      url: string;
      enabled: boolean;
    };
    instagram: {
      url: string;
      enabled: boolean;
    };
    linkedin: {
      url: string;
      enabled: boolean;
    };
  };
  legalLinks: {
    id: string;
    label: string;
    path: string;
  }[];
}

const defaultFooterData: FooterData = {
  logo: {
    white: '/logo-white.png'
  },
  companyInfo: {
    description: 'Votre partenaire de confiance pour des solutions innovantes et personnalisées en dépollution et services environnementaux.',
    address: '123 Rue Example, 75000 Paris, France',
    phone: '+33 1 23 45 67 89',
    email: 'contact@votresite.fr'
  },
  quickLinks: [
    { id: '1', label: 'Accueil', path: '/' },
    { id: '2', label: 'À propos', path: '/about' },
    { id: '3', label: "Domaines d'intervention", path: '/domains' },
    { id: '4', label: 'Réalisations', path: '/realisations' },
    { id: '5', label: 'Actualités', path: '/news' },
    { id: '6', label: 'Carrières', path: '/careers' }
  ],
  socialLinks: {
    facebook: { url: '#', enabled: true },
    twitter: { url: '#', enabled: true },
    instagram: { url: '#', enabled: true },
    linkedin: { url: '#', enabled: true }
  },
  legalLinks: [
    { id: '1', label: 'Politique de confidentialité', path: '/privacy' },
    { id: '2', label: 'Conditions d\'utilisation', path: '/terms' },
    { id: '3', label: 'Mentions légales', path: '/mentions-legales' }
  ]
};

export default function FooterEditor() {
  const [footerData, setFooterData] = useState<FooterData>(defaultFooterData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadFooterData();
  }, []);

  const loadFooterData = async () => {
    try {
      const footerRef = doc(db, 'settings', 'footer');
      const footerDoc = await getDoc(footerRef);
      
      if (footerDoc.exists()) {
        setFooterData({ ...defaultFooterData, ...footerDoc.data() as FooterData });
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      toast.error('Erreur lors du chargement des données');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const footerRef = doc(db, 'settings', 'footer');
      await setDoc(footerRef, footerData);
      toast.success('Footer mis à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  const addQuickLink = () => {
    const newId = Math.max(...footerData.quickLinks.map(link => parseInt(link.id)), 0) + 1;
    setFooterData(prev => ({
      ...prev,
      quickLinks: [...prev.quickLinks, { id: newId.toString(), label: '', path: '' }]
    }));
  };

  const removeQuickLink = (id: string) => {
    setFooterData(prev => ({
      ...prev,
      quickLinks: prev.quickLinks.filter(link => link.id !== id)
    }));
  };

  const addLegalLink = () => {
    const newId = Math.max(...footerData.legalLinks.map(link => parseInt(link.id)), 0) + 1;
    setFooterData(prev => ({
      ...prev,
      legalLinks: [...prev.legalLinks, { id: newId.toString(), label: '', path: '' }]
    }));
  };

  const removeLegalLink = (id: string) => {
    setFooterData(prev => ({
      ...prev,
      legalLinks: prev.legalLinks.filter(link => link.id !== id)
    }));
  };

  const toggleSocialNetwork = (network: keyof typeof footerData.socialLinks) => {
    setFooterData(prev => ({
      ...prev,
      socialLinks: {
        ...prev.socialLinks,
        [network]: {
          ...prev.socialLinks[network],
          enabled: !prev.socialLinks[network].enabled
        }
      }
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Modifier le Footer</h1>
            <button
              onClick={handleSave}
              disabled={loading}
              className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
            >
              <Save className="h-5 w-5" />
              <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
            </button>
          </div>

          <div className="space-y-8">
            {/* Logo Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-medium mb-4">Logo</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Logo blanc (pour fond sombre)
                  </label>
                  {footerData.logo.white && (
                    <div className="mb-4 p-4 bg-gray-900">
                      <img
                        src={footerData.logo.white}
                        alt="Logo blanc"
                        className="h-12 object-contain"
                      />
                    </div>
                  )}
                  <ImageUpload
                    onImageUploaded={(url) => setFooterData({
                      ...footerData,
                      logo: { ...footerData.logo, white: url }
                    })}
                    onError={(error) => toast.error(error)}
                  />
                </div>
              </div>
            </div>

            {/* Company Info Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-medium mb-4">Informations de l'entreprise</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Description
                  </label>
                  <textarea
                    value={footerData.companyInfo.description}
                    onChange={(e) => setFooterData({
                      ...footerData,
                      companyInfo: { ...footerData.companyInfo, description: e.target.value }
                    })}
                    rows={3}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Adresse
                  </label>
                  <input
                    type="text"
                    value={footerData.companyInfo.address}
                    onChange={(e) => setFooterData({
                      ...footerData,
                      companyInfo: { ...footerData.companyInfo, address: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Téléphone
                  </label>
                  <input
                    type="text"
                    value={footerData.companyInfo.phone}
                    onChange={(e) => setFooterData({
                      ...footerData,
                      companyInfo: { ...footerData.companyInfo, phone: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    value={footerData.companyInfo.email}
                    onChange={(e) => setFooterData({
                      ...footerData,
                      companyInfo: { ...footerData.companyInfo, email: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
              </div>
            </div>

            {/* Quick Links Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium">Liens rapides</h2>
                <button
                  onClick={addQuickLink}
                  className="flex items-center space-x-2 text-black hover:text-gray-700"
                >
                  <Plus className="h-5 w-5" />
                  <span>Ajouter un lien</span>
                </button>
              </div>
              <div className="space-y-4">
                {footerData.quickLinks.map((link) => (
                  <div key={link.id} className="flex items-center space-x-4">
                    <div className="flex-1 grid grid-cols-2 gap-4">
                      <input
                        type="text"
                        value={link.label}
                        onChange={(e) => {
                          const newLinks = footerData.quickLinks.map(l =>
                            l.id === link.id ? { ...l, label: e.target.value } : l
                          );
                          setFooterData({ ...footerData, quickLinks: newLinks });
                        }}
                        className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                        placeholder="Libellé"
                      />
                      <input
                        type="text"
                        value={link.path}
                        onChange={(e) => {
                          const newLinks = footerData.quickLinks.map(l =>
                            l.id === link.id ? { ...l, path: e.target.value } : l
                          );
                          setFooterData({ ...footerData, quickLinks: newLinks });
                        }}
                        className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                        placeholder="Lien"
                      />
                    </div>
                    <button
                      onClick={() => removeQuickLink(link.id)}
                      className="text-red-600 hover:text-red-700"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Social Links Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-medium mb-4">Réseaux sociaux</h2>
              <div className="space-y-6">
                {/* Facebook */}
                <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <label className="font-medium">Facebook</label>
                      <button
                        onClick={() => toggleSocialNetwork('facebook')}
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          footerData.socialLinks.facebook.enabled
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {footerData.socialLinks.facebook.enabled ? 'Activé' : 'Désactivé'}
                      </button>
                    </div>
                    <input
                      type="url"
                      value={footerData.socialLinks.facebook.url}
                      onChange={(e) => setFooterData({
                        ...footerData,
                        socialLinks: {
                          ...footerData.socialLinks,
                          facebook: {
                            ...footerData.socialLinks.facebook,
                            url: e.target.value
                          }
                        }
                      })}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                      placeholder="https://facebook.com/..."
                    />
                  </div>
                </div>

                {/* Twitter */}
                <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <label className="font-medium">Twitter</label>
                      <button
                        onClick={() => toggleSocialNetwork('twitter')}
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          footerData.socialLinks.twitter.enabled
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {footerData.socialLinks.twitter.enabled ? 'Activé' : 'Désactivé'}
                      </button>
                    </div>
                    <input
                      type="url"
                      value={footerData.socialLinks.twitter.url}
                      onChange={(e) => setFooterData({
                        ...footerData,
                        socialLinks: {
                          ...footerData.socialLinks,
                          twitter: {
                            ...footerData.socialLinks.twitter,
                            url: e.target.value
                          }
                        }
                      })}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                      placeholder="https://twitter.com/..."
                    />
                  </div>
                </div>

                {/* Instagram */}
                <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <label className="font-medium">Instagram</label>
                      <button
                        onClick={() => toggleSocialNetwork('instagram')}
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          footerData.socialLinks.instagram.enabled
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {footerData.socialLinks.instagram.enabled ? 'Activé' : 'Désactivé'}
                      </button>
                    </div>
                    <input
                      type="url"
                      value={footerData.socialLinks.instagram.url}
                      onChange={(e) => setFooterData({
                        ...footerData,
                        socialLinks: {
                          ...footerData.socialLinks,
                          instagram: {
                            ...footerData.socialLinks.instagram,
                            url: e.target.value
                          }
                        }
                      })}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                      placeholder="https://instagram.com/..."
                    />
                  </div>
                </div>

                {/* LinkedIn */}
                <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <label className="font-medium">LinkedIn</label>
                      <button
                        onClick={() => toggleSocialNetwork('linkedin')}
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          footerData.socialLinks.linkedin.enabled
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {footerData.socialLinks.linkedin.enabled ? 'Activé' : 'Désactivé'}
                      </button>
                    </div>
                    <input
                      type="url"
                      value={footerData.socialLinks.linkedin.url}
                      onChange={(e) => setFooterData({
                        ...footerData,
                        socialLinks: {
                          ...footerData.socialLinks,
                          linkedin: {
                            ...footerData.socialLinks.linkedin,
                            url: e.target.value
                          }
                        }
                      })}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                      placeholder="https://linkedin.com/..."
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Legal Links Section */}
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium">Liens légaux</h2>
                <button
                  onClick={addLegalLink}
                  className="flex items-center space-x-2 text-black hover:text-gray-700"
                >
                  <Plus className="h-5 w-5" />
                  <span>Ajouter un lien</span>
                </button>
              </div>
              <div className="space-y-4">
                {footerData.legalLinks.map((link) => (
                  <div key={link.id} className="flex items-center space-x-4">
                    <div className="flex-1 grid grid-cols-2 gap-4">
                      <input
                        type="text"
                        value={link.label}
                        onChange={(e) => {
                          const newLinks = footerData.legalLinks.map(l =>
                            l.id === link.id ? { ...l, label: e.target.value } : l
                          );
                          setFooterData({ ...footerData, legalLinks: newLinks });
                        }}
                        className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                        placeholder="Libellé"
                      />
                      <input
                        type="text"
                        value={link.path}
                        onChange={(e) => {
                          const newLinks = footerData.legalLinks.map(l =>
                            l.id === link.id ? { ...l, path: e.target.value } : l
                          );
                          setFooterData({ ...footerData, legalLinks: newLinks });
                        }}
                        className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                        placeholder="Lien"
                      />
                    </div>
                    <button
                      onClick={() => removeLegalLink(link.id)}
                      className="text-red-600 hover:text-red-700"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}