import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save, Power } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import { enableFirebaseCache, disableFirebaseCache, clearFirebaseCache } from '../../utils/firebaseCache';

interface PageSEO {
  title: string;
  description: string;
}

interface SEOData {
  pages: {
    home: PageSEO;
    about: PageSEO;
    domains: PageSEO;
    contact: PageSEO;
    careers: PageSEO;
    realisations: PageSEO;
    news: PageSEO;
    privacy: PageSEO;
    terms: PageSEO;
    legalNotice: PageSEO;
  };
  cache: {
    enabled: boolean;
    duration: number; // in minutes
  };
  firebaseCache: {
    enabled: boolean;
    duration: number; // in minutes
  };
}

const defaultSEOData: SEOData = {
  pages: {
    home: {
      title: "DEPOL - Solutions de dépollution et services environnementaux",
      description: "Expert en dépollution, désamiantage et réhabilitation environnementale. Solutions professionnelles sur mesure pour vos projets."
    },
    about: {
      title: "À propos | DEPOL",
      description: "Découvrez notre histoire, notre mission et nos valeurs. Plus de 25 ans d'expertise en dépollution et services environnementaux."
    },
    domains: {
      title: "Domaines d'intervention | DEPOL",
      description: "Nos domaines d'expertise : dépollution, désamiantage, démantèlement et réhabilitation. Solutions complètes pour sites industriels."
    },
    contact: {
      title: "Contact | DEPOL",
      description: "Contactez nos experts pour vos projets de dépollution et services environnementaux. Devis personnalisé et accompagnement sur mesure."
    },
    careers: {
      title: "Carrières | DEPOL",
      description: "Rejoignez notre équipe d'experts en dépollution. Découvrez nos offres d'emploi et opportunités de carrière."
    },
    realisations: {
      title: "Nos réalisations | DEPOL",
      description: "Découvrez nos projets de dépollution et réhabilitation réussis. Expertise et savoir-faire démontrés sur le terrain."
    },
    news: {
      title: "Actualités | DEPOL",
      description: "Suivez nos dernières actualités, innovations et réalisations dans le domaine de la dépollution environnementale."
    },
    privacy: {
      title: "Politique de confidentialité | DEPOL",
      description: "Notre politique de confidentialité détaille comment nous protégeons vos données personnelles."
    },
    terms: {
      title: "Conditions d'utilisation | DEPOL",
      description: "Consultez nos conditions générales d'utilisation pour comprendre vos droits et obligations."
    },
    legalNotice: {
      title: "Mentions légales | DEPOL",
      description: "Informations légales et réglementaires concernant notre société et l'utilisation de notre site web."
    }
  },
  cache: {
    enabled: true,
    duration: 60 // 1 hour by default
  },
  firebaseCache: {
    enabled: true,
    duration: 60 // 1 hour by default
  }
};

export default function SeoEditor() {
  const [seoData, setSeoData] = useState<SEOData>(defaultSEOData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadSeoData();
  }, []);

  const loadSeoData = async () => {
    try {
      const seoRef = doc(db, 'settings', 'seo');
      const seoDoc = await getDoc(seoRef);
      
      if (seoDoc.exists()) {
        setSeoData({ ...defaultSEOData, ...seoDoc.data() as SEOData });
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      toast.error('Erreur lors du chargement des données SEO');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const seoRef = doc(db, 'settings', 'seo');
      await setDoc(seoRef, seoData);
      
      // Update cache in localStorage if enabled
      if (seoData.cache.enabled) {
        localStorage.setItem('seoData', JSON.stringify({
          data: seoData,
          timestamp: new Date().getTime()
        }));
      } else {
        localStorage.removeItem('seoData');
      }

      // Update Firebase cache settings
      if (seoData.firebaseCache.enabled) {
        enableFirebaseCache(seoData.firebaseCache.duration);
      } else {
        disableFirebaseCache();
      }
      
      toast.success('Paramètres SEO mis à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: keyof SEOData['pages'], field: keyof PageSEO, value: string) => {
    setSeoData({
      ...seoData,
      pages: {
        ...seoData.pages,
        [page]: {
          ...seoData.pages[page],
          [field]: value
        }
      }
    });
  };

  const toggleCache = () => {
    setSeoData({
      ...seoData,
      cache: {
        ...seoData.cache,
        enabled: !seoData.cache.enabled
      }
    });

    if (!seoData.cache.enabled) {
      toast.success('Cache SEO activé');
    } else {
      localStorage.removeItem('seoData');
      toast.success('Cache SEO désactivé et effacé');
    }
  };

  const toggleFirebaseCache = () => {
    const newEnabled = !seoData.firebaseCache.enabled;
    setSeoData({
      ...seoData,
      firebaseCache: {
        ...seoData.firebaseCache,
        enabled: newEnabled
      }
    });

    if (newEnabled) {
      enableFirebaseCache(seoData.firebaseCache.duration);
      toast.success('Cache Firebase activé');
    } else {
      disableFirebaseCache();
      clearFirebaseCache();
      toast.success('Cache Firebase désactivé et effacé');
    }
  };

  const handleCacheDurationChange = (duration: number) => {
    setSeoData({
      ...seoData,
      cache: {
        ...seoData.cache,
        duration
      }
    });
  };

  const handleFirebaseCacheDurationChange = (duration: number) => {
    setSeoData({
      ...seoData,
      firebaseCache: {
        ...seoData.firebaseCache,
        duration
      }
    });
    if (seoData.firebaseCache.enabled) {
      enableFirebaseCache(duration);
    }
  };

  const clearCache = () => {
    localStorage.removeItem('seoData');
    toast.success('Cache SEO effacé');
  };

  const clearAllFirebaseCache = () => {
    clearFirebaseCache();
    toast.success('Cache Firebase effacé');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Paramètres SEO</h1>
            <div className="flex items-center space-x-4">
              <button
                onClick={toggleCache}
                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                  seoData.cache.enabled
                    ? 'bg-green-500 text-white hover:bg-green-600'
                    : 'bg-gray-500 text-white hover:bg-gray-600'
                }`}
              >
                <Power className="h-5 w-5" />
                <span>{seoData.cache.enabled ? 'Cache SEO activé' : 'Cache SEO désactivé'}</span>
              </button>
              <button
                onClick={handleSave}
                disabled={loading}
                className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
              >
                <Save className="h-5 w-5" />
                <span>{loading ? 'Enregistrement...' : 'Enregistrer'}</span>
              </button>
            </div>
          </div>

          {/* Cache Settings */}
          <div className="bg-white rounded-lg shadow p-6 mb-6">
            <h2 className="text-lg font-medium mb-4">Paramètres du cache SEO</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Durée du cache (minutes)
                </label>
                <input
                  type="number"
                  value={seoData.cache.duration}
                  onChange={(e) => handleCacheDurationChange(parseInt(e.target.value))}
                  min="1"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  disabled={!seoData.cache.enabled}
                />
              </div>
              <button
                onClick={clearCache}
                className="px-4 py-2 text-red-600 hover:text-red-700 disabled:opacity-50"
                disabled={!seoData.cache.enabled}
              >
                Vider le cache SEO
              </button>
            </div>
          </div>

          {/* Firebase Cache Settings */}
          <div className="bg-white rounded-lg shadow p-6 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Cache Firebase</h2>
              <button
                onClick={toggleFirebaseCache}
                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                  seoData.firebaseCache.enabled
                    ? 'bg-green-500 text-white hover:bg-green-600'
                    : 'bg-gray-500 text-white hover:bg-gray-600'
                }`}
              >
                <Power className="h-5 w-5" />
                <span>{seoData.firebaseCache.enabled ? 'Cache Firebase activé' : 'Cache Firebase désactivé'}</span>
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Durée du cache Firebase (minutes)
                </label>
                <input
                  type="number"
                  value={seoData.firebaseCache.duration}
                  onChange={(e) => handleFirebaseCacheDurationChange(parseInt(e.target.value))}
                  min="1"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  disabled={!seoData.firebaseCache.enabled}
                />
              </div>
              <button
                onClick={clearAllFirebaseCache}
                className="px-4 py-2 text-red-600 hover:text-red-700 disabled:opacity-50"
                disabled={!seoData.firebaseCache.enabled}
              >
                Vider le cache Firebase
              </button>
            </div>
          </div>

          <div className="space-y-6">
            {Object.entries(seoData.pages).map(([key, value]) => (
              <div key={key} className="bg-white rounded-lg shadow p-6">
                <h2 className="text-lg font-medium mb-4 capitalize">
                  Page {key.replace(/([A-Z])/g, ' $1').trim()}
                </h2>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Titre de la page
                    </label>
                    <input
                      type="text"
                      value={value.title}
                      onChange={(e) => handlePageChange(key as keyof SEOData['pages'], 'title', e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Recommandé : 50-60 caractères
                      <span className="ml-2 text-gray-400">
                        ({value.title.length} caractères)
                      </span>
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Meta description
                    </label>
                    <textarea
                      value={value.description}
                      onChange={(e) => handlePageChange(key as keyof SEOData['pages'], 'description', e.target.value)}
                      rows={3}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Recommandé : 150-160 caractères
                      <span className="ml-2 text-gray-400">
                        ({value.description.length} caractères)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}