import React from 'react';
import { MapPin, Calendar, X, Building2, Clock, Banknote, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ProjectModalProps {
  project: {
    title: string;
    description: string;
    location: {
      city: string;
      country: string;
    };
    year: string;
    category: string;
    image: string;
    slug: string;
    surface?: string;
    duration?: string;
    budget?: string;
  };
  isOpen: boolean;
  onClose: () => void;
}

export default function ProjectModal({ project, isOpen, onClose }: ProjectModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
        </div>

        {/* Modal panel */}
        <div className="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
          {/* Image */}
          <div className="relative h-[250px]">
            <img
              src={project.image}
              alt={project.title}
              className="w-full h-full object-cover"
            />
            <button
              onClick={onClose}
              className="absolute top-4 right-4 bg-white rounded-full p-1.5 shadow-lg hover:bg-gray-100"
            >
              <X className="h-4 w-4" />
            </button>
            <div className="absolute bottom-4 left-4">
              <span className="inline-block px-3 py-1 text-sm font-medium text-gray-900 bg-white rounded-full">
                {project.category}
              </span>
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
            {/* Title */}
            <h2 className="text-xl font-bold text-gray-900 mb-3">
              {project.title}
            </h2>

            {/* Location and Year */}
            <div className="flex items-center space-x-4 text-gray-500 mb-4 text-sm">
              <div className="flex items-center">
                <MapPin className="h-4 w-4 mr-1" />
                <span>{project.location.city}, {project.location.country}</span>
              </div>
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-1" />
                <span>{project.year}</span>
              </div>
            </div>

            {/* Key Metrics */}
            <div className="grid grid-cols-3 gap-4 mb-4 p-4 bg-gray-50 rounded-lg">
              {project.surface && (
                <div className="text-center">
                  <div className="flex items-center justify-center mb-1">
                    <Building2 className="h-4 w-4 text-gray-400" />
                  </div>
                  <div className="text-base font-bold text-gray-900">{project.surface}</div>
                  <div className="text-xs text-gray-500">Surface</div>
                </div>
              )}
              {project.duration && (
                <div className="text-center">
                  <div className="flex items-center justify-center mb-1">
                    <Clock className="h-4 w-4 text-gray-400" />
                  </div>
                  <div className="text-base font-bold text-gray-900">{project.duration}</div>
                  <div className="text-xs text-gray-500">Durée</div>
                </div>
              )}
              {project.budget && (
                <div className="text-center">
                  <div className="flex items-center justify-center mb-1">
                    <Banknote className="h-4 w-4 text-gray-400" />
                  </div>
                  <div className="text-base font-bold text-gray-900">{project.budget}</div>
                  <div className="text-xs text-gray-500">Budget</div>
                </div>
              )}
            </div>

            {/* Description */}
            <p className="text-gray-600 text-sm leading-relaxed mb-6">
              {project.description}
            </p>

            {/* See More Button */}
            <div className="flex justify-end">
              <Link
                to={`/realisations/${project.slug}`}
                className="inline-flex items-center text-black hover:text-gray-700 font-medium"
                onClick={onClose}
              >
                Voir plus
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}