import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHomeData } from './hooks/useHomeData';
import LoadingSpinner from './components/LoadingSpinner';
import HeroSection from '../../components/sections/HeroSection';
import AboutSection from '../../components/sections/AboutSection';
import TypewriterSection from '../../components/sections/TypewriterSection';
import KeyFiguresSection from '../../components/sections/KeyFiguresSection';
import ExperienceSection from '../../components/sections/ExperienceSection';
import DomainSection from '../../components/sections/DomainSection';
import ClientsSection02 from '../../components/sections/ClientsSection02';
import LatestProjects from './components/LatestProjects';
import ContactSection from '../../components/sections/ContactSection';

export default function Home2() {
  const { homeData, loading, error } = useHomeData();
  const [selectedDomain, setSelectedDomain] = useState('');

  // Set initial selected domain when data loads
  React.useEffect(() => {
    if (homeData?.domains?.length > 0) {
      setSelectedDomain(homeData.domains[0].name.toLowerCase());
    }
  }, [homeData?.domains]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Une erreur est survenue
          </h1>
          <p className="text-gray-600">
            Impossible de charger les données. Veuillez réessayer plus tard.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Aps - Solutions de dépollution et services environnementaux</title>
        <meta 
          name="description" 
          content="Expert en dépollution, désamiantage et réhabilitation environnementale. Solutions professionnelles sur mesure pour vos projets." 
        />
      </Helmet>

      <div className="min-h-screen">
        <HeroSection data={homeData.hero} />
        <AboutSection data={homeData.about} />
        <TypewriterSection data={homeData.typewriter} />
        <DomainSection 
          domains={homeData.domains || []}
          selectedDomain={selectedDomain}
          onDomainChange={setSelectedDomain}
        />
        <KeyFiguresSection data={homeData.keyFigures} />
        <ExperienceSection data={homeData.experience} />
        <ClientsSection02 data={homeData.clients} />
        <LatestProjects data={homeData.latestProjects} />
        <ContactSection data={homeData.contact} />
      </div>
    </>
  );
}