import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save, Plus, Trash2 } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import ImageUpload from '../../components/ImageUpload';
import AdminLayout from '../../components/admin/AdminLayout';

interface Domain {
  name: string;
  description: string;
  features: string[];
  image: string;
  stats: {
    sites: string;
    satisfaction: string;
    availability: string;
    labels: {
      sites: string;
      satisfaction: string;
      availability: string;
    };
  };
  details: {
    description: string;
    features: Array<{
      title: string;
      description: string;
    }>;
  };
}

interface DomainsData {
  hero: {
    title: string;
    subtitle: string;
    backgroundImage: string;
  };
  domains: Domain[];
}

const defaultDomainsData: DomainsData = {
  hero: {
    title: "Nos Domaines d'Intervention",
    subtitle: "Expertise complète en dépollution et réhabilitation environnementale",
    backgroundImage: "https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?auto=format&fit=crop&q=80"
  },
  domains: []
};

const emptyDomain: Domain = {
  name: '',
  description: '',
  features: [''],
  image: '',
  stats: {
    sites: '0',
    satisfaction: '0',
    availability: '0',
    labels: {
      sites: 'Sites traités',
      satisfaction: 'Satisfaction',
      availability: 'Disponibilité'
    }
  },
  details: {
    description: '',
    features: [{
      title: '',
      description: ''
    }]
  }
};

export default function DomainsEditor() {
  const [domainsData, setDomainsData] = useState<DomainsData>(defaultDomainsData);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    loadDomainsData();
  }, []);

  const loadDomainsData = async () => {
    try {
      const domainsRef = doc(db, 'settings', 'domains');
      const domainsDoc = await getDoc(domainsRef);
      
      if (domainsDoc.exists()) {
        const data = domainsDoc.data() as DomainsData;
        const domains = data.domains?.map(domain => ({
          ...emptyDomain,
          ...domain,
          stats: {
            ...emptyDomain.stats,
            ...domain.stats,
            labels: {
              ...emptyDomain.stats.labels,
              ...(domain.stats?.labels || {})
            }
          }
        })) || [];

        setDomainsData({
          ...defaultDomainsData,
          ...data,
          domains
        });
      }
    } catch (error) {
      console.error('Erreur lors du chargement:', error);
      toast.error('Erreur lors du chargement des données');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const domainsRef = doc(db, 'settings', 'domains');
      await setDoc(domainsRef, domainsData);
      toast.success('Domaines mis à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la mise à jour');
    } finally {
      setSaving(false);
    }
  };

  const addDomain = () => {
    setDomainsData(prev => ({
      ...prev,
      domains: [...prev.domains, { ...emptyDomain }]
    }));
  };

  const removeDomain = (index: number) => {
    setDomainsData(prev => ({
      ...prev,
      domains: prev.domains.filter((_, i) => i !== index)
    }));
  };

  const updateDomain = (index: number, field: keyof Domain, value: any) => {
    setDomainsData(prev => ({
      ...prev,
      domains: prev.domains.map((domain, i) =>
        i === index ? { ...domain, [field]: value } : domain
      )
    }));
  };

  const updateDomainStats = (domainIndex: number, field: keyof Domain['stats'], value: string) => {
    setDomainsData(prev => ({
      ...prev,
      domains: prev.domains.map((domain, i) =>
        i === domainIndex
          ? {
              ...domain,
              stats: {
                ...domain.stats,
                [field]: value
              }
            }
          : domain
      )
    }));
  };

  const updateDomainStatsLabel = (domainIndex: number, field: keyof Domain['stats']['labels'], value: string) => {
    setDomainsData(prev => ({
      ...prev,
      domains: prev.domains.map((domain, i) =>
        i === domainIndex
          ? {
              ...domain,
              stats: {
                ...domain.stats,
                labels: {
                  ...domain.stats.labels,
                  [field]: value
                }
              }
            }
          : domain
      )
    }));
  };

  return (
    <AdminLayout loading={loading}>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold">Modifier les domaines</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={addDomain}
            className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900"
          >
            <Plus className="h-5 w-5" />
            <span>Ajouter un domaine</span>
          </button>
          <button
            onClick={handleSave}
            disabled={saving}
            className="flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-900 disabled:opacity-50"
          >
            <Save className="h-5 w-5" />
            <span>{saving ? 'Enregistrement...' : 'Enregistrer'}</span>
          </button>
        </div>
      </div>

      <div className="space-y-8">
        {/* Hero Section */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-medium mb-4">Section Hero</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Titre
              </label>
              <input
                type="text"
                value={domainsData.hero.title}
                onChange={(e) => setDomainsData({
                  ...domainsData,
                  hero: { ...domainsData.hero, title: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Sous-titre
              </label>
              <input
                type="text"
                value={domainsData.hero.subtitle}
                onChange={(e) => setDomainsData({
                  ...domainsData,
                  hero: { ...domainsData.hero, subtitle: e.target.value }
                })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Image de fond
              </label>
              {domainsData.hero.backgroundImage && (
                <div className="mb-4">
                  <img 
                    src={domainsData.hero.backgroundImage} 
                    alt="Hero background" 
                    className="h-40 w-full object-cover rounded-lg"
                  />
                </div>
              )}
              <ImageUpload
                onImageUploaded={(url) => setDomainsData({
                  ...domainsData,
                  hero: { ...domainsData.hero, backgroundImage: url }
                })}
                onError={(error) => toast.error(error)}
              />
            </div>
          </div>
        </div>

        {/* Domains */}
        {domainsData.domains.map((domain, index) => (
          <div key={index} className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium">Domaine {index + 1}</h3>
              <button
                onClick={() => removeDomain(index)}
                className="text-red-600 hover:text-red-700"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-6">
              {/* Basic Information */}
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom du domaine
                  </label>
                  <input
                    type="text"
                    value={domain.name}
                    onChange={(e) => updateDomain(index, 'name', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Description
                  </label>
                  <textarea
                    value={domain.description}
                    onChange={(e) => updateDomain(index, 'description', e.target.value)}
                    rows={4}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>

                {/* Statistics */}
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-4">Statistiques</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Label Sites traités
                      </label>
                      <input
                        type="text"
                        value={domain.stats.labels.sites}
                        onChange={(e) => updateDomainStatsLabel(index, 'sites', e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                      />
                      <input
                        type="text"
                        value={domain.stats.sites}
                        onChange={(e) => updateDomainStats(index, 'sites', e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400 mt-2"
                        placeholder="Valeur"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Label Satisfaction
                      </label>
                      <input
                        type="text"
                        value={domain.stats.labels.satisfaction}
                        onChange={(e) => updateDomainStatsLabel(index, 'satisfaction', e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                      />
                      <input
                        type="text"
                        value={domain.stats.satisfaction}
                        onChange={(e) => updateDomainStats(index, 'satisfaction', e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400 mt-2"
                        placeholder="Valeur"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Label Disponibilité
                      </label>
                      <input
                        type="text"
                        value={domain.stats.labels.availability}
                        onChange={(e) => updateDomainStatsLabel(index, 'availability', e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                      />
                      <input
                        type="text"
                        value={domain.stats.availability}
                        onChange={(e) => updateDomainStats(index, 'availability', e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400 mt-2"
                        placeholder="Valeur"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Image
                  </label>
                  {domain.image && (
                    <div className="mb-4">
                      <img 
                        src={domain.image} 
                        alt={domain.name} 
                        className="h-40 w-full object-cover rounded-lg"
                      />
                    </div>
                  )}
                  <ImageUpload
                    onImageUploaded={(url) => updateDomain(index, 'image', url)}
                    onError={(error) => toast.error(error)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </AdminLayout>
  );
}