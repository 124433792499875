import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

interface HeroProps {
  data: {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonLink: string;
    backgroundImage?: string;
  };
}

export default function HeroSection({ data }: HeroProps) {
  return (
    <section className="relative h-screen">
      <div className="absolute inset-0">
        <img
          src={data.backgroundImage || "https://images.unsplash.com/photo-1590674899484-d5640e854abe?auto=format&fit=crop&q=80"}
          alt="Industrial background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center justify-center text-center">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-white max-w-4xl"
        >
          <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight">
            {data.title}
          </h1>
          <p className="text-xl md:text-2xl mb-12 text-gray-200">
            {data.subtitle}
          </p>
          <div className="flex justify-center">
            <Link
              to={data.buttonLink}
              className="bg-black hover:bg-gray-900 text-white px-8 py-4 rounded-lg flex items-center space-x-2 group inline-flex"
            >
              <span>{data.buttonText}</span>
              <ArrowRight className="h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </motion.div>
      </div>
    </section>
  );
}