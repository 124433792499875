import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Linkedin, ArrowRight } from 'lucide-react';
import PageHero from '../components/PageHero';
import { useSEO } from '../hooks/useSEO';

interface AboutData {
  hero: {
    title: string;
    subtitle: string;
    backgroundImage: string;
  };
  mission: {
    title: string;
    subtitle: string;
    description: string;
  };
  values: {
    title: string;
    items: {
      id: string;
      title: string;
      description: string;
    }[];
  };
  team: {
    title: string;
    subtitle: string;
    members: {
      id: string;
      name: string;
      role: string;
      image: string;
      linkedin?: string;
    }[];
  };
  certifications: {
    title: string;
    items: {
      id: string;
      name: string;
      image: string;
      description: string;
    }[];
  };
}

const defaultAboutData: AboutData = {
  hero: {
    title: "Notre Histoire et Notre Vision",
    subtitle: "Engagés pour un environnement plus propre depuis plus de 25 ans",
    backgroundImage: "https://images.unsplash.com/photo-1482731215275-a1f151646268?auto=format&fit=crop&q=80"
  },
  mission: {
    title: "Notre Mission",
    subtitle: "Un engagement durable",
    description: "Nous nous engageons à créer un impact positif sur l'environnement en proposant des solutions innovantes et durables de dépollution."
  },
  values: {
    title: "Nos valeurs",
    items: [
      {
        id: "1",
        title: "Innovation",
        description: "Nous repoussons constamment les limites pour trouver des solutions durables"
      },
      {
        id: "2",
        title: "Engagement",
        description: "Nous sommes déterminés à faire une différence positive pour l'environnement"
      },
      {
        id: "3",
        title: "Excellence",
        description: "Nous visons l'excellence dans tout ce que nous entreprenons"
      }
    ]
  },
  team: {
    title: "Notre Équipe de Direction",
    subtitle: "Des experts passionnés qui dirigent notre entreprise vers l'excellence",
    members: []
  },
  certifications: {
    title: "Nos Certifications",
    items: []
  }
};

export default function About() {
  const [aboutData, setAboutData] = useState<AboutData>(defaultAboutData);
  const seo = useSEO('about');

  useEffect(() => {
    const loadAboutData = async () => {
      try {
        const aboutRef = doc(db, 'settings', 'about');
        const aboutDoc = await getDoc(aboutRef);
        
        if (aboutDoc.exists()) {
          setAboutData({ ...defaultAboutData, ...aboutDoc.data() as AboutData });
        }
      } catch (error) {
        console.error('Erreur lors du chargement:', error);
      }
    };

    loadAboutData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{seo?.title || 'À propos | DEPOL'}</title>
        <meta 
          name="description" 
          content={seo?.description || 'Découvrez notre histoire, notre mission et nos valeurs.'} 
        />
      </Helmet>

      <div className="min-h-screen">
        <PageHero
          title={aboutData.hero.title}
          subtitle={aboutData.hero.subtitle}
          backgroundImage={aboutData.hero.backgroundImage}
          height="large"
        />

        {/* Mission Section */}
        <section className="py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div>
                <h2 className="text-4xl font-bold mb-4">{aboutData.mission.title}</h2>
                <h3 className="text-xl text-gray-600 mb-6">{aboutData.mission.subtitle}</h3>
                <p className="text-gray-600">{aboutData.mission.description}</p>
              </div>
              <div className="relative">
                <div className="aspect-[4/3] relative">
                  <img
                    src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80"
                    alt="Équipe au travail"
                    className="rounded-lg shadow-xl object-cover w-full h-full"
                  />
                </div>
                <div className="absolute -bottom-4 -left-4 -right-4 -top-4 rounded-lg bg-black/5 -z-10"></div>
              </div>
            </div>
          </div>
        </section>

        {/* Values Section */}
        <section className="py-20 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl font-bold text-center mb-12">{aboutData.values.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {aboutData.values.items.map((value, index) => (
                <motion.div
                  key={value.id}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="bg-white p-8 rounded-lg shadow-lg"
                >
                  <h3 className="text-xl font-semibold mb-4">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Team Section */}
        {aboutData.team.members.length > 0 && (
          <section className="py-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-center mb-16"
              >
                <h2 className="text-4xl font-bold mb-4">{aboutData.team.title}</h2>
                <p className="text-xl text-gray-600 max-w-3xl mx-auto">{aboutData.team.subtitle}</p>
              </motion.div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {aboutData.team.members.map((member, index) => (
                  <motion.div
                    key={member.id}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                    whileHover={{ y: -5 }}
                    className="bg-white rounded-3xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
                  >
                    <div className="flex flex-col items-center text-center">
                      <motion.div 
                        className="relative w-32 h-32 mb-6"
                        whileHover={{ scale: 1.05 }}
                      >
                        <img
                          src={member.image}
                          alt={member.name}
                          className="w-full h-full rounded-full object-cover shadow-md"
                        />
                        <div className="absolute inset-0 rounded-full bg-gradient-to-b from-black/0 to-black/20" />
                      </motion.div>

                      <motion.h3 
                        className="text-2xl font-bold mb-2"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                      >
                        {member.name}
                      </motion.h3>

                      <motion.p 
                        className="text-gray-600 mb-6"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                      >
                        {member.role}
                      </motion.p>

                      {member.linkedin && (
                        <motion.div
                          className="flex space-x-4"
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{ delay: 0.4 }}
                        >
                          <a
                            href={member.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="p-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-900 transition-colors duration-200"
                          >
                            <Linkedin className="w-5 h-5" />
                          </a>
                        </motion.div>
                      )}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>
        )}

        {/* Certifications Section */}
        {aboutData.certifications.items.length > 0 && (
          <section className="py-20 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-4xl font-bold text-center mb-12">{aboutData.certifications.title}</h2>
              <div className="flex flex-wrap justify-center gap-8">
                {aboutData.certifications.items.map((certification, index) => (
                  <motion.div
                    key={certification.id}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                    className="flex flex-col items-center text-center w-64"
                  >
                    <img
                      src={certification.image}
                      alt={certification.name}
                      className="h-24 object-contain mb-4"
                    />
                    <h3 className="font-semibold mb-2">{certification.name}</h3>
                    <p className="text-sm text-gray-600">{certification.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>
        )}

        {/* CTA Section */}
        <section className="py-20 bg-black text-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold mb-8">Prêt à travailler avec nous ?</h2>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-3 border-2 border-white text-lg font-medium rounded-lg text-white hover:bg-white hover:text-black transition-colors duration-200"
            >
              Contactez-nous
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}