import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

interface AnimatedStatProps {
  end: number;
  label: string;
  suffix?: string;
}

export default function AnimatedStat({ end, label, suffix = '+' }: AnimatedStatProps) {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return (
    <div ref={ref} className="text-center">
      <div className="text-4xl font-bold mb-2 text-gray-900">
        {inView ? (
          <CountUp
            end={end}
            duration={2.5}
            suffix={suffix}
            separator=" "
          />
        ) : (
          `0${suffix}`
        )}
      </div>
      <div className="text-gray-600">{label}</div>
    </div>
  );
}