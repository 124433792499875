import React from 'react';
import { motion } from 'framer-motion';
import ClientLogo from './ClientLogo';

interface MarqueeTrackProps {
  items: Array<{
    id: string;
    name: string;
    logo: string;
  }>;
}

export default function MarqueeTrack({ items }: MarqueeTrackProps) {
  // Double the items array to create a seamless loop
  const displayItems = [...items, ...items];

  return (
    <div className="flex flex-col gap-8">
      {/* First Row - Left to Right */}
      <div className="overflow-hidden">
        <motion.div
          className="flex gap-8"
          animate={{
            x: [0, -100 * items.length],
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear",
            },
          }}
          style={{ width: 'fit-content' }}
        >
          {displayItems.map((item, index) => (
            <ClientLogo
              key={`${item.id}-${index}-forward`}
              name={item.name}
              logo={item.logo}
            />
          ))}
        </motion.div>
      </div>

      {/* Second Row - Right to Left */}
      <div className="overflow-hidden">
        <motion.div
          className="flex gap-8"
          animate={{
            x: [-100 * items.length, 0],
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear",
            },
          }}
          style={{ width: 'fit-content' }}
        >
          {displayItems.map((item, index) => (
            <ClientLogo
              key={`${item.id}-${index}-backward`}
              name={item.name}
              logo={item.logo}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
}