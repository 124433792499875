import { SEOData } from '../types/seo';

export const getSEODataFromCache = (): SEOData | null => {
  try {
    const cached = localStorage.getItem('seoData');
    if (!cached) {
      console.log('No SEO cache found');
      return null;
    }

    const { data, timestamp } = JSON.parse(cached);
    const now = new Date().getTime();
    const cacheAge = (now - timestamp) / 1000 / 60; // Convert to minutes

    console.log('Cache age:', Math.round(cacheAge), 'minutes');
    console.log('Cache duration:', data.cache.duration, 'minutes');

    // Check if cache is still valid
    if (data.cache.enabled && cacheAge < data.cache.duration) {
      console.log('Cache is valid');
      return data;
    }

    // Cache expired or disabled, remove it
    console.log('Cache expired or disabled, removing');
    localStorage.removeItem('seoData');
    return null;
  } catch (error) {
    console.error('Error reading SEO cache:', error);
    localStorage.removeItem('seoData');
    return null;
  }
};

export const setSEODataToCache = (data: SEOData) => {
  if (!data.cache.enabled) {
    console.log('Cache is disabled, not saving');
    return;
  }

  try {
    const cacheData = {
      data,
      timestamp: new Date().getTime()
    };
    
    localStorage.setItem('seoData', JSON.stringify(cacheData));
    console.log('SEO data cached successfully');
  } catch (error) {
    console.error('Error saving SEO cache:', error);
  }
};