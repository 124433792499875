import React from 'react';
import { LucideIcon } from 'lucide-react';

interface ContactInfoCardProps {
  icon: LucideIcon;
  title: string;
  content: string;
}

export default function ContactInfoCard({ icon: Icon, title, content }: ContactInfoCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-200">
      <Icon className="h-6 w-6 text-gray-700 mb-4" />
      <h3 className="text-lg font-semibold mb-2 text-black">{title}</h3>
      <p className="text-gray-600">{content}</p>
    </div>
  );
}