import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';
import { Save, Plus, Trash2, Edit2 } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';
import BlogCategoryManager from '../../components/admin/BlogCategoryManager';
import ImageUpload from '../../components/ImageUpload';

interface Article {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  image: string;
  category: string;
  publishedAt: string;
  readTime: number;
  author: {
    name: string;
    avatar: string;
  };
  slug: string;
}

interface Category {
  id: string;
  name: string;
  description: string;
}

const emptyArticle: Omit<Article, 'id'> = {
  title: '',
  excerpt: '',
  content: '',
  image: '',
  category: '',
  publishedAt: new Date().toISOString(),
  readTime: 5,
  author: {
    name: '',
    avatar: ''
  },
  slug: ''
};

export default function BlogEditor() {
  const [articles, setArticles] = useState<Article[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);
  const [newArticle, setNewArticle] = useState<Omit<Article, 'id'>>(emptyArticle);
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      // Load articles
      const articlesSnapshot = await getDocs(collection(db, 'news'));
      const articlesData = articlesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Article[];
      setArticles(articlesData);

      // Load categories
      const categoriesSnapshot = await getDocs(collection(db, 'categories'));
      const categoriesData = categoriesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Category[];
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Erreur lors du chargement des données');
    }
  };

  const handleAddArticle = async () => {
    try {
      const slug = newArticle.title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

      const articleWithSlug = { ...newArticle, slug };

      if (isEditing && editingId) {
        await updateDoc(doc(db, 'news', editingId), articleWithSlug);
        toast.success('Article mis à jour avec succès');
      } else {
        await addDoc(collection(db, 'news'), articleWithSlug);
        toast.success('Article ajouté avec succès');
      }

      loadData();
      setNewArticle(emptyArticle);
      setIsEditing(false);
      setEditingId(null);
    } catch (error) {
      toast.error(isEditing ? 'Erreur lors de la mise à jour' : 'Erreur lors de l\'ajout');
    }
  };

  const handleDeleteArticle = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
      try {
        await deleteDoc(doc(db, 'news', id));
        toast.success('Article supprimé avec succès');
        loadData();
      } catch (error) {
        toast.error('Erreur lors de la suppression');
      }
    }
  };

  const handleEditArticle = (article: Article) => {
    setNewArticle({
      title: article.title,
      excerpt: article.excerpt,
      content: article.content,
      image: article.image,
      category: article.category,
      publishedAt: article.publishedAt,
      readTime: article.readTime,
      author: article.author,
      slug: article.slug
    });
    setIsEditing(true);
    setEditingId(article.id);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Category Management
  const handleAddCategory = async (category: Omit<Category, 'id'>) => {
    try {
      await addDoc(collection(db, 'categories'), category);
      toast.success('Catégorie ajoutée avec succès');
      loadData();
    } catch (error) {
      toast.error('Erreur lors de l\'ajout de la catégorie');
    }
  };

  const handleUpdateCategory = async (id: string, category: Omit<Category, 'id'>) => {
    try {
      await updateDoc(doc(db, 'categories', id), category);
      toast.success('Catégorie mise à jour avec succès');
      loadData();
    } catch (error) {
      toast.error('Erreur lors de la mise à jour de la catégorie');
    }
  };

  const handleDeleteCategory = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'categories', id));
      toast.success('Catégorie supprimée avec succès');
      loadData();
    } catch (error) {
      toast.error('Erreur lors de la suppression de la catégorie');
    }
  };

  // Calculate article count by category
  const articleCountByCategory = categories.reduce((acc, category) => {
    acc[category.id] = articles.filter(article => article.category === category.name).length;
    return acc;
  }, {} as Record<string, number>);

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Gestion du Blog</h1>
          </div>

          {/* Category Manager */}
          <div className="mb-8">
            <BlogCategoryManager
              categories={categories}
              onAddCategory={handleAddCategory}
              onUpdateCategory={handleUpdateCategory}
              onDeleteCategory={handleDeleteCategory}
              articleCountByCategory={articleCountByCategory}
            />
          </div>

          {/* Articles List */}
          <div className="bg-white rounded-lg shadow p-6 mb-8">
            <h2 className="text-lg font-medium mb-4">Articles publiés</h2>
            <div className="space-y-4">
              {articles.map((article) => (
                <div key={article.id} className="flex items-center justify-between p-4 border rounded-lg">
                  <div>
                    <h3 className="font-medium">{article.title}</h3>
                    <p className="text-sm text-gray-500">
                      {new Date(article.publishedAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleEditArticle(article)}
                      className="text-blue-600 hover:text-blue-700"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteArticle(article.id)}
                      className="text-red-600 hover:text-red-700"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Article Form */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-medium mb-6">
              {isEditing ? 'Modifier l\'article' : 'Nouvel article'}
            </h2>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Titre
                </label>
                <input
                  type="text"
                  value={newArticle.title}
                  onChange={(e) => setNewArticle({ ...newArticle, title: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Extrait
                </label>
                <textarea
                  value={newArticle.excerpt}
                  onChange={(e) => setNewArticle({ ...newArticle, excerpt: e.target.value })}
                  rows={3}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Contenu
                </label>
                <textarea
                  value={newArticle.content}
                  onChange={(e) => setNewArticle({ ...newArticle, content: e.target.value })}
                  rows={10}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Catégorie
                </label>
                <select
                  value={newArticle.category}
                  onChange={(e) => setNewArticle({ ...newArticle, category: e.target.value })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                >
                  <option value="">Sélectionner une catégorie</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Image de couverture
                </label>
                {newArticle.image && (
                  <div className="mb-4">
                    <img
                      src={newArticle.image}
                      alt="Article cover"
                      className="w-full max-w-md h-48 object-cover rounded-lg"
                    />
                  </div>
                )}
                <ImageUpload
                  onImageUploaded={(url) => setNewArticle({ ...newArticle, image: url })}
                  onError={(error) => toast.error(error)}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Temps de lecture (minutes)
                  </label>
                  <input
                    type="number"
                    value={newArticle.readTime}
                    onChange={(e) => setNewArticle({ ...newArticle, readTime: parseInt(e.target.value) })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom de l'auteur
                  </label>
                  <input
                    type="text"
                    value={newArticle.author.name}
                    onChange={(e) => setNewArticle({
                      ...newArticle,
                      author: { ...newArticle.author, name: e.target.value }
                    })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Avatar de l'auteur
                  </label>
                  {newArticle.author.avatar && (
                    <div className="mb-4">
                      <img
                        src={newArticle.author.avatar}
                        alt="Author avatar"
                        className="w-12 h-12 rounded-full object-cover"
                      />
                    </div>
                  )}
                  <ImageUpload
                    onImageUploaded={(url) => setNewArticle({
                      ...newArticle,
                      author: { ...newArticle.author, avatar: url }
                    })}
                    onError={(error) => toast.error(error)}
                  />
                </div>
              </div>

              <div className="flex space-x-4">
                <button
                  onClick={handleAddArticle}
                  className="flex-1 bg-black text-white py-3 px-6 rounded-lg hover:bg-gray-900 transition-colors duration-200 flex items-center justify-center"
                >
                  <Save className="h-5 w-5 mr-2" />
                  <span>{isEditing ? 'Mettre à jour l\'article' : 'Publier l\'article'}</span>
                </button>

                {isEditing && (
                  <button
                    onClick={() => {
                      setNewArticle(emptyArticle);
                      setIsEditing(false);
                      setEditingId(null);
                    }}
                    className="flex-1 bg-gray-100 text-gray-700 py-3 px-6 rounded-lg hover:bg-gray-200 transition-colors duration-200"
                  >
                    Annuler la modification
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}