import { useState, useEffect } from 'react';
import { doc, onSnapshot, collection, query, orderBy, limit, getDocs, getDoc } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { HomeData, defaultHomeData } from '../types';
import toast from 'react-hot-toast';

export function useHomeData() {
  const [homeData, setHomeData] = useState<HomeData>(defaultHomeData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // Set up real-time listener for homepage settings
    const unsubscribeHome = onSnapshot(
      doc(db, 'settings', 'homepage2'),
      async (docSnapshot) => {
        if (docSnapshot.exists()) {
          try {
            const homeSettings = docSnapshot.data() as HomeData;
            
            // Load latest projects
            const projectsRef = collection(db, 'projects');
            const q = query(projectsRef, orderBy('year', 'desc'), limit(3));
            const projectsSnapshot = await getDocs(q);
            const latestProjects = projectsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));

            // Load domains data
            const domainsDoc = await getDoc(doc(db, 'settings', 'domains'));
            const domainsData = domainsDoc.exists() ? domainsDoc.data().domains : [];

            // Combine all data with default values for missing fields
            setHomeData({
              ...defaultHomeData, // First spread default data
              ...homeSettings, // Then spread fetched data
              domains: domainsData,
              latestProjects: {
                ...defaultHomeData.latestProjects,
                ...homeSettings.latestProjects,
                projects: latestProjects
              },
              keyFigures: {
                ...defaultHomeData.keyFigures,
                ...homeSettings.keyFigures
              }
            });
            
            setError(null);
          } catch (error) {
            console.error('Error loading data:', error);
            toast.error('Erreur lors du chargement des données');
            setError(error instanceof Error ? error : new Error('Unknown error'));
          }
        } else {
          setHomeData(defaultHomeData);
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error in home data listener:', error);
        toast.error('Erreur lors du chargement des données');
        setError(error);
        setLoading(false);
      }
    );

    // Set up real-time listener for domains
    const unsubscribeDomains = onSnapshot(
      doc(db, 'settings', 'domains'),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setHomeData(prev => ({
            ...prev,
            domains: docSnapshot.data().domains || []
          }));
        }
      },
      (error) => {
        console.error('Error in domains listener:', error);
        toast.error('Erreur lors du chargement des domaines');
      }
    );

    return () => {
      unsubscribeHome();
      unsubscribeDomains();
    };
  }, []);

  return { homeData, loading, error };
}