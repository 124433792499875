import React from 'react';

interface TypewriterSectionProps {
  data: {
    prefix: string;
    words: string[];
    buttonText: string;
    buttonLink: string;
  };
  onChange: (data: any) => void;
}

export default function TypewriterSection({ data, onChange }: TypewriterSectionProps) {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium mb-4">Section Typewriter</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Préfixe
          </label>
          <input
            type="text"
            value={data.prefix}
            onChange={(e) => onChange({
              ...data,
              prefix: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Mots (séparés par des virgules)
          </label>
          <input
            type="text"
            value={data.words.join(', ')}
            onChange={(e) => onChange({
              ...data,
              words: e.target.value.split(',').map(word => word.trim())
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Texte du bouton
          </label>
          <input
            type="text"
            value={data.buttonText}
            onChange={(e) => onChange({
              ...data,
              buttonText: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Lien du bouton
          </label>
          <input
            type="text"
            value={data.buttonLink}
            onChange={(e) => onChange({
              ...data,
              buttonLink: e.target.value
            })}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-gray-400"
          />
        </div>
      </div>
    </div>
  );
}