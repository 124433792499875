import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { auth, db } from '../../lib/firebase';
import { updatePassword, updateEmail } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { User, Lock, Mail } from 'lucide-react';
import Sidebar from '../../components/admin/Sidebar';

export default function Profile() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    role: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    const loadUserData = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(prev => ({
              ...prev,
              email: currentUser.email || '',
              role: data.role || ''
            }));
          }
        } catch (error) {
          toast.error('Erreur lors du chargement des données');
        }
      }
    };

    loadUserData();
  }, [currentUser]);

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (currentUser) {
        // Update email if changed
        if (userData.email !== currentUser.email) {
          await updateEmail(currentUser, userData.email);
        }

        // Update password if provided
        if (userData.newPassword) {
          if (userData.newPassword !== userData.confirmPassword) {
            throw new Error('Les mots de passe ne correspondent pas');
          }
          await updatePassword(currentUser, userData.newPassword);
        }

        // Update Firestore document
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          email: userData.email
        });

        toast.success('Profil mis à jour avec succès');
        setUserData(prev => ({ ...prev, newPassword: '', confirmPassword: '' }));
      }
    } catch (error) {
      toast.error('Erreur lors de la mise à jour du profil');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <div className="ml-64 p-8">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-2xl font-bold mb-8">Paramètres du profil</h1>

          <div className="bg-white rounded-lg shadow p-6">
            <form onSubmit={handleUpdateProfile} className="space-y-6">
              {/* Email Section */}
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Mail className="h-5 w-5 text-gray-400" />
                  <h2 className="text-lg font-medium">Email</h2>
                </div>
                <input
                  type="email"
                  value={userData.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>

              {/* Password Section */}
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Lock className="h-5 w-5 text-gray-400" />
                  <h2 className="text-lg font-medium">Changer le mot de passe</h2>
                </div>
                <input
                  type="password"
                  placeholder="Nouveau mot de passe"
                  value={userData.newPassword}
                  onChange={(e) => setUserData({ ...userData, newPassword: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                />
                <input
                  type="password"
                  placeholder="Confirmer le mot de passe"
                  value={userData.confirmPassword}
                  onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              {/* Role Display */}
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <User className="h-5 w-5 text-gray-400" />
                  <h2 className="text-lg font-medium">Rôle</h2>
                </div>
                <p className="text-gray-600">{userData.role}</p>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
              >
                {loading ? 'Mise à jour...' : 'Mettre à jour le profil'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}